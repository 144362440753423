<template lang="pug">
div(v-if="preview || disabled || (dataKey=='referencielTvaId' && (line.type==14 ||line.type==15 ||line.type==16 ||line.type==17 ||line.type==18))")
  div.content-span-document-detail
    span.mr-2 {{ selected(line[dataKey]) }}
div(v-else)
  select.form-select.custom-select(@focus="emitEditFocus(true)" v-model="line[dataKey]" :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled ? 'disabled' :'']" aria-label='Default select example' :disabled="(dataKey == 'referencielTvaId' && (orderFormCustomerOption.isReverseCharge || !canEdit || line.isDisabled))" @change="change(dataKey)")
    option(v-for='(item, index) in orderFormCustomerHeader[dataKey].choice' :value="item.id")  {{ item.label }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { applyOrderFormCustomerDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";

export default {
  props: {
    preview: {
      default: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    orderFormCustomerHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    mouseOn: {
      type: Boolean,
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(["orderFormCustomerOption"]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    orderFormCustomerDetails: {
      get() {
        return this.$store.getters["getOrderFormCustomerDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_DETAILS", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "updateOrderFormCustomerDetail",
      "loopOrderFormCustomerDetails",
      "updateTvaOrderFormCustomerDetail",
      "updateOrderFormCustomer",
    ]),
    referencielTvaTranslate,
    applyOrderFormCustomerDeduction,
    selected(id) {
      if (
        this.orderFormCustomerHeader[this.dataKey].choice.find(
          (el) => el.id == id
        )
      ) {
        return this.orderFormCustomerHeader[this.dataKey].choice.find(
          (el) => el.id == id
        ).label;
      } else {
        return "--";
      }
    },
    blurFunction(res) {
      this.emitEditFocus(res);
      this.updateOrderFormCustomerDetail({
        orderFormCustomerDetail: this.line,
      });
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    change(dataKey) {
      if (dataKey == "referencielTvaId") {
        this.changeAllReferencielTva();
      } else {
        this.blurFunction(false);
      }
    },
    changeAllReferencielTva() {
      let orderFormCustomerDetailIds = [];
      // Autoliquidation
      if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] == 5 &&
        this.line.type == 6
      ) {
        this.$bvModal
          .msgBoxConfirm("Est-ce une autoliquidation ?", {
            title: "TVA à 0% détectée",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, appliquer à toutes les lignes",
            cancelTitle: "Non",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.orderFormCustomerOption.isReverseCharge = true;
              this.loopOrderFormCustomerDetails({ referencielTvaId: 5 }).then(
                () => {
                  this.saveAction(false);
                }
              );
            } else {
              orderFormCustomerDetailIds.push(this.line.id);
              let workLineElements = this.orderFormCustomerDetails.filter(
                (el) => el.parentId == this.line.id
              );
              for (let index = 0; index < workLineElements.length; index++) {
                const element = workLineElements[index];
                element.referencielTvaId = this.line.referencielTvaId;
                orderFormCustomerDetailIds.push(element.id);
              }
              this.updateTvaOrderFormCustomerDetail({
                orderFormCustomerDetailIds: orderFormCustomerDetailIds,
                referencielTvaId: 5,
              });
              this.applyOrderFormCustomerDeduction();
            }
          });
      } else if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] == 5
      ) {
        this.$bvModal
          .msgBoxConfirm("Est-ce une autoliquidation ?", {
            title: "TVA à 0% détectée",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, appliquer à toutes les lignes",
            cancelTitle: "Non",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.orderFormCustomerOption.isReverseCharge = true;
              this.loopOrderFormCustomerDetails({ referencielTvaId: 5 }).then(
                () => {
                  this.saveAction(false);
                }
              );
            } else {
              this.updateOrderFormCustomerDetail({
                orderFormCustomerDetail: this.line,
              });
              this.applyOrderFormCustomerDeduction();
            }
          });
      } else if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] != 5 &&
        this.line.type == 6
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "Voulez-vous appliquer cette TVA à toutes les autres lignes ?",
            {
              title:
                "TVA à " +
                referencielTvaTranslate(this.line[this.dataKey]) +
                " détectée",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui, appliquer à toutes les lignes",
              cancelTitle: "Non",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.loopOrderFormCustomerDetails({
                referencielTvaId: this.line.referencielTvaId,
              }).then(() => {
                this.saveAction(false);
              });
            } else {
              orderFormCustomerDetailIds.push(this.line.id);
              let workLineElements = this.orderFormCustomerDetails.filter(
                (el) => el.parentId == this.line.id
              );
              for (let index = 0; index < workLineElements.length; index++) {
                const element = workLineElements[index];
                element.referencielTvaId = this.line.referencielTvaId;
                orderFormCustomerDetailIds.push(element.id);
              }
              this.updateTvaOrderFormCustomerDetail({
                orderFormCustomerDetailIds: orderFormCustomerDetailIds,
                referencielTvaId: this.line.referencielTvaId,
              });
              this.applyOrderFormCustomerDeduction();
            }
          });
      } else if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] != 5
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "Voulez-vous appliquer cette TVA à toutes les autres lignes ?",
            {
              title:
                "TVA à " +
                referencielTvaTranslate(this.line[this.dataKey]) +
                " détectée",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui, seulement aux existantes",
              cancelTitle: "Non",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.loopOrderFormCustomerDetails({
                referencielTvaId: this.line[this.dataKey],
              }).then(() => {
                this.saveAction(false);
              });
            } else {
              this.updateOrderFormCustomerDetail({
                orderFormCustomerDetail: this.line,
              });
              this.applyOrderFormCustomerDeduction();
            }
          });
      }
    },
    saveAction(bool) {
      this.applyOrderFormCustomerDeduction();
      this.updateOrderFormCustomer({
        orderFormCustomer: this.orderFormCustomer,
        loading: bool,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
