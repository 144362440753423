<template lang="pug">
  div(v-if="!preview" style='max-width:400px')
    div.d-flex(:class="edit ? 'justify-content-between' : ''")
      span {{text}}
        span(v-if="isNullable" @mouseover="showTools = true" @mouseleave="showTools = false") {{ begginingWorkDate }} 
          span.cursor-pointer.bold(v-if="showTools && !disabled")
            feather-icon(icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="'Modifier la date'") 
            feather-icon(icon="XIcon" size="18" @click="cancelAffectDate"  v-b-tooltip.hover.top="'Rétirer la date'") 
        a(v-else @click="affectDate") définir
      span.cursor-pointer.text-primary(v-if="edit"  @click="edit = false") Fermer
    div.mb-1.d-flex(v-if="edit")
      div.w-100
        date-range-picker(ref="picker" :showDropdowns='true' id="dates" style="width: 100%" :show-week-numbers="true" :timePicker="false" :single-date-picker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="datePicker" :auto-apply="true")
  div(v-else-if="isNullable")
    div.d-flex
      span {{ text }} {{ begginingWorkDate }} 
</template>
<script>
import { BButton, BFormDatepicker } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  var dayjs = require('dayjs')
  var quarterOfYear = require('dayjs/plugin/quarterOfYear')
  var utc = require('dayjs/plugin/utc')
  var timezone = require('dayjs/plugin/timezone')
  var advanced = require('dayjs/plugin/advancedFormat')
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault('Europe/Paris')
  dayjs.extend(advanced)
  dayjs.extend(quarterOfYear)
  const config = {
    direction: 'ltr',
    format: 'dd/mm/yyyy',
    separator: ' - ',
    applyLabel: 'Appliquer',
    cancelLabel: 'Annuler',
    weekLabel: 'S',
    customRangeLabel: 'Période personnalisée',
    daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ],
    firstDay: 1
  }
  var defaultRange = {
    'Aujourd\'hui': [
      new Date(dayjs()),
      new Date(dayjs())
    ],
    'Demain': [
      new Date(dayjs().add(1, 'day')),
      new Date(dayjs().add(1, 'day'))
    ],
    'Hier': [
      new Date(dayjs().subtract(1, 'day')),
      new Date(dayjs().subtract(1, 'day'))
    ],
    'Dans 1 mois': [
      new Date(dayjs().add(1, 'month')),
      new Date(dayjs().add(1, 'month'))
    ]
  }

export default {
  components: {
    BButton,
    BFormDatepicker,
    DateRangePicker
  },
  props: {
    preview: {
      default: false,
    },
    documentDetailKey:{
      type: String,
      default: null
    },
    text: {
      type: String,
      default: 'Date :'
    },
    keyValue: {
      type: String
    },
    disabled: {
      default: false
    }
  },
  data () {
    return {
      config: config,
      defaultRange: defaultRange,
      edit: false,
      date: null,
      showTools: false,
    }
  },
  methods: {
    affectDate() {
      this.orderFormCustomer.orderFormCustomerBtp[this.keyValue] = new Date(this.orderFormCustomer.documentDate);
      this.edit = true;
      this.$emit('setValue', new Date(this.orderFormCustomer.documentDate));
    },
    cancelAffectDate() {
      this.orderFormCustomer.orderFormCustomerBtp[this.keyValue] = null;
      this.edit = false;
      this.$emit('setValue', null);
    }
  },
  computed: {
    isNullable() {
      return this.orderFormCustomer.orderFormCustomerBtp.workStartDate != null
    },
    begginingWorkDate () {
      if (this.orderFormCustomer.orderFormCustomerBtp[this.keyValue] == null) {
        return null;
      } else {
        return dayjs(this.orderFormCustomer.orderFormCustomerBtp[this.keyValue]).format('DD/MM/YYYY')
      }
    },
    datePicker:{
      get() {
        return {
          startDate:dayjs(this.orderFormCustomer.orderFormCustomerBtp[this.keyValue]),
          endDate:dayjs(this.orderFormCustomer.orderFormCustomerBtp[this.keyValue])
        }
      },
      set(value) {
        this.$emit('setValue', new Date(value.startDate));
        this.edit=false;
      }
    },
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer; 
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      }
    },
  }
}
</script>
