<template lang="pug">
  div(@mouseover="showTools = true" @mouseleave="showTools = false" v-if="!preview")
    div.d-flex(:class="edit ? 'justify-content-between' : ''")
      span Compte bancaire associé : 
        span.no-printting.text-primary.cursor-pointer.ml-1(v-if="orderFormCustomer.bankAccountId==null && !disabled" @click="edit=!edit") + Ajouter
        span.no-printting.bold(v-else-if="!edit" :class="!disabled ? 'cursor-pointer' : ''") {{orderFormCustomer.bankAccountLabel}}
            feather-icon.cursor-pointer.feather.ml-1.feather(v-if="showTools && !disabled" icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="'Modifier le compte bancaire'") 
            feather-icon.cursor-pointer.feather(v-if="showTools && !disabled" icon="XIcon" size="18"  @click="deleteBankAccountLocal" v-b-tooltip.hover.top="'Retirer'")
      span.no-printting.cursor-pointer.text-primary(v-if="edit"  @click="edit = false") Fermer
    ul.ml-1(v-if="!edit && orderFormCustomer.bankAccountId!==null")
        li Banque : {{orderFormCustomer.bankAccountName}}
        li IBAN : {{orderFormCustomer.bankAccountIban}}
        li BIC : {{orderFormCustomer.bankAccountBic}}
    div.no-printting.mb-1.d-flex(v-if="edit")
      div.w-100
        b-form-group(v-if="!orderFormCustomer.bankAccountId || edit" label-for='affair')
            v-select#bankAccount(ref="selectBankAccount" :loading='isLoadingBankAccount' :value='orderFormCustomer.bankAccountName' @input='setValue' :options='bankAccountsList.map((elem)=>{return {label:elem.name, value:elem.id}})' placeholder='Selectionner un compte')
                template(v-slot:no-options='')
                    template  Aucun compte trouv&eacute;
  div(v-else-if="orderFormCustomer.bankAccountId!==null")
    ul.ml-1
        li Banque : {{orderFormCustomer.bankAccountName}}
        li IBAN : {{orderFormCustomer.bankAccountIban}}
        li BIC : {{orderFormCustomer.bankAccountBic}}
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      showTools: false,
    };
  },
  computed: {
    ...mapGetters(["bankAccountsList", "isLoadingBankAccount"]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
  },
  created() {
    if (!this.bankAccountsList || this.bankAccountsList.length == 0) {
      this.getBankAccounts({});
    }
  },
  methods: {
    ...mapActions(["getBankAccounts"]),
    deleteBankAccountLocal() {
      let bank = {
        id: null,
      };
      this.setValue(bank);
    },
    setValue(value) {
      if (value.id !== 0 && value.id !== null) {
        this.$emit(
          "setValue",
          this.bankAccountsList.find((elem) => {
            return elem.id == value.value;
          })
        );
        this.edit = false;
      } else {
        this.$emit("setValue", value);
      }
    },
  },
};
</script>
