import { render, staticRenderFns } from "./Holdback.vue?vue&type=template&id=68ae3ebe&scoped=true&lang=pug&"
import script from "./Holdback.vue?vue&type=script&lang=js&"
export * from "./Holdback.vue?vue&type=script&lang=js&"
import style0 from "./Holdback.vue?vue&type=style&index=0&id=68ae3ebe&scoped=true&lang=css&"
import style1 from "./Holdback.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ae3ebe",
  null
  
)

export default component.exports