<template lang="pug">
tr
  td(v-for="(key, i) in Object.keys(orderTrackingCustomerHeader)" :key="i" v-if="orderTrackingCustomerHeader[key].display" :class="orderTrackingCustomerHeader[key].display ? orderTrackingCustomerHeader[key].class : ''" :style="'background:'+orderFormCustomer.institutionColor") 
    .header C {{orderTrackingCustomerHeader[key].text}}    
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    preview: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["orderFormCustomer", "getOrderTrackingCustomerHeader"]),
    orderTrackingCustomerHeader() {
      if (this.preview == "order-tracking-customer-pdf") {
        let header = this.getOrderTrackingCustomerHeader;
        header["referencielTvaId"].display = false;
        header["total"].display = false;
        header["situationRemainingQuantity"].display = false;
        header["situationRemainingAmount"].display = false;
        header["situationRemainingPercent"].display = false;
        return header;
      } else {
        return this.getOrderTrackingCustomerHeader;
      }
    },
  },
};
</script>
