<template lang="pug">
    .row
        .col-md-6.toolbar.w-100.d-flex
            b-button.mr-1.button-tools(@click="newLine({type:4, unit : 1, indexArray: lastOrderFormCustomerLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'") 
                div(v-if="isLoadingLine4")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Fourniture
            b-button.mr-1.button-tools(@click="newLine({type: 5, unit: 2, indexArray: lastOrderFormCustomerLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'") 
                div(v-if="isLoadingLine5")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Main d'oeuvre
            b-button.mr-1.button-tools(@click="newLine({type : 6, unit: 1, indexArray: lastOrderFormCustomerLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'") 
                div(v-if="isLoadingLine6")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Ouvrage
            b-dropdown.mr-1( text="..." :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'") 
                b-dropdown-item(@click="newLine({type: 7, unit: 1, indexArray: lastOrderFormCustomerLine, review: false})") Sous-traitance
                b-dropdown-item(@click="newLine({type: 8, unit: 1, indexArray: lastOrderFormCustomerLine, review: false})") Matériel
                b-dropdown-item(@click="newLine({type: 9, unit: 1, indexArray: lastOrderFormCustomerLine, review: false})") Autre
        .col-md-6.toolbar.w-100.d-flex.align-right.justify-content-end
            b-dropdown.mr-1(v-if="includeSection" text="Section ..." :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'") 
                b-dropdown-item(@click="newLine({type: 0, indexArray: lastOrderFormCustomerLine, review: false})") Section
                b-dropdown-item(@click="newLine({type: 11, indexArray: lastOrderFormCustomerLine, review: false})") Sous-Section
            b-button.mr-1.button-tools(v-else @click="newLine({type:0, indexArray: lastOrderFormCustomerLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-dark'") 
                div(v-if="isLoadingLine0")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    span Section
            b-button.mr-1.button-tools(@click="addSubTotalLine({indexArray: lastOrderFormCustomerLine})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-dark'") 
                div(v-if="isLoadingLine13")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    span Sous-total
            b-button.mr-1.button-tools(@click="newLine({type:1, indexArray: lastOrderFormCustomerLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-dark'") 
                div(v-if="isLoadingLine1")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    span Texte
            b-dropdown( text="Saut ..." :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'" :style="disabled ? 'pointer-events: none;' : ''") 
                b-dropdown-item(@click="newLine({type: 12, indexArray: lastOrderFormCustomerLine, review: false})") Saut de ligne
                b-dropdown-item(@click="newLine({type: 2, indexArray: lastOrderFormCustomerLine, review: false})") Saut de page
</template>
<script>
import { BButton, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import { calculateSubTotalOrderFormCustomerLine } from "@/types/api-orisis/library/DocumentOperations";

export default {
  data() {
    return {
      isLoadingLine0: false,
      isLoadingLine1: false,
      isLoadingLine2: false,
      isLoadingLine3: false,
      isLoadingLine4: false,
      isLoadingLine5: false,
      isLoadingLine6: false,
      isLoadingLine7: false,
      isLoadingLine8: false,
      isLoadingLine9: false,
      isLoadingLine10: false,
      isLoadingLine11: false,
      isLoadingLine12: false,
      isLoadingLine13: false,
    };
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    disabled: {
      default: false,
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters([
      "orderFormCustomer",
      "institutionSettingsActive",
      "orderFormCustomerOption",
    ]),
    lastOrderFormCustomerLine() {
      return this.$store.getters.getOrderFormCustomerDetails.length - 1;
    },
    includeSection() {
      return (
        this.$store.getters.getOrderFormCustomerDetails.filter(
          (el) => el.type == 0
        ).length > 0
      );
    },
  },
  methods: {
    calculateSubTotalOrderFormCustomerLine,
    ...mapActions(["addOrderFormCustomerLine"]),
    async addSubTotalLine(line) {
      this.isLoadingLine13 = true;
      await this.calculateSubTotalOrderFormCustomerLine(
        {
          ...line,
          type: 13,
          review: false,
          orderFormCustomerId: this.orderFormCustomer.id,
          referencielTvaId: 5,
          quantity: 1,
          isOption: false,
          description: "Sous-total",
        },
        false,
        true
      );
      this.isLoadingLine13 = false;
    },
    newLine(line) {
      if (line.type == 0) this.isLoadingLine0 = true;
      if (line.type == 1) this.isLoadingLine1 = true;
      if (line.type == 2) this.isLoadingLine2 = true;
      if (line.type == 3) this.isLoadingLine3 = true;
      if (line.type == 4) this.isLoadingLine4 = true;
      if (line.type == 5) this.isLoadingLine5 = true;
      if (line.type == 6) this.isLoadingLine6 = true;
      if (line.type == 7) this.isLoadingLine7 = true;
      if (line.type == 8) this.isLoadingLine8 = true;
      if (line.type == 9) this.isLoadingLine9 = true;
      if (line.type == 10) this.isLoadingLine10 = true;
      if (line.type == 11) this.isLoadingLine11 = true;
      if (line.type == 12) this.isLoadingLine12 = true;

      this.addOrderFormCustomerLine({
        orderFormCustomerDetail: {
          ...line,
          orderFormCustomerId: this.orderFormCustomer.id,
          referencielTvaId: this.orderFormCustomerOption.isReverseCharge
            ? 5
            : this.institutionSettingsActive.referencielTvas.find(
                (elem) => elem.isDefault == true
              ).referencielTva.id,
        },
        indexArray: line.indexArray,
        review: line.review,
      })
        .then((res) => {
          if (line.type == 0) this.isLoadingLine0 = false;
          if (line.type == 1) this.isLoadingLine1 = false;
          if (line.type == 2) this.isLoadingLine2 = false;
          if (line.type == 3) this.isLoadingLine3 = false;
          if (line.type == 4) this.isLoadingLine4 = false;
          if (line.type == 5) this.isLoadingLine5 = false;
          if (line.type == 6) this.isLoadingLine6 = false;
          if (line.type == 7) this.isLoadingLine7 = false;
          if (line.type == 8) this.isLoadingLine8 = false;
          if (line.type == 9) this.isLoadingLine9 = false;
          if (line.type == 10) this.isLoadingLine10 = false;
          if (line.type == 11) this.isLoadingLine11 = false;
          if (line.type == 12) this.isLoadingLine12 = false;
          if (line.type == 13) this.isLoadingLine13 = false;
        })
        .catch((err) => {
          if (line.type == 0) this.isLoadingLine0 = false;
          if (line.type == 1) this.isLoadingLine1 = false;
          if (line.type == 2) this.isLoadingLine2 = false;
          if (line.type == 3) this.isLoadingLine3 = false;
          if (line.type == 4) this.isLoadingLine4 = false;
          if (line.type == 5) this.isLoadingLine5 = false;
          if (line.type == 6) this.isLoadingLine6 = false;
          if (line.type == 7) this.isLoadingLine7 = false;
          if (line.type == 8) this.isLoadingLine8 = false;
          if (line.type == 9) this.isLoadingLine9 = false;
          if (line.type == 10) this.isLoadingLine10 = false;
          if (line.type == 11) this.isLoadingLine11 = false;
          if (line.type == 12) this.isLoadingLine12 = false;
          if (line.type == 13) this.isLoadingLine13 = false;
        });
    },
  },
  mounted() {
    this.isLoadingLine0 = false;
    this.isLoadingLine1 = false;
    this.isLoadingLine2 = false;
    this.isLoadingLine3 = false;
    this.isLoadingLine4 = false;
    this.isLoadingLine5 = false;
    this.isLoadingLine6 = false;
    this.isLoadingLine7 = false;
    this.isLoadingLine8 = false;
    this.isLoadingLine9 = false;
    this.isLoadingLine10 = false;
    this.isLoadingLine11 = false;
    this.isLoadingLine12 = false;
    this.isLoadingLine13 = false;
  },
};
</script>
