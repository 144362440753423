<template lang="pug">
  td
    div(v-if="orderFormCustomerHeader[dataKey].inputType == 1") 
      span(v-model="line[dataKey]") 
      span {{orderFormCustomerHeader[dataKey].prefix }} 
      span {{ formatNumber(line[dataKey]) }}
      span {{orderFormCustomerHeader[dataKey].unit}}
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 2") 
      span {{formatNumberToString(line[dataKey])}}
      span &nbsp;{{orderFormCustomerHeader[dataKey].unit}}
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 10") 
      SelectCol(:line="line" :canEdit="false" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :editFocus="editFocus" :preview="true") 
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 24") 
      SelectCatalog(:line="line" :canEdit="false" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :editFocus="editFocus" :preview="true") 
</template>

<script>
import TextCol from '@/components/invoice/order-form-customer/builder/table/cols/TextCol.vue'
import SelectCatalog from '@/components/invoice/order-form-customer/builder/table/cols/SelectCatalog.vue'
import SelectCol from '@/components/invoice/order-form-customer/builder/table/cols/SelectCol.vue'
import { formatNumber, formatNumberToString } from "@/types/api-orisis/library/FormatOperations.ts"

export default {
  data () {
    return {}
  },
  props: {
    edit: {
      type: Boolean,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    orderFormCustomerHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    },
    editFocus: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    formatNumber,
    formatNumberToString,
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TextCol,
    SelectCatalog,
    SelectCol
  }
}
</script>

<style>
.input {
  padding: 0.375rem 0.75rem;
}
.no-edit {
  border: none;
}
</style>
