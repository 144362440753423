<template lang="pug">
  tr(:class="line.isOption ? 'isOption' : ''" v-if="(line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18)" :style="line.type == 2 ? 'page-break-after: always;' : ''")
    OrderFormCustomerPreviewDetailCommonLine.m-0(v-for="(key, i) in Object.keys(orderFormCustomerHeader)" :key="i"  :dataKey="key" v-if="orderFormCustomerHeader[key].display && (line.type == 4 || line.type == 5 || line.type == 7 || line.type == 8 || line.type == 9 || line.type == 6) && (line.type == 4 || line.type == 5 || line.type == 7 || line.type == 8 || line.type == 9 || line.type == 6 ? !(line.quantity == 0 && orderFormCustomerOption.hideLinesQuantityEmpty) : true)" :class="orderFormCustomerHeader[key].class" :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :editFocus="editFocus" :edit="edit") 
    OrderFormCustomerPreviewDetailSectionLine.m-0(v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 0 || line.type == 11" :line="line" :class="orderFormCustomerHeader[key].class" :colspan="setColspanSection(orderFormCustomerHeader[key])" :orderFormCustomerHeader="orderFormCustomerHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
    OrderFormCustomerPreviewDetailSubTotalLine.m-0(v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 13" :line="line" :class="orderFormCustomerHeader[key].class" :colspan="setColspanSubTotalLine(orderFormCustomerHeader[key])"  :orderFormCustomerHeader="orderFormCustomerHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
    OrderFormCustomerPreviewDetailTextLine.m-0(v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 1" :class="orderFormCustomerHeader[key].class" :colspan="setColspanSection(orderFormCustomerHeader[key])" :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :editFocus="editFocus" :edit="edit")
    OrderFormCustomerPreviewDetailBreakLine.m-0(v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 12"  :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :colspan="setColspanAll(orderFormCustomerHeader[key])" :edit="edit" :editFocus="editFocus")
    OrderFormCustomerPreviewDetailPriceLine.m-0(v-for="(key, i) in ['index', 'description', 'referencielTvaId', 'total']" :key="i"  :dataKey="key" v-if="orderFormCustomerHeader[key].display && (line.type == 3)" :class="orderFormCustomerHeader[key].class" :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :editFocus="editFocus" :edit="edit")
</template>

<script>
import OrderFormCustomerPreviewDetailCommonLine from "@/components/invoice/order-form-customer/preview/table/lines/OrderFormCustomerPreviewDetailCommonLine.vue";
import OrderFormCustomerPreviewDetailSectionLine from "@/components/invoice/order-form-customer/preview/table/lines/OrderFormCustomerPreviewDetailSectionLine.vue";
import OrderFormCustomerPreviewDetailTextLine from "@/components/invoice/order-form-customer/preview/table/lines/OrderFormCustomerPreviewDetailTextLine.vue";
import OrderFormCustomerPreviewDetailBreakPageLine from "@/components/invoice/order-form-customer/preview/table/lines/OrderFormCustomerPreviewDetailBreakPageLine.vue";
import OrderFormCustomerPreviewDetailBreakLine from "@/components/invoice/order-form-customer/preview/table/lines/OrderFormCustomerPreviewDetailBreakLine.vue";
import OrderFormCustomerPreviewDetailWorkLine from "@/components/invoice/order-form-customer/preview/table/lines/OrderFormCustomerPreviewDetailWorkLine.vue";
import OrderFormCustomerPreviewDetailPriceLine from "@/components/invoice/order-form-customer/preview/table/lines/OrderFormCustomerPreviewDetailPriceLine.vue";
import OrderFormCustomerPreviewDetailSubTotalLine from "@/components/invoice/order-form-customer/preview/table/lines/OrderFormCustomerPreviewDetailSubTotalLine.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      edit: false,
      editFocus: false,
    };
  },
  computed: {
    ...mapGetters(["orderFormCustomerOption"]),
    orderFormCustomerHeader: {
      get() {
        return this.$store.getters.getOrderFormCustomerHeader;
      },
      set(val) {
        this.$store.commit("SET_INVOICE_HEADER", {
          ...orderFormCustomerHeader,
          val,
        });
      },
    },
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    columnText() {
      if (this.orderFormCustomer.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  watch: {
    editFocus(val) {
      if (val == false) {
        this.edit = false;
      }
    },
  },
  methods: {
    setColspanAll(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return Object.entries(this.orderFormCustomerHeader).filter(
          (el) => el[1].display == true
        ).length;
      } else {
        return 1;
      }
    },
    setColspanSection(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.orderFormCustomerHeader).filter(
            (el) => el[1].display == true
          ).length - 1
        );
      } else {
        return 1;
      }
    },
    setColspanSubTotalLine(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.orderFormCustomerHeader).filter(
            (el) => el[1].display == true
          ).length - 2
        );
      } else {
        return 1;
      }
    },
  },
  components: {
    OrderFormCustomerPreviewDetailCommonLine,
    OrderFormCustomerPreviewDetailSectionLine,
    OrderFormCustomerPreviewDetailTextLine,
    OrderFormCustomerPreviewDetailBreakPageLine,
    OrderFormCustomerPreviewDetailBreakLine,
    OrderFormCustomerPreviewDetailWorkLine,
    OrderFormCustomerPreviewDetailPriceLine,
    OrderFormCustomerPreviewDetailSubTotalLine,
  },
};
</script>
