<template lang="pug">
  td
    div(v-if="orderFormCustomerHeader[dataKey].inputType == 1") 
      span {{line[dataKey]}}
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 21")
      span(v-html="line['description']") 
</template>
<script>
import { mapGetters } from 'vuex'
import TextCol from '@/components/invoice/order-form-customer/builder/table/cols/TextCol.vue'
import NumberCol from '@/components/invoice/order-form-customer/builder/table/cols/NumberCol.vue'
import QuillEditorCol from '@/components/invoice/order-form-customer/builder/table/cols/QuillEditorCol.vue'

export default {
  data () {
    return {}
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    orderFormCustomerHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    },
    editFocus: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    }
  },
  components: {
    TextCol,
    NumberCol,
    QuillEditorCol
  }
}
</script>
