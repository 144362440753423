<template>
  <div
    v-if="!preview && !disabled"
    class="cursor-pointer"
    @mouseover="mouseOn = true"
    @mouseleave="mouseOn = false"
  >
    <div @click="toggleDescription(!orderFormCustomerOption.showDescription)">
      <span class="text-primary font-weight-bold no-printting">
        {{
          orderFormCustomerOption.showDescription == true
            ? "- Masquer la description"
            : "+ Ajouter une description"
        }}
      </span>
    </div>
    <quill-editor
      @focus="focusFunction(true)"
      @blur="setValue"
      :key="'description' + orderFormCustomer.id"
      v-show="orderFormCustomerOption.showDescription"
      class="editor quill-object"
      :class="editFocus || mouseOn ? 'edit' : 'no-edit'"
      v-model="orderFormCustomer.description"
      :options="editorOption"
    />
  </div>
  <div v-else>
    <span
      v-if="orderFormCustomerOption.showDescription"
      v-html="orderFormCustomer.description"
    ></span>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      mouseOn: false,
      dataOrigine: null,
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      editFocus: false,
    };
  },
  created() {
    this.dataOrigine = this.orderFormCustomer.description
      ? structuredClone(this.orderFormCustomer.description)
      : null;
  },
  computed: {
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    orderFormCustomerOption: {
      get() {
        return this.$store.getters.orderFormCustomerOption;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_OPTION", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateOrderFormCustomer", "updateOrderFormCustomerOption"]),
    focusFunction(val) {
      this.editFocus = true;
    },
    setValue() {
      if (this.orderFormCustomer.description != this.dataOrigine) {
        this.$emit("setValue", this.orderFormCustomer.description);
        this.editFocus = false;
      }
    },
    toggleDescription(val) {
      this.orderFormCustomerOption.showDescription = val;
      this.updateOrderFormCustomerOption({
        orderFormCustomerOption: this.orderFormCustomerOption,
        loading: false,
      });
    },
  },
  components: {
    quillEditor,
  },
};
</script>
