<template lang="pug"> 
  div(v-if="!preview" style='max-width:400px')
    div.d-flex(:class="edit ? 'justify-content-end' : ''")
      span(v-if="!edit") {{ text }}
        span(v-if="orderFormCustomer[keyValue] && !edit" @mouseover="showTools = true" @mouseleave="showTools = false") {{ begginingWorkDate }} 
          span.cursor-pointer.bold(v-if="showTools && canChangeDate && !disabled") 
            feather-icon(icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="'Modifier la date'") 
            a(v-if="orderFormCustomer[keyValue] == null" @click="edit = !edit") définir
      span.cursor-pointer.text-primary(v-if="edit" @click="edit = false") Fermer
    div.mb-1.d-flex(v-if="edit")
      div.w-100
        date-range-picker(:showDropdowns='true' :maxDate="today" ref="picker" id="dates" style="width: 200px" :show-week-numbers="true" :timePicker="false" :single-date-picker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="datePicker" :auto-apply="true")
  div(v-else)
    div.d-flex
      span {{ text }} {{ begginingWorkDate }} 
</template>
<script>
import { mapGetters } from "vuex";
import { statusCheck } from "@/types/api-orisis/enums/enums";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Aujourd'hui": [new Date(dayjs()), new Date(dayjs())],
  Demain: [new Date(dayjs().add(1, "day")), new Date(dayjs().add(1, "day"))],
  Hier: [
    new Date(dayjs().subtract(1, "day")),
    new Date(dayjs().subtract(1, "day")),
  ],
  "Dans 1 mois": [
    new Date(dayjs().add(1, "month")),
    new Date(dayjs().add(1, "month")),
  ],
};

export default {
  components: {
    DateRangePicker,
  },
  props: {
    preview: {
      default: false,
    },
    documentDetailKey: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: "Date :",
    },
    keyValue: {
      type: String,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      config: config,
      defaultRange: defaultRange,
      edit: false,
      date: null,
      showTools: false,
      today: new Date(),
      statusCheck,
    };
  },
  computed: {
    ...mapGetters(["orderFormCustomersList"]),
    begginingWorkDate() {
      return dayjs(this.orderFormCustomer[this.keyValue]).format("DD/MM/YYYY");
    },
    datePicker: {
      get() {
        return {
          startDate: dayjs(this.orderFormCustomer[this.keyValue]),
          endDate: dayjs(this.orderFormCustomer[this.keyValue]),
        };
      },
      set(value) {
        this.$emit("setValue", new Date(value.startDate));
        this.edit = false;
      },
    },
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    // lastDocumentDate() {
    //   let orderFormCustomersListFilter = this.orderFormCustomersList.filter(
    //     (el) => el.status == 2 || el.status == 3 || el.status == 4
    //   );
    //   return orderFormCustomersListFilter.length > 0
    //     ? orderFormCustomersListFilter.reduce(
    //         (max, obj) => (obj.documentDate > max ? obj.documentDate : max),
    //         orderFormCustomersListFilter[0].documentDate
    //       )
    //     : null;
    // },
    canChangeDate() {
      return this.statusCheck.orderFormCustomerEditable.includes(
        this.orderFormCustomer.status
      );
    },
  },
};
</script>
