<template lang="pug">
div(v-if="(isWorkLineWithElement() || !orderFormCustomerHeader[dataKey].editable) || disabled")
  div.content-span-document-detail
    span(v-model="line[dataKey]") 
    span {{ orderFormCustomerHeader[dataKey].prefix }} 
    span {{ formatNumberToString(line[dataKey]) }}
    span {{ orderFormCustomerHeader[dataKey].unit }}
.marginCol(v-else-if="!preview && orderFormCustomerOption.calculateMargin && (dataKey == 'unitPriceHt' || dataKey == 'disbursedNetHt')")
  .marginCol-content
    input.w-100.margin-detail(@focus="emitEditFocus(true)" @blur="blurFunction(false, dataKey)" :style="line[dataKey] < 0 ? 'color:red!important' : ''" :disabled="line.isDisabled" :state="line['disbursedNetHt'] >= 0 && line['disbursedNetHt'] != null ? null : false" type='number' :class="edit ? 'edit' : 'no-edit' " v-model="line[dataKey]")
    .hide-margin-detail(v-if="!(dataKey == 'disbursedNetHt' && productProvidersRelated.length == 0)")
      div.d-flex 
        .w-100(v-if="dataKey == 'unitPriceHt'")
          b-form-group(label='Marge U. HT' label-for='marginBrutHt')
            ejs-numerictextbox(:enabled="false" format="c2" :showSpinButton="false" v-model="line['marginBrutHt']")
        .w-100(v-else-if="dataKey == 'disbursedNetHt' && productProvidersRelated.length > 0")
          b-form-group(label='Produit fournisseur associé' label-for='productProviderRelated')
            b-form-select.edit.select-product-provider-related(@change="changeProductProviderRelated" v-model="line['productProviderId']")
              b-form-select-option(v-for="option in productProvidersRelated" :key="option.id" :value="option.id") {{ (option.reference ? option.reference : 'Aucune référence') +' - '+ (option.label ? option.label : 'Produit sans libellé') }}
input.w-100(v-else @focus="emitEditFocus(true)" @blur="blurFunction(false, dataKey)" :disabled="line.isDisabled ? true : false" type="number" min='0' :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled ? 'disabled' :'']" v-model="line[dataKey]")          
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { calculateSubTotalOrderFormCustomerLineBySection } from "@/types/api-orisis/library/DocumentOperations";
import {
  formatNumber,
  formatNumberToString,
  formatNumberDecimal,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { applyOrderFormCustomerDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";

export default {
  data() {
    return {
      calculateSubTotalOrderFormCustomerLineBySection,
    };
  },
  computed: {
    ...mapGetters([
      "orderFormCustomer",
      "deduction",
      "orderFormCustomerOption",
      "institutionSettingsActive",
      "productProvidersList",
      "catalog",
    ]),
    orderFormCustomerDetails: {
      get() {
        return this.$store.getters["getOrderFormCustomerDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_DETAILS", value);
      },
    },
    productProvidersRelated() {
      return this.productProvidersList
        ? this.productProvidersList.filter(
            (el) => el.productId == this.line.productId
          )
        : [];
    },
  },
  methods: {
    ...mapActions(["updateOrderFormCustomerDetail"]),
    formatNumber,
    formatNumberToString,
    formatNumberDecimal,
    applyOrderFormCustomerDeduction,
    isWorkLineWithElement() {
      if (
        this.dataKey == "unitPriceHt" ||
        this.dataKey == "disbursedNetHt" ||
        this.dataKey == "marginCoefficient"
      ) {
        if (this.line.type == 6) {
          let workLineElements = this.orderFormCustomerDetails.filter(
            (el) => el.parentId == this.line.id
          );
          if (workLineElements && workLineElements.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeProductProviderRelated(val) {
      let product = this.catalog.find((el) => el.id == this.line.productId);
      let productProvider = this.productProvidersList.find(
        (el) => el.id == val
      );
      this.line.productProviderId = productProvider.id;
      this.line["marginCoefficient"] = product.priceHT
        ? productProvider.netPrice
          ? this.formatNumber(product.priceHT / productProvider.netPrice)
          : 0
        : getMarginCoefficient(this.line, this.institutionSettingsActive);
      this.line["unitPriceHt"] = product.priceHT
        ? Math.round(product.priceHT * 100) / 100
        : productProvider.netPrice
        ? Math.round(
            productProvider.netPrice *
              getMarginCoefficient(this.line, this.institutionSettingsActive)
          )
        : this.line["unitPriceHt"];
      this.line["disbursedNetHt"] = productProvider.netPrice
        ? productProvider.netPrice
        : this.line["dryDisbursed"];

      this.line["marginBrutHt"] =
        product.priceHT == 0
          ? this.formatNumber(
              this.line["unitPriceHt"] - this.line["disbursedNetHt"]
            )
          : productProvider.grossMarginHT
          ? productProvider.grossMarginHT
          : this.line["grossMarginHT"];

      this.line["marginRate"] =
        product.priceHT == 0
          ? this.formatNumber(
              (this.line["marginBrutHt"] / this.line["unitPriceHt"]) * 100
            )
          : productProvider.marginRate
          ? productProvider.marginRate
          : this.line["marginRate"];
      this.line["total"] = this.formatNumber(
        this.line["unitPriceHt"] * this.line["quantity"]
      );
      function getMarginCoefficient(line, institutionSettingsActive) {
        if (
          (line.type == 4 || line.type == 14) &&
          institutionSettingsActive.supplyMarginDefault &&
          institutionSettingsActive.supplyMarginDefault > 0
        ) {
          return institutionSettingsActive.supplyMarginDefault;
        } else if (
          (line.type == 5 || line.type == 17) &&
          institutionSettingsActive.manPowerMarginDefault &&
          institutionSettingsActive.manPowerMarginDefault > 0
        ) {
          return institutionSettingsActive.manPowerMarginDefault;
        } else if (
          line.type == 6 &&
          institutionSettingsActive.laborMarginDefault &&
          institutionSettingsActive.laborMarginDefault > 0
        ) {
          return Number(institutionSettingsActive.laborMarginDefault);
        } else if (
          (line.type == 7 || line.type == 15) &&
          institutionSettingsActive.subContractingMarginDefault &&
          institutionSettingsActive.subContractingMarginDefault > 0
        ) {
          return institutionSettingsActive.subContractingMarginDefault;
        } else if (
          (line.type == 8 || line.type == 16) &&
          institutionSettingsActive.equipementMarginDefault &&
          institutionSettingsActive.equipementMarginDefault > 0
        ) {
          return institutionSettingsActive.equipementMarginDefault;
        } else if (
          (line.type == 9 || line.type == 18) &&
          institutionSettingsActive.variousMarginDefault &&
          institutionSettingsActive.variousMarginDefault > 0
        ) {
          return institutionSettingsActive.variousMarginDefault;
        } else {
          return 1;
        }
      }
    },
    change(res, calculateSubTotal = false) {
      this.line[this.dataKey] = formatNumber(this.line[this.dataKey]);
      if (this.line[this.dataKey] > 100 && this.dataKey == "discount") {
        this.line[this.dataKey] = 100;
        this.change({ ...res, target: { value: 100 } }, true);
      } else {
        // On traite les lignes
        this.line["disbursedNetHt"] = formatNumber(this.line["disbursedNetHt"]);
        this.line["marginBrutHt"] = formatNumber(
          this.line["unitPriceHt"] - this.line["disbursedNetHt"]
        );
        this.line["marginRate"] = formatNumber(
          this.line["unitPriceHt"] == 0
            ? 0
            : (this.line["marginBrutHt"] / this.line["unitPriceHt"]) * 100
        );
        let discount =
          Math.round(((100 - this.line["discount"]) / 100) * 100) / 100;
        this.line["total"] = formatNumber(
          this.line["unitPriceHt"] * this.line["quantity"] * discount
        );
      }

      // On traite les lignes de composant d'un ouvrage
      if (
        this.line.type == 14 ||
        this.line.type == 15 ||
        this.line.type == 16 ||
        this.line.type == 17 ||
        this.line.type == 18
      ) {
        let workLine = this.orderFormCustomerDetails.find(
          (el) => el.id == this.line.parentId
        );
        let workLineElements = this.orderFormCustomerDetails.filter(
          (el) => el.parentId == this.line.parentId
        );
        let totalPriceHt = 0;
        let totalDryDisbursed = 0;
        for (let index = 0; index < workLineElements.length; index++) {
          const element = workLineElements[index];
          totalPriceHt += formatNumber(element.unitPriceHt * element.quantity);
          totalDryDisbursed += formatNumber(
            element.disbursedNetHt * element.quantity
          );
        }
        workLine.unitPriceHt = formatNumber(totalPriceHt);
        workLine.disbursedNetHt = formatNumber(totalDryDisbursed);
        workLine.marginBrutHt = formatNumber(
          workLine.unitPriceHt - workLine.disbursedNetHt
        );
        workLine.marginRate = formatNumber(
          workLine.unitPriceHt == 0
            ? 0
            : (workLine.marginBrutHt / workLine.unitPriceHt) * 100
        );

        // On met à jour le total
        workLine.total = formatNumber(workLine.unitPriceHt * workLine.quantity);
        this.updateOrderFormCustomerDetail({
          orderFormCustomerDetail: workLine,
        });
      }
      if (calculateSubTotal)
        this.calculateSubTotalOrderFormCustomerLineBySection(this.line);
    },
    async blurFunction(res, hiddenFields = null) {
      this.$emit("emitEditFocus", res);
      if (this.orderFormCustomerOption.calculateMargin) {
        this.unitPriceHtChange(hiddenFields);
      }
      await this.change(null, true);
      await this.updateOrderFormCustomerDetail({
        orderFormCustomerDetail: this.line,
      });
      this.applyOrderFormCustomerDeduction();
    },
    unitPriceHtChange(field) {
      if (field == "disbursedNetHt") {
        this.line["disbursedNetHt"] = formatNumber(this.line["disbursedNetHt"]);
        if (this.line["disbursedNetHt"] && this.line["disbursedNetHt"] > 0) {
          this.line["unitPriceHt"] = formatNumber(
            this.line["disbursedNetHt"] * this.line["marginCoefficient"]
          );
        } else {
          this.line["marginCoefficient"] = 0;
        }
      } else if (field == "marginCoefficient") {
        this.line["marginCoefficient"] = formatNumber(
          this.line["marginCoefficient"]
        );
        if (this.line["disbursedNetHt"] > 0) {
          this.line["unitPriceHt"] = formatNumber(
            this.line["disbursedNetHt"] * this.line["marginCoefficient"]
          );
        } else {
          this.line["disbursedNetHt"] = formatNumber(
            this.line["unitPriceHt"] / this.line["marginCoefficient"]
          );
        }
      } else if (field == "unitPriceHt") {
        this.line["unitPriceHt"] = formatNumber(this.line["unitPriceHt"]);
        this.line["marginCoefficient"] = this.line["disbursedNetHt"]
          ? formatNumber(this.line["unitPriceHt"] / this.line["disbursedNetHt"])
          : 0;
      }
      this.line["marginBrutHt"] = formatNumber(
        this.line["unitPriceHt"] - this.line["disbursedNetHt"]
      );
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
  },
  props: {
    preview: {
      default: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    orderFormCustomerHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    mouseOn: {
      type: Boolean,
    },
    disabled: {
      default: false,
    },
  },
};
</script>
