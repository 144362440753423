<template lang="pug">
div(class="invoiceHeader")
    b-row
      b-col.col-sm-6(class="invoiceHeaderLeft")
        img.logo.mb-2(v-if="orderFormCustomer.institutionLogo && !orderFormCustomerOption.hideInstitutionLogo" :src='orderFormCustomer.institutionLogo' :style="'width:' + institutionSelected.logoWidth + 'px !important;'")
        ul
          li.mb-2
            h3(:style="'color:'+orderFormCustomer.institutionColor+' !important'" v-if="(!orderFormCustomerOption.hideInstitutionName ) || workspaceSelected.id == 14 ") {{orderFormCustomer.institutionName}}
            div(v-if="!orderFormCustomerOption.hideInstitutionAddress")
              div {{orderFormCustomer.institutionAddress}}
              div(v-if="orderFormCustomer.institutionAddressComplement") {{orderFormCustomer.institutionAddressComplement}}
              div(v-if="orderFormCustomer.institutionZipCode || orderFormCustomer.institutionCity") {{orderFormCustomer.institutionZipCode}} {{orderFormCustomer.institutionCity}}
          li(v-if="!orderFormCustomerOption.hideUser").mt-2
            UserInfoOrderFormCustomer(:preview="true")
          li(v-if="collaboratorInfo && !orderFormCustomerOption.hideCollaboratorInCharge").mt-2
            div
              span.text-primary.font-weight-bold Dossier suivi par : 
            div
              span.font-weight-bold {{ collaboratorInfo.firstName }} {{ collaboratorInfo.lastName }}
            div
              span {{ collaboratorInfo.email ? "E-mail : " + collaboratorInfo.email : "" }}
            div
              span {{ collaboratorInfo.phoneNumber ? "Tél : " + collaboratorInfo.phoneNumber : "" }}
      b-col.col-sm-6(class="invoiceHeaderRight")
        div(style="min-height:135px")
          ul(style="height:fit-content")
            li
              h3(:style="'color:'+orderFormCustomer.institutionColor+' !important'") Bon de commande n° {{ orderFormCustomer.documentReference ?  orderFormCustomer.documentReference : 'en attente' }}
                span(style='font-weight:300')
            li
              SelectDate(keyValue="orderFormCustomerDate" @setValue="setOrderFormCustomerDate" orderFormCustomerDetailKey="orderFormCustomerDate" :preview="true" text="En date du ")
            li(v-if="orderFormCustomer.quoteId && initialDocument && initialDocument.documentReference && !orderFormCustomerOption.hideInitialQuote")
              span Provenant du devis : {{ initialDocument.documentReference }}
            li(v-if="orderFormCustomer.nature == 0")
              SelectCounterDate(keyValue="limitDate" @setValue="setSelectCounterDate" :preview="true" text="Valable jusqu'au ")
            li(v-if="orderFormCustomer.type == 2")
              SelectDateCEE(keyValue="technicalVisitDate" @setValue="setOrderFormCustomerTechnicalVisitDate" :preview="true" text="Visite technique le ")
            li.mt-1(v-if="(orderFormCustomer.type == 3 || orderFormCustomer.type == 2) && orderFormCustomer.orderFormCustomerBtp && (orderFormCustomer.orderFormCustomerBtp.workStartDate || orderFormCustomer.orderFormCustomerBtp.estimatedDurationNumber || orderFormCustomer.orderFormCustomerBtp.estimatedDurationType)")
              p {{ orderFormCustomer.orderFormCustomerBtp.workStartDate ? 'Début des travaux : ' + formatDate(orderFormCustomer.orderFormCustomerBtp.workStartDate) : '' }}{{ orderFormCustomer.orderFormCustomerBtp.estimatedDurationNumber ? ' - Durée estimée : ' + orderFormCustomer.orderFormCustomerBtp.estimatedDurationNumber + ' ' + translateDurationType(orderFormCustomer.orderFormCustomerBtp.estimatedDurationType) + (orderFormCustomer.orderFormCustomerBtp.estimatedDurationNumber > 1 && orderFormCustomer.orderFormCustomerBtp.estimatedDurationType != 3 ? 's' : '') : ''}}
        div
          SelectCompany(:preview="true")
        div(v-if="!orderFormCustomerOption.hideAffair")
          SelectAffair(:preview="true")
    b-row
      b-col.mb-0(cols="12" class="align-items-center invoiceDescription" style="margin-top: 10px;")
        OrderFormCustomerDescription(:key="'OrderFormCustomerDescription_'+orderFormCustomer.id" :preview="true")
    v-style
      |table:after { background-color: {{orderFormCustomer.institutionColor}} !important;}
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import SelectCompany from "@/components/invoice/order-form-customer/builder/SelectCompany";
import SelectAffair from "@/components/invoice/order-form-customer/builder/SelectAffair";
import SelectDate from "@/components/invoice/order-form-customer/builder/SelectDate";
import SelectDateCEE from "@/components/invoice/order-form-customer/builder/SelectDateCEE";
import SelectDateWork from "@/components/invoice/order-form-customer/builder/SelectDateWork";
import SelectPeriod from "@/components/invoice/order-form-customer/builder/SelectPeriod";
import OrderFormCustomerDescription from "@/components/invoice/order-form-customer/builder/OrderFormCustomerDescription";
import SelectCounterDate from "@/components/invoice/order-form-customer/builder/SelectCounterDate";
import UserInfoOrderFormCustomer from "@/components/invoice/order-form-customer/builder/UserInfoOrderFormCustomer";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";
export default {
  data() {
    return {
      collaboratorInfo: null,
      options: [
        { label: "Heure", id: 0 },
        { label: "Jour", id: 1 },
        { label: "Semaine", id: 2 },
        { label: "Mois", id: 3 },
        { label: "Année", id: 4 },
      ],
    };
  },
  mounted() {
    this.setCollaboratorInfo();
  },
  methods: {
    ...mapActions([
      "updateOrderFormCustomer",
      "getCompanyById",
      "getCollaboratorById",
    ]),
    formatDate,
    translateDurationType(id) {
      if (id != null) {
        return this.options[id].label.toLowerCase();
      } else {
        return null;
      }
    },
    setOrderFormCustomerDate(date) {
      this.orderFormCustomer = {
        ...this.orderFormCustomer,
        documentDate: date,
      };
      this.saveAction();
    },
    setOrderFormCustomerTechnicalVisitDate(date) {
      this.orderFormCustomer.orderFormCustomerCee = {
        ...this.orderFormCustomer.orderFormCustomerCee,
        technicalVisitDate: date,
      };
      this.saveAction();
    },
    setSelectCounterDate(date) {
      this.orderFormCustomer = {
        ...this.orderFormCustomer,
        limitDate: date,
      };
      this.saveAction();
    },
    setWorkStartDate(date) {
      this.orderFormCustomer.orderFormCustomerBtp = {
        ...this.orderFormCustomer.orderFormCustomerBtp,
        workStartDate: date,
      };
      this.saveAction();
    },
    setWorkEndDate(durationNumber, durationType) {
      this.orderFormCustomer.orderFormCustomerBtp = {
        ...this.orderFormCustomer.orderFormCustomerBtp,
        estimatedDurationNumber: durationNumber,
        estimatedDurationType: durationType,
      };
      this.saveAction();
    },
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            let adressReturn = {
              ...adress,
              name: company.name,
              firstName: company.firstName,
              lastName: company.lastName,
            };
            resolve(adressReturn);
          }
        });
      });
    },
    async setCollaboratorInfo() {
      if (this.orderFormCustomer.companyId) {
        await this.getCompanyById({
          companyId: this.orderFormCustomer.companyId,
        }).then(async (company) => {
          this.collaboratorInfo = company.collaboratorId
            ? await this.getCollaboratorById({
                collaboratorId: company.collaboratorId,
              })
            : null;
        });
      } else {
        this.collaboratorInfo = null;
      }
    },
    async setSelectCompany(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.orderFormCustomer = {
          ...this.orderFormCustomer,
          companyId: company.id,
          companyName: company.name,
          companyFirstName: company.firstName,
          companyLastName: company.lastName,
          companyAddress: company.address,
          companyCity: company.city,
          companyZipCode: company.zipCode,
          companyCountry: company.country,
          companyMail: company.email,
          companyPhoneNumber: company.phoneNumber,
          companyTva: company.tvaNumber,
          companySiret: company.siret,
        };
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.orderFormCustomer = {
                  ...this.orderFormCustomer,
                  companyId: company.id,
                  companyName: company.name,
                  companyFirstName: company.firstName,
                  companyLastName: company.lastName,
                  companyAddress: res.address,
                  companyAddressComplement: res.addressComplement,
                  companyCity: res.city,
                  companyZipCode: res.zipCode,
                  companyCountry: res.country,
                  companyMail: company.email,
                  companyPhoneNumber: company.phoneNumber,
                  companyTva: company.tvaNumber,
                  companySiret: company.siret,
                };
              });
            } else {
              this.orderFormCustomer = {
                ...this.orderFormCustomer,
                companyId: company.id,
                companyName: company.name,
                companyFirstName: company.firstName,
                companyLastName: company.lastName,
                companyAddress: null,
                companyAddressComplement: null,
                companyCity: null,
                companyZipCode: null,
                companyCountry: null,
                companyMail: company.email,
                companyPhoneNumber: company.phoneNumber,
                companyTva: company.tvaNumber,
                companySiret: company.siret,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    setSelectAffair(affair) {
      this.orderFormCustomer = {
        ...this.orderFormCustomer,
        affairId: affair.id,
        affairName: affair.name,
        affairAddress: affair.address,
        affairAddressComplement: affair.addressComplement,
        affairZipCode: affair.zipCode,
        affairCity: affair.city,
        affairCountry: affair.country,
      };
      this.saveAction();
    },
    setInvoiceDescription(description) {
      this.orderFormCustomer = {
        ...this.orderFormCustomer,
        description: description,
      };
      this.saveAction();
    },
    saveAction() {
      this.updateOrderFormCustomer({
        orderFormCustomer: this.orderFormCustomer,
        loading: false,
      });
    },
  },
  computed: {
    ...mapGetters([
      "institutionSelected",
      "companiesList",
      "affairsList",
      "workspaceSelected",
      "orderFormCustomerOption",
      "initialDocument",
    ]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    SelectCompany,
    SelectAffair,
    SelectDate,
    SelectPeriod,
    OrderFormCustomerDescription,
    SelectCounterDate,
    // SelectValidityDate,
    SelectDateWork,
    UserInfoOrderFormCustomer,
    SelectDateCEE,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
