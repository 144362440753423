<template lang="pug">
  .quote-card(v-if="!preview")
    b-form-group(v-if="!orderFormCustomer.companyId" label-for='customer')
      validation-provider(#default='{ errors }' name='Client')
        v-select#company(ref="selectCompany" :loading='isLoadingCompany' :state='errors.length > 0 ? false : null' :value='orderFormCustomer.companyName' @input='setValue' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='optionsComputed' :placeholder='placeholder')
          template(v-slot:no-options='')
            template  Aucun r&eacute;sultat trouv&eacute;
          li.border-bottom.p-1.py-50(slot='list-header')
            b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newCompany()' variant='primary' size='sm')
              | + Nouveau client
              span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                | open_in_new
        small.text-danger {{ errors[0] }}
    div(v-if="editMode")
      div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
        span.text-primary.font-weight-bold Client :
        span.cursor-pointer.text-primary(v-if="editMode" @click='validEditMode') Enregistrer
      div.mb-1
        b-form-group(label-for='companyName' style='flex:1')
          validation-provider(#default='{ errors }' name='companyName')
            b-form-input(placeholder="Nom de la société" v-model="documentEdit.companyName")
      div.mb-1(v-if="company && company.length")
        b-form-group(v-if="orderFormCustomer.companyId" label-for='address')
          validation-provider(#default='{ errors }' name='address')
            v-select#addresses(ref="selectAddress" item-text="label" :loading='isLoadingCompany' :state='errors.length > 0 ? false : null' :value='documentEdit.companyAddressLabel' @input='setAddress' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='company' placeholder='Sélectionner une adresse')
              template(v-slot:no-options='')
                template  Aucun r&eacute;sultat trouv&eacute;
      div.mb-1
        b-form-group(label-for='companyAddress' style='flex:1')
          validation-provider(#default='{ errors }' name='companyAddress')
            b-form-input(placeholder="Adresse" v-model="documentEdit.companyAddress") 
      div.mb-1
        b-form-group(label-for='companyAddressComplement' style='flex:1')
          validation-provider(#default='{ errors }' name='companyAddressComplement')
            b-form-input(placeholder="Adresse" v-model="documentEdit.companyAddressComplement") 
      div.mb-1.d-flex
        div.w-25
          b-form-group.pr-1(label-for='companyZipCode' style='flex:1')
            validation-provider(#default='{ errors }' name='companyZipCode')
              b-form-input(placeholder="CP"  v-model="documentEdit.companyZipCode")
        div.w-75
          b-form-group(label-for='companyCity' style='flex:1')
            validation-provider(#default='{ errors }' name='companyCity')
              b-form-input(placeholder="Ville" v-model="documentEdit.companyCity")
      div.mb-1
        b-form-group(label-for='companyCountry' style='flex:1')
          validation-provider(#default='{ errors }' name='companyCountry')
            b-form-input(placeholder="Pays" v-model="documentEdit.companyCountry") 
      div.mb-1
        b-form-group(label-for='companySiret' style='flex:1')
          validation-provider(#default='{ errors }' name='companySiret')
            b-form-input(placeholder="Siret" v-model="documentEdit.companySiret") 
      div.mb-1
        b-form-group(label-for='companyTva' style='flex:1')
          validation-provider(#default='{ errors }' name='companyTva')
            b-form-input(placeholder="N° TVA intra" v-model="documentEdit.companyTva") 
    .card-info(v-else-if="orderFormCustomer.companyId" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      div(v-if="!editMode")
        .card-tools.pr-0(v-if="showCardTool && !disabled")
          feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier la fiche client'" @click="setEditMode()")
          feather-icon(icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="'Changer de client'")
        span.bold {{ orderFormCustomer.companyCode && orderFormCustomerOption.showCompanyCode ? orderFormCustomer.companyCode+ " - " : ""  }}{{ orderFormCustomer.companyName  }}
        div(v-if="orderFormCustomer.companyAddressLabel != null && orderFormCustomerOption.showCompanyAddressLabel")
          span {{ orderFormCustomer.companyAddressLabel }}
        div(v-if="orderFormCustomer.companyAddress != null")
          span {{ orderFormCustomer.companyAddress }}
        div(v-if="orderFormCustomer.companyAddressComplement != null")
          span {{ orderFormCustomer.companyAddressComplement }}
        div(v-if="orderFormCustomer.companyZipCode != null || orderFormCustomer.companyCity != null")
          span {{ orderFormCustomer.companyZipCode }} {{ orderFormCustomer.companyCity }}
        div(v-if="orderFormCustomer.companyCountry != null")
          span {{ orderFormCustomer.companyCountry }}
        div(v-if="orderFormCustomerOption.showCompanySiret && orderFormCustomer.companySiret != null")
          span Siret : {{ orderFormCustomer.companySiret ? orderFormCustomer.companySiret : 'Non renseigné'}}
        div(v-if="orderFormCustomerOption.showCompanyTva && orderFormCustomer.companyTva != null")
          span N° TVA intra : {{ orderFormCustomer.companyTva ? orderFormCustomer.companyTva : 'Non renseigné'}}
  .quote-card(v-else)
    .card-info
      span.bold {{ orderFormCustomer.companyCode && orderFormCustomerOption.showCompanyCode ? orderFormCustomer.companyCode+ " - " : ""  }}{{ orderFormCustomer.companyName  }}
      div(v-if="orderFormCustomer.companyAddressLabel != null && orderFormCustomer.showCompanyAddressLabel")
        span {{ orderFormCustomer.companyAddressLabel }}
      div(v-if="orderFormCustomer.companyAddress != null")
        span {{ orderFormCustomer.companyAddress }}
      div(v-if="orderFormCustomer.companyAddressComplement != null")
        span {{ orderFormCustomer.companyAddressComplement }}
      div(v-if="orderFormCustomer.companyZipCode != null || orderFormCustomer.companyCity != null")
        span {{ orderFormCustomer.companyZipCode }} {{ orderFormCustomer.companyCity }}
      div(v-if="orderFormCustomer.companyCountry != null")
        span {{ orderFormCustomer.companyCountry }}
      div(v-if="orderFormCustomer.showCompanySiret && orderFormCustomer.companySiret != null")
        span Siret : {{ orderFormCustomer.companySiret ? orderFormCustomer.companySiret : 'Non renseigné' }}
      div(v-if="orderFormCustomer.showCompanyTva && orderFormCustomer.companyTva != null")
        span N° TVA intra : {{ orderFormCustomer.companyTva ? orderFormCustomer.companyTva : 'Non renseigné' }}
</template>

<script>
import vSelect from "vue-select";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      value: null,
      showCardTool: false,
      required,
      editMode: false,
      documentEdit: {},
      company: null,
    };
  },
  created() {
    if (this.orderFormCustomer.companyId) {
      this.$store
        .dispatch("getCompanyById", {
          companyId: this.orderFormCustomer.companyId,
        })
        .then((res) => {
          this.company = this.affectLabelAdress(res);
        });
    }
    if (!this.companiesTypesList || this.companiesTypesList.length == 0) {
      this.getCompanyTypes({});
    }
    if (!this.companiesList || this.companiesList.length == 0) {
      this.getCompanies({});
    }
  },
  computed: {
    ...mapGetters([
      "isLoadingCompany",
      "companiesList",
      "companiesTypesList",
      "orderFormCustomerOption",
    ]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    optionsComputed() {
      return this.options.map((elem) => {
        return { label: elem.name, value: elem.id };
      });
    },
  },
  methods: {
    ...mapActions(["getCompanyTypes", "getCompanies"]),
    validModal() {
      let company = {
        id: this.documentEdit.companyId,
        name: this.documentEdit.companyName,
        label: this.documentEdit.companyAddressLabel,
        addressId: this.documentEdit.companyAddressId,
        address: this.documentEdit.companyAddress,
        addressComplement: this.documentEdit.companyAddressComplement,
        zipCode: this.documentEdit.companyZipCode,
        city: this.documentEdit.companyCity,
        country: this.documentEdit.companyCountry,
        siret: this.documentEdit.companySiret,
        tvaNumber: this.documentEdit.companyTva,
      };
      this.$emit("setSelectCompanyAddress", company);
      this.editMode = false;
    },
    validEditMode() {
      this.validModal();
    },
    setEditMode() {
      this.editMode = true;
      this.documentEdit = JSON.parse(JSON.stringify(this.orderFormCustomer));
    },
    setValue(value) {
      if (value.id !== 0 && value.id !== null) {
        let company = this.options.find((elem) => {
          return elem.id == value.value;
        });
        this.$store
          .dispatch("getCompanyById", { companyId: company.id })
          .then((res) => {
            this.company = this.affectLabelAdress(res);
          });
        this.$emit("setValue", company);
      } else {
        this.$emit("setValue", value);
      }
    },
    setAddress(value) {
      this.documentEdit.companyAddressId = value.id;
      this.documentEdit.companyAddressLabel = value.label;
      this.documentEdit.companyAddress = value.address;
      this.documentEdit.companyAddressComplement = value.addressComplement;
      this.documentEdit.companyZipCode = value.zipCode;
      this.documentEdit.companyCity = value.city;
      this.documentEdit.companyCountry = value.country;
    },
    resetValue() {
      // this.$refs['selectCompany'].reset();
      let value = {
        id: null,
        name: null,
        firstName: null,
        lastName: null,
        address: null,
        addressComplement: null,
        city: null,
        zipCode: null,
        country: null,
        email: null,
        phoneNumber: null,
        tva: null,
        siret: null,
      };
      this.value = value;
      this.setValue(value);
      this.orderFormCustomer.companyId = null;
      this.orderFormCustomer.companyName = null;
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
    affectLabelAdress(company) {
      return company?.addresses.map((address, index) => {
        if (!address.label) {
          return { ...address, label: `Adresse ${index + 1}` };
        } else {
          return address;
        }
      });
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>
