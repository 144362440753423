<template lang="pug">
  #invoiceContainer(v-if="this.$route.fullPath.includes(orderFormCustomer.id)")
    .content-loader-center.m-0.h-100(v-if='isLoadingOrderFormCustomer')
      .text-center.flex-center.h-100
        .loading-bg-inner(role='status')
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la commande client...
    div(v-else style="height: calc(100% - 50px) !important")
      OrderFormCustomerBuilderHeader(@changePreviewKey="changePreviewKey" @changePreview="changePreview")
      OrderFormCustomerBuilder(v-if="orderFormCustomerTabActive == 'order-form-customer-edit'")
      .content-tab-fullpage(v-else-if="orderFormCustomerTabActive == 'order-form-customer-pdf'" :key="previewKey")
        OrderTrackingCustomerPdf(v-if="preview=='order-tracking-customer-pdf'" :key="previewKey" :preview='preview')
        OrderFormCustomerPdf(v-else)
      .content-tab-fullpage(v-else-if="orderFormCustomerTabActive == 'order-tracking-customer'" :key="previewKey")
        OrderTrackingCustomer
      .content-tab-fullpage(v-else-if="orderFormCustomerTabActive == 'internal-note'")
        InternalNote
      .content-tab-fullpage(v-else-if="orderFormCustomerTabActive == 'attachments'")
        ged-viewer-fullpage(:parentFolder="orderFormCustomer.folderId" fromModule="orderFormCustomer")
  </template>

<script>
import OrderFormCustomerBuilderHeader from "@/components/invoice/order-form-customer/OrderFormCustomerBuilderHeader.vue";
import OrderFormCustomerBuilder from "@/components/invoice/order-form-customer/OrderFormCustomerBuilder.vue";
import OrderTrackingCustomer from "@/components/invoice/order-form-customer/OrderTrackingCustomer.vue";
import OrderFormCustomerPdf from "@/components/invoice/order-form-customer/OrderFormCustomerPdf.vue";
import OrderTrackingCustomerPdf from "@/components/invoice/order-form-customer/OrderTrackingCustomerPdf.vue";
import InternalNote from "@/components/invoice/order-form-customer/internal-note/InternalNote.vue";
import { mapGetters, mapActions } from "vuex";
import GedViewerFullpage from "@/components/ged/GedViewerFullpage";

export default {
  name: "edit-order-form-customer",
  ref: "edit-order-form-customer",

  data() {
    return {
      editOrderFormCustomerLoaded: false,
      preview: "",
      previewKey: 0,
    };
  },
  props: {
    id: {
      default: null,
    },
    mode: {
      default: "order-form-customer-edit",
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async created() {
    if (
      this.orderFormCustomer.status >= 3 ||
      this.orderFormCustomer.usedUrlGed
    ) {
      await this.changeOrderFormCustomerTabActive("order-tracking-customer");
    } else {
      await this.changeOrderFormCustomerTabActive(this.mode);
    }

    // TODO : Mettre le document sur edit à chaque ouverture sauf exeptions
    // TODO : Que se passe t il si je relance l'application sur le devis ?
    // TODO : si je fais cette fonction, elle se déclanche aussi lorsque que je créer un document donc 2 appels
    // il faut qu'elle se déclanche uniquement en cas de reaload
    this.orderFormCustomerHeader.referencielTvaId.choice =
      this.institutionSettingsActive.referencielTvas.map((elem) => {
        return {
          id: elem.referencielTva.id,
          label: elem.referencielTva.label,
          value: elem.referencielTva.value,
        };
      });
    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({}).then((res) => {
        this.orderFormCustomerHeader.unitId.choice = res;
      });
    } else {
      this.orderFormCustomerHeader.unitId.choice = this.unitsList;
    }

    this.getProducts({});

    this.editOrderFormCustomerLoaded = true;
  },
  computed: {
    ...mapGetters([
      "institutionSettingsActive",
      "isLoadingOrderFormCustomer",
      "orderFormCustomerTabActive",
      "unitsList",
    ]),
    orderFormCustomerHeader: {
      get() {
        return this.$store.getters.getOrderFormCustomerHeader;
      },
      set(val) {
        this.$store.commit("SET_INVOICE_HEADER", {
          ...orderFormCustomerHeader,
          val,
        });
      },
    },
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getProducts",
      "getUnits",
      "changeOrderFormCustomerTabActive",
    ]),
    changePreview(preview) {
      this.preview = preview;
      this.previewKey++;
    },
    changePreviewKey() {
      this.previewKey++;
    },
  },
  components: {
    OrderFormCustomerBuilder,
    OrderTrackingCustomer,
    OrderFormCustomerBuilderHeader,
    OrderFormCustomerPdf,
    OrderTrackingCustomerPdf,
    InternalNote,
    GedViewerFullpage,
  },
};
</script>

<style scoped>
#invoiceContainer {
  width: 98%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.content-tab-fullpage {
  padding-top: 20px !important;
  background-color: white !important;
  height: 100%;
}
</style>
