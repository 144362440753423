<template lang="pug">
div.builder-header.px-2(v-show="!isLoadingOrderFormCustomer")
  div
    ul#pills-tab.nav.nav-pill(role='tablist')
      li.nav-item(v-if="this.orderFormCustomer.status<=2" @click="changeOrderFormCustomerTabActive('order-form-customer-edit')")
        div.nav-link.cursor-pointer.text-center(:class="orderFormCustomerTabActive == 'order-form-customer-edit' ? 'active' : ''")
          feather-icon(icon="Edit2Icon" size="15")
          |  Edition
      li.nav-item(v-if="checkIfPreviewIsPossible() && this.orderFormCustomer.status>2" @click="showTrack")
        div.nav-link.cursor-pointer.text-center.d-flex.align-items-center(:class="orderFormCustomerTabActive == 'order-tracking-customer' ? 'active' : ''")
          span(class="material-icons-outlined mr-50") checklist_rtl
          |  Suivi
      li.nav-item(v-else-if="checkIfPreviewIsPossible()" @click="showPreviewPdf")
        div.nav-link.cursor-pointer.text-center(:class="orderFormCustomerTabActive == 'order-form-customer-pdf' ? 'active' : ''")
          feather-icon(icon="EyeIcon" size="15")
          |  Prévisualisation
      li.nav-item(v-if="haveGedModule" @click="changeOrderFormCustomerTabActive('attachments')")
        div.nav-link.cursor-pointer.text-center(:class="orderFormCustomerTabActive == 'attachments' ? 'active' : ''")
          feather-icon(icon="FolderIcon" size="15")
          |  Documents
      li.nav-item(@click="changeOrderFormCustomerTabActive('internal-note')")
        div.nav-link.cursor-pointer.text-center(:class="orderFormCustomerTabActive == 'internal-note' ? 'active' : ''")
          feather-icon(icon="LockIcon" size="15")
          |  Notes

  .d-flex.align-items-center.flex-column.etat-document(v-if="orderFormCustomerTabActive == 'order-tracking-customer'")
    progress.mb-50(:value="percentAvancement" max="100" :style="'--value: '+percentAvancement+'; --max: 100;'")
    div.d-flex.align-items-center(v-if="percentAvancement==0")
      //- span.material-icons-outlined.text-warning.mr-50(style="font-size: 20px;") hourglass_top
      | Non facturé (0%)
    div.d-flex.align-items-center(v-else-if="percentAvancement<100")
      span.material-icons-outlined.mr-50(style="font-size: 20px;") hourglass_top
      | Avancement ({{percentAvancement}}%)
    div.d-flex.align-items-center(v-else)
      span.material-icons-outlined.text-success.mr-50(style="font-size: 20px;") done
      | Terminé

  div.d-flex.align-items-center.ml-auto
    .form-group.form-check.mb-0.mr-1.tab-control.builderControls(v-if="orderFormCustomerTabActive=='order-form-customer-edit'" data-tab='order-form-customer-edit')
      .d-flex.align-items-center
        b-dropdown.mr-1#dropdown-form(v-if="orderFormCustomer.status <= 3" ref='dropdown' right='' variant='outline-secondary' text='Options')
          b-dropdown-form.dropdown-options
            h6(v-if="statusCheck.documentEditable.includes(orderFormCustomer.status) || orderFormCustomer.isTemplate") Modèle
              b-form-checkbox.checkbox-options(v-if="statusCheck.documentEditable.includes(orderFormCustomer.status) || orderFormCustomer.isTemplate" @change="setTemplate" v-model="orderFormCustomer.isTemplate")
                span.text-nowrap Définir comme modèle
              .d-flex.align-items-center.mb-1.cursor-pointer(v-if="orderFormCustomer.isTemplate" style="font-size: 13px; font-weight: 500;" @click="toggleTemplateModal()")
                span.material-icons-outlined.mr-50(style="font-size: 19px;") edit
                span(style='-moz-text-decoration-line: underline; -moz-text-decoration-style: dotted; -moz-text-decoration-color: #666; -webkit-text-decoration-line: underline; -webkit-text-decoration-style: wavy; -webkit-text-decoration-color: red; text-decoration-line: underline; text-decoration-style: dotted; text-decoration-color: #666;') {{ orderFormCustomer.label && orderFormCustomer.label!=="" ? orderFormCustomer.label : "Nom du modèle" }}
            h6 Edition
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.calculateMargin")
              | Afficher le calcul des marges
            //- b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.calculateUnitPriceHtByUnitPurchasePriceHt")
            //-     | Calculer le prix de vente par rapport au prix d'achat
            b-form-checkbox.checkbox-options(@change="discountLineAction" v-model="orderFormCustomerOption.showDiscountColumn")
              | Afficher la colonne des remises par ligne
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.showReferenceColumn")
              | Afficher la colonne des références
            b-form-checkbox.checkbox-options(v-model="orderFormCustomerOption.isReverseCharge" @change="isReverseChargeRequest")
              | Autoliquidation
            h6.mt-1 Prévisualisation
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.hideInstitutionLogo")
              | Masquer mon logo
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.hideInstitutionName")
              | Masquer ma raison sociale
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.hideInstitutionAddress")
              | Masquer mon adresse
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.hideCollaboratorInCharge")
              | Masquer le collaborateur en charge
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.hideAffair")
              | Masquer l'affaire
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.showAffairCode")
              | Masquer le code de l'affaire
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.hideLinesQuantityEmpty")
              | Masquer les lignes sans quantité
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.hideTotal")
              span.text-nowrap Masquer le total
            b-form-checkbox.checkbox-options(v-if="orderFormCustomer.status != 0 && orderFormCustomer.status != 1" @change="saveActionOptions()" v-model="orderFormCustomerOption.showWaterMark")
              span.text-nowrap Afficher le filigrane
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomer.showTermsOfSales")
              span.text-nowrap Afficher les conditions de vente
            b-form-checkbox.checkbox-options(v-if="orderFormCustomer.quoteId" @change="saveActionOptions()" v-model="orderFormCustomerOption.hideInitialQuote")
              | Masquer le devis initial
            h6.mt-1 Document
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.hideUser")
              | Masquer le contact
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.hideIndexColumn")
              span.text-nowrap Masquer la colonne des index
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.hideQuantityColumn")
              | Masquer la colonne des quantités
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.hideUnitColumn")
              | Masquer la colonne des unités
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.hideUnitPriceHtColumn")
              | Masquer la colonne des prix unitaire HT
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.hideReferencielTvaColumn")
              | Masquer la colonne des TVA
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.hidePriceHtColumn")
              | Masquer la colonne des totaux HT
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormCustomerOption.hideBankAccount")
              | Masquer le compte bancaire associé
            h6.mt-1 Client
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.hideCompany")
                span.text-nowrap Masquer le client
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.showCompanyCode")
                span.text-nowrap Afficher le code client
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.showCompanyAddressLabel")
              | Afficher le libellé de l'adresse
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.showCompanyCode")
              span.text-nowrap Masquer le code client
              b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.showCompanyMail")
                span.text-nowrap Afficher le mail
              b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.showCompanyPhoneNumber")
                span.text-nowrap Afficher le téléphone
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="orderFormCustomerOption.showCompanySiret")
              | Afficher le SIRET
            b-form-checkbox.checkbox-options(@input="saveActionOptions()" v-model="orderFormCustomerOption.showCompanyTva")
              span.text-nowrap Afficher le numéro de TVA intracommunautaire
    div(v-if="orderFormCustomer.status < 2")
      b-button.mr-1.btn-invoice-builder-header(@click="cancel" :variant="'outline-danger'")
        | Supprimer
    div(v-if="percentAvancement<100")
      b-dropdown.dropdown-icon-wrapper.mr-1(style='height:38px' variant='success' left='' v-if='([3, 4, 7]).includes(orderFormCustomer.status) && finaleInvoiceExist == 0')
        template(#button-content)
          span.mr-50 Facturer
          feather-icon.align-middle(icon='FileIcon')
        div(v-if='initialDocument && initialDocument.orderFormCustomerDownPaymentsRequests && situationInvoiceExist <= 1')
          b-dropdown-item(@click='generateDownPaymentInvoice(item)' v-for='(item, index) in initialDocument.orderFormCustomerDownPaymentsRequests' :key='index')
            | Acompte de {{ formatNumber(item.number) }} {{ downPaymentRequestTypeTranslate(item.downPaymentRequestType) }} {{ item.downPaymentRequestDue == 3 ? item.label : downPaymentRequestDueTranslate(item.downPaymentRequestDue) }}
        b-dropdown-item(:disabled="situationInvoiceExist > 1" @click="generateDownPaymentInvoice()") Facture d'acompte {{ downPaymentInvoiceExist <= 1 ? '' : '#' + downPaymentInvoiceExist }}
        b-dropdown-item(:disabled="lastInvoice ? lastInvoice.status == 0 || lastInvoice.allWorkBilled : false" @click="generateSituationInvoice") Facture de situation {{ situationInvoiceExist <= 1 ? '' : '#' + situationInvoiceExist }}
        b-dropdown-item(:disabled="lastInvoice ? lastInvoice.nature == 3 || lastInvoice.nature == 4 : false" @click="generateFinaleInvoice") Facture finale
    div.d-flex.align-items-center.ml-auto(v-if="orderFormCustomer.status < 3 && orderFormCustomerDetails.length > 0 && !orderFormCustomer.isTemplate")
      b-button-group
        b-dropdown.dropdown-options.pr-1(text='Finaliser le document' variant='success' dropup)
          b-dropdown-item.cursor-pointer(@click="finalizedOrderFormCustomer(2, false)" :disabled="orderFormCustomer.status >= 2" :style="orderFormCustomer.status >= 2 ? 'opacity:0.5' : ''")
            span.chip.justify-content-start
              span.point.bg-warning
              span.label.text-warning Passer en validation
          b-dropdown-item.cursor-pointer(@click="finalizedOrderFormCustomer(3, false)" :disabled="orderFormCustomer.status >= 3" :style="orderFormCustomer.status >= 3 ? 'opacity:0.5' : ''")
            span.chip.justify-content-start
              span.point.bg-primary
              span.label.text-primary Valider le document
    div
      b-button-group
        b-button.btn-invoice-builder-header.m-0(v-if="orderFormCustomer.status < 3" @click="checkStatusBeforeSave(false)" variant='primary')
          | Enregistrer
        b-dropdown.dropdown-options(style='height:38px' v-else  variant='primary' dropup)
          template.p-0.m-0(#button-content)
            span(class="material-icons-outlined mr-50" style="font-size:16px") upload_file
            | Aperçu
          b-dropdown-item.cursor-pointer(v-if="orderFormCustomer.path" @click="forceFileDownload(orderFormCustomer.path)")
            span Bon de commande original
          b-dropdown-item.cursor-pointer(v-else-if="checkIfPreviewIsPossible()" @click="showPreviewPdf")
            span Bon de commande original
          b-dropdown-item.cursor-pointer(:disabled="true" @click="showPreview('order-tracking-customer-pdf')")
            span Avancement
          b-dropdown-item.cursor-pointer(:disabled="true" @click="showPreview('order-tracking-customer-pdf')")
            span Restant à facturer
          b-dropdown-item.cursor-pointer(:disabled="true" @click="showPreview('order-tracking-customer-pdf')")
            span Quantités Avancement/Restant
          b-dropdown-item.cursor-pointer(:disabled="true" @click="showPreview('order-tracking-customer-pdf')")
            span Montants Avancement/Restant
        b-button.btn-invoice-builder-header(v-if="orderFormCustomer.status < 3" variant='primary' @click="checkStatusBeforeSave(true)" style="border-left:1px solid #ccc !important")
          feather-icon.ml-50(icon='LogOutIcon')
        b-button.btn-invoice-builder-header(v-else variant='primary' @click="cancel" style="border-left:1px solid #ccc !important")
          | Fermer
          feather-icon.ml-50(icon='LogOutIcon')
    b-modal(ref='template-modal' :title="'Enregistrer en tant que modèle'")
      div
        b-row.mt-50.mb-50
          b-col.py-0(cols='12')
            b-form-group(label="Nom du modèle")
              ejs-textbox(id="documentLabel" placeholder="Nom du modèle" v-model="orderFormCustomer.label" name="Label")
      //.mt-1.cancel-modal-buttons
      template(#modal-footer="{ ok, cancel }")
        .w-100.m-0.py-50
          b-button(variant="primary" class="float-right" @click="toggleTemplateModal()") Enregistrer
    popup-new-down-payment-invoice(:activePopupDownPaymentInvoice='activePopupDownPaymentInvoice' @toggleModalNewDownPaymentInvoice='toggleModalNewDownPaymentInvoice' :preRenderingData='preRenderingData' type="ordeFormCustomer")
    popup-new-situation-invoice(ref='popup-new-situation-invoice' :activePopupSituationInvoice='activePopupSituationInvoice' @toggleModalNewSituationInvoice='toggleModalNewSituationInvoice' type="ordeFormCustomer")
</template>

<script>
var dayjs = require("dayjs");

import {
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { calculateTotalOrderFormCustomerLine } from "@/types/api-orisis/library/DetailDocumentOperations";
import { getTotalDeduction } from "@/types/api-orisis/library/DocumentOperations";

import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import {
  downPaymentRequestTypeTranslate,
  downPaymentRequestDueTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

import Status from "@/components/global/status/Status";
import SelectDate from "@/components/invoice/order-form-customer/builder/SelectDate";

import PopupNewDownPaymentInvoice from "@/components/invoice/PopupNewDownPaymentInvoice";
import PopupNewSituationInvoice from "@/components/invoice/PopupNewSituationInvoice";

import { statusCheck } from "@/types/api-orisis/enums/enums";
import { InvoiceOptionModel } from "@/types/api-orisis/models/InvoiceOptionModel";

import { checkFunctionUser } from "@/auth/utils.ts";

import { applyOrderFormCustomerDeduction } from "@/types/api-orisis/library/DeductionOperations";

export default {
  data() {
    return {
      InvoiceOptionModel,
      activePopupDownPaymentInvoice: false,
      activePopupSituationInvoice: false,
      mandatoryInformationAlertArray: [],
      preRenderingData: null,
      preview: "",
      calculateTotalOrderFormCustomerLine,
      getTotalDeduction,
      statusCheck,
    };
  },
  created() {
    this.setupHeaderOrderFormCustomer();
  },
  methods: {
    ...mapActions([
      "updateOrderFormCustomer",
      "deleteOrderFormCustomer",
      "setupHeaderOrderFormCustomer",
      "loopOrderFormCustomerDetails",
      "changeOrderFormCustomerTabActive",
      "updateOrderFormCustomerDetail",
      "createInvoice",
      "getLastOrderFormCustomerReference",
      "updateOrderFormCustomerOption",
      "createInvoiceOption",
      "getInvoiceOptionDefaultByInvoiceId",
      "createDocumentDetailsToInvoiceIdFromOrderFormCustomerId",
    ]),
    applyOrderFormCustomerDeduction,
    checkFunctionUser,
    formatNumber,
    downPaymentRequestTypeTranslate,
    downPaymentRequestDueTranslate,
    setTemplate(value) {
      if (value) {
        this.toggleTemplateModal();
      } else {
        this.saveAction(false);
      }
    },
    toggleTemplateModal() {
      this.$refs["template-modal"].toggle("#toggle-btn");
      this.saveAction(false);
    },
    async validOrderFormCustomer(action, exit) {
      if (action == 2) {
        await this.updateOrderFormCustomer({
          orderFormCustomer: { ...this.orderFormCustomer, status: 2 },
          loading: true,
        });

        if (this.orderFormCustomerTabActive == "order-form-customer-pdf") {
          this.$emit("changePreviewKey");
        } else {
          await this.changeOrderFormCustomerTabActive(
            "order-form-customer-pdf"
          );
        }
      } else if (action == 3) {
        this.$bvModal
          .msgBoxConfirm(
            "Attention, en passant votre document en statut 'validé' celui-ci sera regénéré sans filigrane et ne sera plus modifiable.",
            {
              title: "Valider le bon de commande",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui, confirmer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              this.$store.commit("SET_IS_VALIDATING_ORDER_FORM_CUSTOMER", true);
              await this.updateOrderFormCustomer({
                orderFormCustomer: {
                  ...this.orderFormCustomer,
                  status: 3,
                  remainingToBillHt: this.orderFormCustomer.totalHt,
                },
                loading: true,
              })
                .then(async () => {
                  // Si la tab affiché correspond déjà à l'aperçu alor son rafrachit le composant pour que l'aperçu se regénère
                  if (
                    this.orderFormCustomerTabActive == "order-form-customer-pdf"
                  ) {
                    this.$emit("changePreviewKey");
                  } else {
                    await this.changeOrderFormCustomerTabActive(
                      "order-form-customer-pdf"
                    );
                  }
                  this.$store.commit(
                    "SET_IS_VALIDATING_ORDER_FORM_CUSTOMER",
                    false
                  );
                })
                .catch((err) => {
                  console.error(err);
                  this.$store.commit(
                    "SET_IS_VALIDATING_ORDER_FORM_CUSTOMER",
                    false
                  );
                });
            }
          });
      }
    },
    async finalizedOrderFormCustomer(action, exit) {
      if (!this.orderFormCustomer.companyId) {
        this.$bvToast.toast(
          "Veuillez sélectionner un client afin de finaliser votre document.",
          {
            title: `Erreur`,
            variant: "danger",
            solid: true,
          }
        );
      } else {
        this.checkMandatoryInformation().then(async (res) => {
          let next = true;
          for (let i = 0; i < res.length; i++) {
            const element = res[i];
            if (next == true) {
              await this.$swal({
                title: element.message,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: element.button,
                cancelButtonText: "Corriger",
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  next = true;
                } else {
                  next = false;
                }
              });
            }
          }
          if (next == true) {
            await this.validOrderFormCustomer(action, exit);
          }
        });
      }
    },
    showTrack() {
      this.$emit("changePreviewKey");
      this.$nextTick(() => {
        this.changeOrderFormCustomerTabActive("order-tracking-customer");
      });
    },
    showPreview(preview) {
      this.preview = preview;
      this.$emit("changePreview", preview);
      this.$emit("changePreviewKey");
      this.$nextTick(() => {
        this.changeOrderFormCustomerTabActive("order-form-customer-pdf");
      });
    },
    showPreviewPdf() {
      this.$emit("changePreviewKey");
      this.$nextTick(() => {
        this.changeOrderFormCustomerTabActive("order-form-customer-pdf");
      });
    },
    saveAction(bool) {
      this.updateOrderFormCustomer({
        orderFormCustomer: this.orderFormCustomer,
        loading: bool,
      });
    },
    saveActionOptions() {
      this.updateOrderFormCustomerOption({
        orderFormCustomerOption: this.orderFormCustomerOption,
      });
    },
    async checkStatusBeforeSave(exit) {
      if (this.orderFormCustomer.status == 0) {
        this.orderFormCustomer.status = 1;
        this.$store.getters.orderFormCustomersList.unshift(
          this.orderFormCustomer
        );
      }
      await this.saveOrderFormCustomer(exit);
    },
    checkIfPreviewIsPossible() {
      if (this.orderFormCustomer.companyId) {
        return true;
      } else {
        return false;
      }
    },
    async saveOrderFormCustomer(exit) {
      return await new Promise(async (resolve, reject) => {
        await this.updateOrderFormCustomer({
          orderFormCustomer: this.orderFormCustomer,
          loading: true,
        }).then(async (res) => {
          if (exit) {
            this.$tabs.close({ to: "/order-form-customers" });
          }
        });
      });
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute(
          "download",
          this.orderFormCustomer.documentReference
        );
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    setHeader() {
      this.setupHeaderOrderFormCustomer();
      this.saveActionOptions();
    },
    async discountLineAction() {
      await this.setHeader();
      if (!this.orderFormCustomerOption.showDiscountColumn) {
        for (let i = 0; i < this.orderFormCustomerDetails.length; i++) {
          const orderFormCustomerDetail = this.orderFormCustomerDetails[i];
          if (orderFormCustomerDetail.discount > 0) {
            orderFormCustomerDetail.discount = 0;
            let total = this.calculateTotalOrderFormCustomerLine(
              orderFormCustomerDetail
            );
            this.updateOrderFormCustomerDetail({
              orderFormCustomerDetail: {
                ...orderFormCustomerDetail,
                total: total,
              },
            });
          }
        }
      }
    },
    isReverseChargeRequest(value) {
      if (value) {
        this.$bvModal
          .msgBoxConfirm(
            "Attention, toutes les TVA du document seront passées à 0 %.",
            {
              title: "Activer l'autoliquidation",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.saveAction(false);
              this.loopOrderFormCustomerDetails({ referencielTvaId: 5 });
            } else {
              this.orderFormCustomerOption.isReverseCharge = false;
            }
          });
      } else {
        this.orderFormCustomerOption.isReverseCharge = false;
      }
    },
    checkMandatoryInformation() {
      return new Promise(async (resolve, reject) => {
        let mandatoryInformationAlertArray = [];
        if (this.orderFormCustomer.type > 1) {
          mandatoryInformationAlertArray =
            await this.checkMandatoryInformationWorkStartDate(
              mandatoryInformationAlertArray
            );
          mandatoryInformationAlertArray =
            await this.checkMandatoryInformationEstimatedDuration(
              mandatoryInformationAlertArray
            );
          mandatoryInformationAlertArray =
            await this.checkMandatoryInformationWasteManagement(
              mandatoryInformationAlertArray
            );
        }
        resolve(mandatoryInformationAlertArray);
      });
    },
    checkMandatoryInformationWorkStartDate(mandatoryInformationAlertArray) {
      if (
        this.orderFormCustomer.orderFormCustomerBtp.workStartDate == null &&
        (this.orderFormCustomer.type == 3 || this.orderFormCustomer.type == 2)
      ) {
        mandatoryInformationAlertArray.push({
          message:
            "La date de début des travaux est une mention obligatoire vis à vis de la loi",
          button: "Continuer sans la date",
        });
        return mandatoryInformationAlertArray;
      }
      return mandatoryInformationAlertArray;
    },
    checkMandatoryInformationEstimatedDuration(mandatoryInformationAlertArray) {
      if (
        this.orderFormCustomer.orderFormCustomerBtp.estimatedDurationNumber ==
          null &&
        (this.orderFormCustomer.type == 3 || this.orderFormCustomer.type == 2)
      ) {
        mandatoryInformationAlertArray.push({
          message:
            "La durée approximative des travaux est une mention obligatoire vis à vis de la loi",
          button: "Continuer sans la durée",
        });
        return mandatoryInformationAlertArray;
      }
      return mandatoryInformationAlertArray;
    },
    checkMandatoryInformationWasteManagement(mandatoryInformationAlertArray) {
      if (
        !(
          this.orderFormCustomer.orderFormCustomerBtp.wasteVolumeFrom >= 0 &&
          this.orderFormCustomer.orderFormCustomerBtp.wasteVolumeTo >= 0 &&
          this.orderFormCustomer.orderFormCustomerBtp.wasteCollectionPointIds &&
          this.orderFormCustomer.orderFormCustomerBtp.wasteCollectionPointIds
            .length > 0 &&
          this.orderFormCustomer.orderFormCustomerBtp.wasteTypeIds &&
          this.orderFormCustomer.orderFormCustomerBtp.wasteTypeIds.length > 0 &&
          (this.orderFormCustomer.type == 3 || this.orderFormCustomer.type == 2)
        )
      ) {
        mandatoryInformationAlertArray.push({
          message:
            "Les informations concernant l'enlèvement des déchets sont des mentions obligatoires vis à vis de la loi",
          button: "Continuer sans ces informations",
        });
        return mandatoryInformationAlertArray;
      }
      return mandatoryInformationAlertArray;
    },
    cancel() {
      if (this.orderFormCustomer.status > 1) {
        this.closeTab();
      } else {
        this.$bvModal
          .msgBoxConfirm(
            `Votre bon de commande n'ayant aucun statut, vous êtes sur le point de le supprimer. Attention, cette action est irreversible.`,
            {
              title:
                "Êtes-vous sûr de vouloir supprimer votre bon de commande ?",
              size: "sm",
              okVariant: "danger",
              okTitle: "Supprimer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              if (this.orderFormCustomer.status < 2) {
                this.deleteOrderFormCustomer({
                  orderFormCustomerIds: [this.orderFormCustomer.id],
                  route: this.$route.name,
                }).then(() => {
                  this.closeTab();
                });
              }
            }
          });
      }
    },
    closeTab() {
      if (
        this.$route.params.routeOrigine &&
        this.$route.params.routeOrigine !== ""
      ) {
        this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
      } else {
        this.$tabs.close();
      }
    },

    generateSituationInvoice() {
      if (this.orderFormCustomer.status < 4) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour facturer ce devis, vous devez le passer en statut "Facturé"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Facturer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              await this.updateOrderFormCustomer({
                orderFormCustomer: { ...this.orderFormCustomer, status: 4 },
                loading: false,
              }).then((res) => {
                this.$emit("refreshRow", res);
                this.toggleModalNewSituationInvoice(true);
              });
            }
          });
      } else {
        if (this.lastInvoice && this.lastInvoice.nature == 4) {
          this.$refs["popup-new-situation-invoice"].createSituationInvoice(4);
        } else {
          this.toggleModalNewSituationInvoice(true);
        }
      }
    },
    toggleModalNewSituationInvoice(bool = false) {
      this.activePopupSituationInvoice = bool;
    },

    async generateFinaleInvoice() {
      if (this.orderFormCustomer.status < 4) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour facturer ce bon de commande, vous devez le passer en statut "Facturé"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Facturer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              await this.updateOrderFormCustomer({
                orderFormCustomer: { ...this.orderFormCustomer, status: 4 },
                loading: false,
              }).then(async (res) => {
                this.$emit("refreshRow", res);
                await this.createFinaleInvoice();
              });
            }
          });
      } else {
        await this.createFinaleInvoice();
      }
    },
    async createFinaleInvoice() {
      let invoiceOptionsDefault = InvoiceOptionModel({});
      let invoiceOptions = InvoiceOptionModel({});
      let initialDocument = structuredClone(this.initialDocument);
      let orderFormCustomer = structuredClone(this.orderFormCustomer);
      let initialDocumentOptions = structuredClone(
        this.orderFormCustomerOption
      );
      await this.getInvoiceOptionDefaultByInvoiceId({
        updateState: false,
      }).then((res) => {
        invoiceOptionsDefault = res;
        invoiceOptions = {
          invoiceId: 0,
          ...res,
          calculateMargin: initialDocumentOptions.calculateMargin,
          showDiscount: initialDocumentOptions.showDiscount,
          showDescription: initialDocumentOptions.showDescription,
          id: 0,
        };
      });

      // Le document généré vient d'ORISIS
      documentInvoice.usedUrlGed = false;

      let deduction = null;
      await this.getLastOrderFormCustomerReference({}).then(async (res) => {
        let documentInvoice = structuredClone(orderFormCustomer);
        // Stocker l'id du devis
        documentInvoice.quoteId =
          initialDocument.initialDocumentNature == "quote"
            ? structuredClone(initialDocument.id)
            : structuredClone(initialDocument.quote?.id);
        // Stocker l'id du bon de commande
        documentInvoice.orderFormCustomerId = orderFormCustomer.id;
        // Mettre à 0 l'id de la facture
        documentInvoice.id = 0;
        // Mettre la paymentConditionId
        let paymentCondition = this.paymentConditionsList.find(
          (item) => item.id == invoiceOptionsDefault.paymentConditionDefaultId
        );

        documentInvoice.paymentConditionId = paymentCondition
          ? paymentCondition.id
          : 1;
        // Aller chercher la date de validité dans limitDate
        documentInvoice.limitDate = paymentCondition
          ? dayjs().add(paymentCondition.days, "day").toISOString()
          : dayjs().toISOString();
        // Mettre la nature à 1
        documentInvoice.nature = 1;
        // Ajouter isCounted à false,
        documentInvoice.isCounted = false;
        // Mettre documentDate à la date du jour
        documentInvoice.documentDate = new Date().toISOString();
        // Mettre documentReference à res
        documentInvoice.documentReference = res.data;
        // Mettre le status à 8
        documentInvoice.status = 8;
        // Calculer le prix Ht
        documentInvoice.totalHt = orderFormCustomer.totalHt;
        // Calculer le prix Ttc et le reste à payer pour la facture d'acompte
        documentInvoice.totalTtc = documentInvoice.remainingToPayTtc =
          orderFormCustomer.totalTtc;
        // Mettre à jour le documentStep
        documentInvoice.documentStep = 1;
        // Mettre à jour les paiements
        documentInvoice.path = null;
        documentInvoice.payments = [];
        // Mettre a jour la remise globale si le devis avait une remise globale
        documentInvoice.discountGlobalNumber =
          orderFormCustomer.discountGlobalNumber;
        documentInvoice.discountGlobalType =
          orderFormCustomer.discountGlobalType;
        // Mettre à jour invoiceBTP
        if (orderFormCustomer.orderFormCustomerBtp != null) {
          documentInvoice.invoiceBTP = {
            id: 0,
            workStartDate: orderFormCustomer.orderFormCustomerBtp.workStartDate,
            estimatedDurationNumber:
              orderFormCustomer.orderFormCustomerBtp.estimatedDurationNumber,
            estimatedDurationType:
              orderFormCustomer.orderFormCustomerBtp.estimatedDurationType,
          };
        } else {
          documentInvoice.invoiceBTP = null;
        }
        // Mettre à jour invoiceCEE
        if (orderFormCustomer.orderFormCustomerCee != null) {
          documentInvoice.invoiceCEE = {
            id: 0,
            technicalVisitDate:
              orderFormCustomer.orderFormCustomerCee.technicalVisitDate,
            amountPremium: orderFormCustomer.orderFormCustomerCee.amountPremium,
            precarityType: orderFormCustomer.orderFormCustomerCee.precarityType,
            parcelNumber: orderFormCustomer.orderFormCustomerCee.parcelNumber,
          };
        } else {
          documentInvoice.invoiceCEE = null;
        }

        // Mettre à jour les déductions
        if (orderFormCustomer.deduction) {
          deduction = structuredClone(orderFormCustomer.deduction);
        }
        if (initialDocumentOptions) {
          let baseMessage = "<p>Notes du bon de commande :</p>";
          let tempDivInvoiceOptionsDefault = document.createElement("div");
          tempDivInvoiceOptionsDefault.innerHTML =
            invoiceOptionsDefault?.comment;
          tempDivInvoiceOptionsDefault = tempDivInvoiceOptionsDefault.innerText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+/g, "")
            .replace(/[^a-zA-Z0-9]/g, "")
            .toLowerCase();

          let tempDivDocumentInvoice = document.createElement("div");
          tempDivDocumentInvoice.innerHTML = documentInvoice.comments;
          tempDivDocumentInvoice = tempDivDocumentInvoice.innerText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+/g, "")
            .replace(/[^a-zA-Z0-9]/g, "")
            .toLowerCase();

          if (tempDivDocumentInvoice.includes(tempDivInvoiceOptionsDefault)) {
            documentInvoice.comments = documentInvoice.comments;
          } else {
            documentInvoice.comments =
              (documentInvoice?.comments ? baseMessage : "") +
              (invoiceOptionsDefault?.comment
                ? invoiceOptionsDefault?.comment + "<br>"
                : "") +
              (documentInvoice?.comments ? documentInvoice?.comments : "");
            if (structuredClone(documentInvoice.comments).length === 0) {
              documentInvoice.comments = invoiceOptionsDefault.comment;
            }
          }
        }

        // Mettre à jour le documentDetails et mettre à jours les totaux dans le store
        await this.createInvoice({
          invoice: documentInvoice,
        }).then(async (res) => {
          documentInvoice.id = res.data.id;
          await this.createDocumentDetailsToInvoiceIdFromOrderFormCustomerId({
            invoiceId: res.data.id,
            orderFormCustomerId: orderFormCustomer.id,
          });
          await this.createInvoiceOption({
            invoiceOption: { ...invoiceOptions, invoiceId: res.data.id },
          });
          if (deduction)
            await this.applyOrderFormCustomerDeduction(
              deduction,
              res.data.id,
              orderFormCustomer
            );
          this.$router.push({
            name: "new-invoice",
            params: {
              id: res.data.id,
              title: "Nouvelle facture",
              tips: "Nouvelle facture",
              routeOrigine: "invoices",
            },
          });
        });
      });
    },

    generateDownPaymentInvoice(preRenderingData = null) {
      this.preRenderingData = preRenderingData;
      if ([0, 1, 2, 3, 7].includes(this.orderFormCustomer.status)) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour facturer ce bon de commande, vous devez le passer en statut "Facturé"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Facturer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.updateOrderFormCustomer({
                orderFormCustomer: { ...this.orderFormCustomer, status: 4 },
                loading: false,
              }).then((res) => {
                this.$emit("refreshRow", res);
                this.toggleModalNewDownPaymentInvoice(true);
              });
            }
          });
      } else {
        this.toggleModalNewDownPaymentInvoice(true);
      }
    },
    toggleModalNewDownPaymentInvoice(bool = false) {
      this.activePopupDownPaymentInvoice = bool;
    },
  },
  computed: {
    ...mapGetters([
      "orderTrackingCustomer",
      "orderFormCustomerTabActive",
      "orderFormCustomerPDF",
      "isLoadingOrderFormCustomerPDF",
      "deduction",
      "isLoadingOrderFormCustomer",
      "workspaceSelected",
      "initialDocument",
      "lastInvoice",
      "paymentConditionsList",
    ]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    orderFormCustomerOption: {
      get() {
        return this.$store.getters.orderFormCustomerOption;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_OPTION", value);
      },
    },
    orderFormCustomerDetails: {
      get() {
        return this.$store.getters["getOrderFormCustomerDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_DETAILS", value);
      },
    },

    percentAvancement() {
      if (this.orderTrackingCustomer && this.orderTrackingCustomer.id) {
        let remainingToBillHt = this.orderTrackingCustomer.remainingToBillHt
          ? this.orderTrackingCustomer.remainingToBillHt
          : 0;
        return (
          (1 -
            (this.orderTrackingCustomer.totalHt -
              (this.orderTrackingCustomer.totalHt - remainingToBillHt)) /
              this.orderTrackingCustomer.totalHt) *
          100
        ).toFixed(0);
      } else {
        return 0;
      }
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
    downPaymentInvoiceExist() {
      if (this.initialDocument) {
        return this.initialDocument.invoices &&
          this.initialDocument.invoices.length > 0
          ? this.initialDocument.invoices.filter(
              (item) => item.nature == 2 && item.status != 5
            ).length + 1
          : 0;
      }
      return 0;
    },
    situationInvoiceExist() {
      if (this.initialDocument && this.initialDocument.invoices) {
        let situationInvoice = this.initialDocument.invoices
          .filter(
            (item) => (item.nature == 3 || item.nature == 4) && item.status != 5
          )
          .sort((a, b) => a.documentStep > b.documentStep);
        if (situationInvoice.length > 0) {
          situationInvoice =
            situationInvoice[situationInvoice.length - 1].documentStep + 1;
        } else {
          situationInvoice = 1;
        }
        return situationInvoice;
      }
      return 0;
    },
    finaleInvoiceExist() {
      if (this.initialDocument) {
        return this.initialDocument.invoices &&
          this.initialDocument.invoices.length > 0
          ? this.initialDocument.invoices.filter(
              (item) => item.nature == 1 && item.status != 5
            ).length
          : 0;
      }
      return 0;
    },
  },
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
    Status,
    SelectDate,
    PopupNewDownPaymentInvoice,
    PopupNewSituationInvoice,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.vl {
  border-left: 1px solid #8a8e93;
  width: 1px;
  height: 16px;
  background: rgb(255, 255, 255);
}

.btn-invoice-builder-header {
  padding: 8px;
  height: 38px;
}

.bl-white {
  border-left-color: red !important;
}

.checkbox-options {
  margin-bottom: 10px;
}

.checkbox-options > label {
  font-size: 13px !important;
}
.etat-document {
  position: absolute;
  left: calc(50% - 100px);
  width: 200px;
  text-align: center;
}

.dropdown-options {
  height: 100%;
}

#invoiceContainer .dropdown-menu {
  width: auto !important;
}
</style>
