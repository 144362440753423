<template lang="pug">
  div(style="page-break-inside: avoid;" v-if="orderFormCustomer.documentTvas && orderFormCustomer.documentTvas.length > 1")
    #totalTva
      .total-head(:style="'border-bottom:' + orderFormCustomer.institutionColor +' 1px solid; color:' + orderFormCustomer.institutionColor")
        div.text-right(style="width:33%")
          | Taux de TVA
        div.text-right(style="width:34%")
          | Base HT
        div.text-right(style="width:33%")
          | Montant TVA
      .total-content(v-if="orderFormCustomer && !orderFormCustomerOption.isReverseCharge" v-for="(item, index) in orderFormCustomer.documentTvas ")
        div.text-right(style="width:33%")
          | {{ referencielTvaTranslate(item.referencielTvaId) }}
        div.text-right(style="width:34%")
          | {{ formatCurrency(item.value.toFixed(2)) }}
        div.text-right(style="width:33%")
          | {{ calculateTva(item) }}
        
</template>

<script>
import { mapGetters } from "vuex";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  props: {
    preview: {
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "institutionSettingsActive",
      "orderFormCustomer",
      "orderFormCustomerOption",
    ]),
  },
  methods: {
    formatCurrency,
    referencielTvaTranslate,
    calculateTva(tva) {
      let tvaRate = this.institutionSettingsActive.referencielTvas.find(
        (item) => item.referencielTva.id == tva.referencielTvaId
      ).referencielTva.value;
      return formatCurrency((tva.value * tvaRate) / 100);
    },
  },
};
</script>

<style>
#totalTva {
  border: 1px solid #dedde0 !important;
  border-radius: 5px;
  padding: 0px;
}
#totalTva .total-head {
  display: flex;
  border-bottom: 1px solid #0c3571 !important;
  color: #0c3571 !important;
}
#totalTva .total-head div {
  padding: 5px !important;
  border-right: 1px solid #dedde0 !important;
}
#totalTva .total-head div:last-child {
  border-right: 0px !important;
}
#totalTva .total-content {
  display: flex;
}
#totalTva .total-content div {
  padding: 5px !important;
  border-right: 1px solid #dedde0 !important;
}
#totalTva .total-content div:last-child {
  border-right: 0px !important;
}
</style>
