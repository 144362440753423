<template lang="pug">
  div
    .document-head.bg-primary
        .product-line.builder
            div(v-for="(key, i) in Object.keys(orderFormCustomerHeader)" :key="i" v-if="orderFormCustomerHeader[key].display" :class="orderFormCustomerHeader[key].display ? orderFormCustomerHeader[key].class : ''" :style="orderFormCustomerHeader[key].style") {{orderFormCustomerHeader[key].text}}  

    
</template>
<script>
export default {
  data() {
    return {
    };
  },

//options: 
  computed: {
    orderFormCustomerHeader: {
      get() {
        return this.$store.getters.getOrderFormCustomerHeader;
      },
      set(val) {
        this.$store.commit("SET_INVOICE_HEADER", val);
      }
    }
  },
};
</script>