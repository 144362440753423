<template lang="pug">
.builder.pb-4
  b-alert.mt-2(v-if="mandatoryInformationMissingNumber" variant='warning' show='')
    p.alert-heading
      | Certaines mentions légales sont manquantes. <a  class="cursor-pointer" style="font-weight: bold" @click="redirectToInstitution">Cliquez ici pour les ajouter</a>
  b-alert.mt-2(v-if="institutionInformationsNotUpToDate && orderFormCustomer.status > 2" variant='warning' show='')
    p.alert-heading
      | Les informations de votre bon de commande client ne sont pas à jour. <a class="cursor-pointer" style="font-weight: bold"  @click="updateOrderFormCustomerInstitutionInformations">Cliquez ici pour les mettres à jour</a>
  .builder-area
    OrderFormCustomerHeader(:disabled="disabled")
    OrderFormCustomerTable(:disabled="disabled")
    OrderFormCustomerBTools(:disabled="disabled")
    OrderFormCustomerFooter(:disabled="disabled")
</template>

<script>
import OrderFormCustomerHeader from "@/components/invoice/order-form-customer/builder/OrderFormCustomerHeader.vue";
import OrderFormCustomerTable from "@/components/invoice/order-form-customer/builder/OrderFormCustomerTable.vue";
import OrderFormCustomerBTools from "@/components/invoice/order-form-customer/builder/OrderFormCustomerBTools.vue";
import OrderFormCustomerFooter from "@/components/invoice/order-form-customer/builder/OrderFormCustomerFooter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    OrderFormCustomerHeader,
    OrderFormCustomerTable,
    OrderFormCustomerBTools,
    OrderFormCustomerFooter,
  },
  computed: {
    ...mapGetters(["institutionSelected", "institutionSettingsActive"]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    orderFormCustomerDetails: {
      get() {
        return this.$store.getters["getOrderFormCustomerDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_DETAILS", value);
      },
    },
    orderFormCustomerDownPaymentsRequestsList: {
      get() {
        return this.$store.getters["orderFormCustomerDownPaymentsRequestsList"];
      },
      set(value) {
        return this.$store.commit(
          "SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST",
          value
        );
      },
    },
    disabled() {
      return this.orderFormCustomer.status >= 3;
    },
    deduction: {
      get() {
        return this.$store.getters["deduction"];
      },
      set(value) {
        return this.$store.commit("SET_DEDUCTION", value);
      },
    },
    mandatoryInformationMissingNumber() {
      if (
        this.orderFormCustomer.institutionLegalForm == 0 ||
        this.orderFormCustomer.institutionLegalForm == 1
      ) {
        var mandatoryInformation = [
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else if (
        this.orderFormCustomer.institutionLegalForm == 2 ||
        this.orderFormCustomer.institutionLegalForm == 3
      ) {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionRcsCity",
          "institutionCapital",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      }
      let missing = 0;
      for (let i = 0; i < mandatoryInformation.length; i++) {
        const mandatory = mandatoryInformation[i];
        if (
          this.orderFormCustomer[mandatory] === null ||
          this.orderFormCustomer[mandatory] === ""
        ) {
          missing++;
        }
      }
      return missing;
    },
    institutionInformationsNotUpToDate() {
      return (
        this.institutionSelected.name !=
          this.orderFormCustomer.institutionName ||
        this.institutionSelected.legalForm !=
          this.orderFormCustomer.institutionLegalForm ||
        this.institutionSelected.address !=
          this.orderFormCustomer.institutionAddress ||
        this.institutionSelected.addressComplement !=
          this.orderFormCustomer.institutionAddressComplement ||
        this.institutionSelected.zipCode !=
          this.orderFormCustomer.institutionZipCode ||
        this.institutionSelected.city !=
          this.orderFormCustomer.institutionCity ||
        this.institutionSelected.country !=
          this.orderFormCustomer.institutionCountry ||
        this.institutionSelected.phoneNumber !=
          this.orderFormCustomer.institutionPhoneNumber ||
        this.institutionSelected.secondaryPhoneNumber !=
          this.orderFormCustomer.institutionSecondaryPhoneNumber ||
        this.institutionSelected.email !=
          this.orderFormCustomer.institutionEmail ||
        this.institutionSelected.webSite !=
          this.orderFormCustomer.institutionWebSite ||
        this.institutionSelected.registrationNumber !=
          this.orderFormCustomer.institutionRegistrationNumber ||
        this.institutionSelected.isSubjectTVA !=
          this.orderFormCustomer.institutionIsSubjectTVA ||
        this.institutionSelected.tvaNumber !=
          this.orderFormCustomer.institutionTvaNumber ||
        this.institutionSelected.siret !=
          this.orderFormCustomer.institutionSiret ||
        this.institutionSelected.rcsCity !=
          this.orderFormCustomer.institutionRcsCity ||
        this.institutionSelected.capital !=
          this.orderFormCustomer.institutionCapital ||
        this.institutionSelected.apeCode !=
          this.orderFormCustomer.institutionApeCode ||
        this.institutionSelected.guaranteeType !=
          this.orderFormCustomer.institutionGuaranteeType ||
        this.institutionSelected.insuranceName !=
          this.orderFormCustomer.institutionInsuranceName ||
        this.institutionSelected.insuranceCoverage !=
          this.orderFormCustomer.institutionInsuranceCoverage ||
        this.institutionSelected.insuranceAddress !=
          this.orderFormCustomer.institutionInsuranceAddress ||
        this.institutionSelected.insuranceAddressComplement !=
          this.orderFormCustomer.institutionInsuranceAddressComplement ||
        this.institutionSelected.insuranceCity !=
          this.orderFormCustomer.institutionInsuranceCity ||
        this.institutionSelected.insuranceZipCode !=
          this.orderFormCustomer.institutionInsuranceZipCode ||
        this.institutionSelected.insuranceCountry !=
          this.orderFormCustomer.institutionInsuranceCountry ||
        this.institutionSelected.color !=
          this.orderFormCustomer.institutionColor ||
        this.institutionSelected.secondaryColor !=
          this.orderFormCustomer.institutionSecondaryColor ||
        this.institutionSettingsActive.footerType !=
          this.orderFormCustomer.footerType ||
        this.institutionSettingsActive.footer != this.orderFormCustomer.footer
      );
    },
  },
  methods: {
    ...mapActions(["updateOrderFormCustomer"]),
    redirectToInstitution() {
      this.$router.push({
        name: "edit-institution",
        params: {
          id: this.orderFormCustomer.institutionId,
          title: "Edit : " + this.orderFormCustomer.institutionName,
          tips: "Editer la société : " + this.orderFormCustomer.institutionName,
          routeOrigine: "institutions",
        },
      });
    },
    saveAction(bool) {
      this.updateOrderFormCustomer({
        orderFormCustomer: this.orderFormCustomer,
        loading: bool,
      });
    },
    updateOrderFormCustomerInstitutionInformations() {
      this.orderFormCustomer.institutionName = this.institutionSelected.name;
      this.orderFormCustomer.institutionLegalForm =
        this.institutionSelected.legalForm;
      this.orderFormCustomer.institutionAddress =
        this.institutionSelected.address;
      this.orderFormCustomer.institutionAddressComplement =
        this.institutionSelected.addressComplement;
      this.orderFormCustomer.institutionZipCode =
        this.institutionSelected.zipCode;
      this.orderFormCustomer.institutionCity = this.institutionSelected.city;
      this.orderFormCustomer.institutionCountry =
        this.institutionSelected.country;
      this.orderFormCustomer.institutionPhoneNumber =
        this.institutionSelected.phoneNumber;
      this.orderFormCustomer.institutionSecondaryPhoneNumber =
        this.institutionSelected.secondaryPhoneNumber;
      this.orderFormCustomer.institutionEmail = this.institutionSelected.email;
      this.orderFormCustomer.institutionWebSite =
        this.institutionSelected.webSite;
      this.orderFormCustomer.institutionRegistrationNumber =
        this.institutionSelected.registrationNumber;
      this.orderFormCustomer.institutionIsSubjectTVA =
        this.institutionSelected.isSubjectTVA;
      this.orderFormCustomer.institutionTvaNumber =
        this.institutionSelected.tvaNumber;
      this.orderFormCustomer.institutionSiret = this.institutionSelected.siret;
      this.orderFormCustomer.institutionRcsCity =
        this.institutionSelected.rcsCity;
      this.orderFormCustomer.institutionCapital =
        this.institutionSelected.capital;
      this.orderFormCustomer.institutionApeCode =
        this.institutionSelected.apeCode;
      this.orderFormCustomer.institutionGuaranteeType =
        this.institutionSelected.guaranteeType;
      this.orderFormCustomer.institutionInsuranceName =
        this.institutionSelected.insuranceName;
      this.orderFormCustomer.institutionInsuranceCoverage =
        this.institutionSelected.insuranceCoverage;
      this.orderFormCustomer.institutionInsuranceAddress =
        this.institutionSelected.insuranceAddress;
      this.orderFormCustomer.institutionInsuranceAddressComplement =
        this.institutionSelected.insuranceAddressComplement;
      this.orderFormCustomer.institutionInsuranceCity =
        this.institutionSelected.insuranceCity;
      this.orderFormCustomer.institutionInsuranceZipCode =
        this.institutionSelected.insuranceZipCode;
      this.orderFormCustomer.institutionInsuranceCountry =
        this.institutionSelected.insuranceCountry;
      this.orderFormCustomer.institutionColor = this.institutionSelected.color;
      this.orderFormCustomer.institutionSecondaryColor =
        this.institutionSelected.secondaryColor;
      this.orderFormCustomer.footerType =
        this.institutionSettingsActive.footerType;
      this.orderFormCustomer.footer = this.institutionSettingsActive.footer;
      this.saveAction(false);
    },
  },
};
</script>

<style scoped>
#invoiceContainer {
  height: calc(100% - 23px) !important;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.builder-area {
  /* margin-top: 20px; */
  padding: 20px;
  background-color: white !important;
  border: solid 2px #f1f1f1;
}
</style>
