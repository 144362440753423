<template lang="pug">
div(class="invoiceFooter")
    .row
      .col-sm-6.mt-1.invoiceFooterLeft(style="page-break-inside: avoid;")
        PaymentConditions.mt-1(:preview="true")
        TotalTva.mt-2
      div.col-sm-6.mt-2.invoiceFooterRight
        TotalOrderFormCustomer(v-if='!orderFormCustomerOption.hideTotal' @updateTvaDetails='updateTvaDetails' :canEditDiscount='true' :preview="true" ref="TotalOrderFormCustomer")
        div.print-signature.mt-2.border.rounded.content-total(style="page-break-inside: avoid;")
          | Date et signature du client précédée de la mention "Lu et approuvé, bon pour accord" :
    .print-footer.mb-1(ref="printFooter")
        span(v-html="orderFormCustomer.footer")
</template>
<script>
import PaymentConditions from "@/components/invoice/order-form-customer/builder/PaymentConditions";
import TotalOrderFormCustomer from "@/components/invoice/order-form-customer/builder/TotalOrderFormCustomer";
import AddGlobalDiscount from "@/components/invoice/order-form-customer/builder/AddGlobalDiscount";
import TotalTva from "@/components/invoice/order-form-customer/builder/TotalTva";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tvaDetails: [],
      printFooterHeight: 0,
    };
  },
  computed: {
    ...mapGetters(["orderFormCustomer", "orderFormCustomerOption"]),
  },
  methods: {
    updateTvaDetails(val) {
      this.tvaDetails = val;
    },
  },
  components: {
    PaymentConditions,
    TotalOrderFormCustomer,
    AddGlobalDiscount,
    TotalTva,
  },
};
</script>
