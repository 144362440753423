<template lang="pug">
  td
    div(v-if="orderFormCustomerHeader[dataKey].inputType == 1") 
      span {{orderFormCustomerHeader[dataKey].prefix }} 
      span {{orderFormCustomerHeader[dataKey].numberToFixed ? formatNumber(line[dataKey]) : line[dataKey]}}
      span &nbsp;{{orderFormCustomerHeader[dataKey].unit}}
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 2") 
      span {{formatNumberToString(line[dataKey])}}
      span &nbsp;{{orderFormCustomerHeader[dataKey].unit}}
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 10") 
      SelectCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :editFocus="editFocus" :preview="true") 
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 23") 
      MarginCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :editFocus="editFocus" :preview="true")
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 24") 
      span(v-html="line[dataKey]")
</template>

<script>
import SelectCol from '@/components/invoice/order-form-customer/builder/table/cols/SelectCol.vue'
import TextCol from '@/components/invoice/order-form-customer/builder/table/cols/TextCol.vue'
import NumberCol from '@/components/invoice/order-form-customer/builder/table/cols/NumberCol.vue'
import MarginCol from '@/components/invoice/order-form-customer/builder/table/cols/MarginCol.vue'
import SelectCatalog from '@/components/invoice/order-form-customer/builder/table/cols/SelectCatalog.vue'
import { formatNumber, formatNumberToString } from "@/types/api-orisis/library/FormatOperations.ts"

export default {
  data () {
    return {}
  },
  props: {
    edit: {
      type: Boolean,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    orderFormCustomerHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    },
    editFocus: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    formatNumber,
    formatNumberToString,
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    }
  },
  components: {
    SelectCol,
    TextCol,
    NumberCol,
    MarginCol,
    SelectCatalog
  }
}
</script>