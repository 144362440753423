<template lang="pug">
    table.documentDetails   
      thead
        OrderFormCustomerPreviewHeader()
      tbody 
        OrderFormCustomerPreviewDetails(v-for="(line, index) in getOrderFormCustomerDetails" :key="index" :line="line" :index="index")
</template>
<script>
import OrderFormCustomerPreviewHeader from '@/components/invoice/order-form-customer/preview/table/OrderFormCustomerPreviewHeader.vue'
import OrderFormCustomerPreviewDetails from '@/components/invoice/order-form-customer/preview/table/OrderFormCustomerPreviewDetails.vue'
import { EDocumentTypeLine, EInputType } from '@/types/api-orisis/enums/enums'
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['getOrderFormCustomerDetails'])
  },
  components: {
    OrderFormCustomerPreviewHeader,
    OrderFormCustomerPreviewDetails,
    draggable
  }
}
</script>
