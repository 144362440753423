<template lang="pug">
div
    b-row
      b-col(cols="12").col-lg-6
        ul        
          li
            div.d-flex
              h3.text-primary(v-if="!edit") Commande n° {{ orderFormCustomer.documentReference ?  orderFormCustomer.documentReference : 'en attente' }}
                feather-icon.ml-1(icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="'Modifier le numéro de commande'")
            div.mb-1(v-if="edit")
              div.d-flex(class="justify-content-between w-100")
                span Commande n°
                span.cursor-pointer.text-primary(v-if="edit" @click="saveDocumentReference") Fermer
              ejs-textbox(v-model="orderFormCustomer.documentReference")
          li(v-if="orderFormCustomer.quoteId && initialDocument")
            span Provenant du devis : {{ initialDocument.quote.documentReference }}
          li.d-flex.align-items-center
            Status(:statusId="orderFormCustomer.status" nature="orderFormCustomer" :static="true")
            SelectDate(:disabled="disabled" keyValue="documentDate" @setValue="setOrderFormCustomerDate" documentDetailKey="documentDate" text="En date du ")
          li(style="margin-bottom:5px")
            SelectCounterDate(:disabled="disabled" keyValue="limitDate" @setValue="saveAction" text="Valable jusqu'au ")
          li(v-if="orderFormCustomer.type == 2" style="margin-bottom:5px")
            SelectDateCEE(:disabled="disabled" keyValue="technicalVisitDate" @setValue="setDocumentTechnicalVisitDate" text="Visite technique le ")
          li(v-if="orderFormCustomer.type == 3 || orderFormCustomer.type == 2" style="margin-bottom:5px")
            SelectDateWork(:disabled="disabled" keyValue="workStartDate" @setValue="setWorkStartDate" text="Début des travaux le ")
          li(v-if="orderFormCustomer.type == 3 || orderFormCustomer.type == 2")
            SelectPeriod(:disabled="disabled" keyValue="workDateEnd" @setValue="setWorkEndDate" text="Durée estimée à ")
          li(v-if="!orderFormCustomer.hideUser").mt-2
            UserInfoOrderFormCustomer(:disabled="disabled")
          li(v-if="collaboratorInfo").mt-2
            div
              span.text-primary.font-weight-bold Dossier suivi par : 
            div
              span.font-weight-bold {{ collaboratorInfo.firstName }} {{ collaboratorInfo.lastName }}
            div
              span {{ collaboratorInfo.email ? "E-mail : " + collaboratorInfo.email : "" }}
            div
              span {{ collaboratorInfo.phoneNumber ? "Tél : " + collaboratorInfo.phoneNumber : "" }}
      b-col(cols="12").offset-lg-1.col-lg-5
        div
          SelectCompany(:disabled="disabled" @setValue="setSelectCompany" @setSelectCompanyAddress="setSelectCompanyAddress" placeholder="Sélectionner un client" :options='companiesList')
        div
          SelectAffair(:disabled="disabled" @setValue="setSelectAffair" placeholder="Sélectionner une affaire" :options='affairsList')
    b-row
      b-col.mb-0(cols="12" class="align-items-center")
        OrderFormCustomerDescription(:disabled="disabled" @setValue="setInvoiceDescription" :key="'OrderFormCustomerDescription_'+orderFormCustomer.id")

            
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Status from "@/components/global/status/Status";
import SelectCompany from "@/components/invoice/order-form-customer/builder/SelectCompany";
import SelectAffair from "@/components/invoice/order-form-customer/builder/SelectAffair";
import SelectDate from "@/components/invoice/order-form-customer/builder/SelectDate";
import SelectDateCEE from "@/components/invoice/order-form-customer/builder/SelectDateCEE";
import SelectDateWork from "@/components/invoice/order-form-customer/builder/SelectDateWork";
import SelectPeriod from "@/components/invoice/order-form-customer/builder/SelectPeriod";
import OrderFormCustomerDescription from "@/components/invoice/order-form-customer/builder/OrderFormCustomerDescription";
import SelectCounterDate from "@/components/invoice/order-form-customer/builder/SelectCounterDate";
import UserInfoOrderFormCustomer from "@/components/invoice/order-form-customer/builder/UserInfoOrderFormCustomer";

export default {
  data() {
    return {
      collaboratorInfo: null,
      edit: false,
    };
  },
  mounted() {
    this.setCollaboratorInfo();
  },
  props: {
    disabled: {
      default: false,
    },
  },
  methods: {
    ...mapActions([
      "updateOrderFormCustomer",
      "getCompanyById",
      "getCollaboratorById",
    ]),
    setOrderFormCustomerDate(date) {
      this.orderFormCustomer = {
        ...this.orderFormCustomer,
        documentDate: date,
        startDate: date,
        endDate: date,
      };
      this.saveAction();
    },
    setOrderFormCustomerTechnicalVisitDate(date) {
      this.orderFormCustomer.orderFormCustomerCee = {
        ...this.orderFormCustomer.orderFormCustomerCee,
        technicalVisitDate: date,
      };
      this.saveAction();
    },

    setWorkStartDate(date) {
      this.orderFormCustomer.orderFormCustomerBtp = {
        ...this.orderFormCustomer.orderFormCustomerBtp,
        workStartDate: date,
      };
      this.saveAction();
    },
    setWorkEndDate(durationNumber, durationType) {
      this.orderFormCustomer.orderFormCustomerBtp = {
        ...this.orderFormCustomer.orderFormCustomerBtp,
        estimatedDurationNumber: durationNumber,
        estimatedDurationType: durationType,
      };
      this.saveAction();
    },
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            let adressReturn = {
              ...adress,
              name: company.name,
              firstName: company.firstName,
              lastName: company.lastName,
            };
            resolve(adressReturn);
          }
        });
      });
    },
    async setCollaboratorInfo() {
      if (this.orderFormCustomer.companyId) {
        await this.getCompanyById({
          companyId: this.orderFormCustomer.companyId,
        }).then(async (company) => {
          this.collaboratorInfo = company.collaboratorId
            ? await this.getCollaboratorById({
                collaboratorId: company.collaboratorId,
              })
            : null;
        });
      } else {
        this.collaboratorInfo = null;
      }
    },
    setSelectCompanyAddress(company) {
      this.orderFormCustomer = {
        ...this.orderFormCustomer,
        companyId: company.id,
        companyName: company.name,
        companyAddress: company.address,
        companyAddressId: company.addressId,
        companyAddressLabel: company.label,
        companyAddressComplement: company.addressComplement,
        companyCity: company.city,
        companyZipCode: company.zipCode,
        companyCountry: company.country,
        companySiret: company.siret,
        companyTva: company.tvaNumber,
      };
      this.saveAction();
    },
    async setSelectCompany(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.orderFormCustomer = {
          ...this.orderFormCustomer,
          companyId: company.id,
          companyName: company.name,
          companyFirstName: company.firstName,
          companyLastName: company.lastName,
          companyAddress: company.address,
          companyCity: company.city,
          companyZipCode: company.zipCode,
          companyCountry: company.country,
          companyMail: company.email,
          companyPhoneNumber: company.phoneNumber,
          companyTva: company.tvaNumber,
          companySiret: company.siret,
        };
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.orderFormCustomer = {
                  ...this.orderFormCustomer,
                  companyId: company.id,
                  companyName: company.name,
                  companyFirstName: company.firstName,
                  companyLastName: company.lastName,
                  companyAddress: res.address,
                  companyAddressComplement: res.addressComplement,
                  companyCity: res.city,
                  companyZipCode: res.zipCode,
                  companyCountry: res.country,
                  companyMail: company.email,
                  companyPhoneNumber: company.phoneNumber,
                  companyTva: company.tvaNumber,
                  companySiret: company.siret,
                  companyAddressLabel: res.label,
                  companyAddressId: res.id,
                };
              });
            } else {
              this.orderFormCustomer = {
                ...this.orderFormCustomer,
                companyId: company.id,
                companyName: company.name,
                companyFirstName: company.firstName,
                companyLastName: company.lastName,
                companyAddress: null,
                companyAddressComplement: null,
                companyCity: null,
                companyZipCode: null,
                companyCountry: null,
                companyMail: company.email,
                companyPhoneNumber: company.phoneNumber,
                companyTva: company.tvaNumber,
                companySiret: company.siret,
                companyAddressLabel: null,
                companyAddressId: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    setSelectAffair(affair) {
      this.orderFormCustomer = {
        ...this.orderFormCustomer,
        affairId: affair.id,
        affairName: affair.name,
        affairAddress: affair.address,
        affairAddressComplement: affair.addressComplement,
        affairZipCode: affair.zipCode,
        affairCity: affair.city,
        affairCountry: affair.country,
        affairCode: affair.code,
      };
      this.saveAction();
    },
    setInvoiceDescription(description) {
      this.orderFormCustomer = {
        ...this.orderFormCustomer,
        description: description,
      };
      this.saveAction();
    },
    saveDocumentReference() {
      this.edit = false;
      this.saveAction();
    },
    saveAction() {
      this.updateOrderFormCustomer({
        orderFormCustomer: this.orderFormCustomer,
        loading: false,
      });
    },
  },
  computed: {
    ...mapGetters(["companiesList", "affairsList", "initialDocument"]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    Status,
    SelectCompany,
    SelectAffair,
    SelectDate,
    SelectPeriod,
    OrderFormCustomerDescription,
    SelectCounterDate,
    SelectDateWork,
    UserInfoOrderFormCustomer,
    SelectDateCEE,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
