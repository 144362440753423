<template lang="pug"> 
  BreakPageCol(:line="line" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :canEdit="true" :edit="edit" :preview="true")      
</template>

<script>
import BreakPageCol from '@/components/invoice/order-form-customer/builder/table/cols/BreakPageCol.vue'

export default {
  data () {
    return {}
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    orderFormCustomerHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    }
  },
  components: {
    BreakPageCol
  }
}
</script>
