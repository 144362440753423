<template lang="pug"> 
  td
    div(v-if="orderFormCustomerHeader[dataKey].inputType == 1") 
      span {{orderFormCustomerHeader[dataKey].prefix }} 
      span {{orderFormCustomerHeader[dataKey].numberToFixed ? (line[dataKey]).toFixed(orderFormCustomerHeader[dataKey].numberToFixed) : line[dataKey]}}
      span {{orderFormCustomerHeader[dataKey].unit}}
    div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 25")  
      span(:style="'color:'+orderFormCustomer.institutionColor+' !important'") {{line[dataKey]}}
</template>
<script>
import { mapGetters } from 'vuex'
import TitleCol from '@/components/invoice/order-form-customer/builder/table/cols/TitleCol.vue'
import TextCol from '@/components/invoice/order-form-customer/builder/table/cols/TextCol.vue'

export default {
  data () {
    return {}
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    orderFormCustomerHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    },
    editFocus: {
      type: Boolean,
      required: true
    },
   
  },
  computed: {
    ...mapGetters(['orderFormCustomer'])
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    }
  },
  components: {
    TitleCol,
    TextCol
  }
}
</script>
