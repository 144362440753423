<template lang="pug">
    input(v-if="!disabled" @focus="emitEditFocus(true)" @blur="blurFunction(false)" class="w-100" :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled ? 'disabled' :'']" :disabled="!edit" placeholder="" v-model="line[dataKey]") 
    div(v-else style="margin-top: 7px")
      span(:style="'color:'+orderFormCustomer.institutionColor+' !important;'") {{line[dataKey]}}
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      ...mapGetters(["orderFormCustomer"]),
    };
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    ...mapActions(["updateOrderFormCustomerDetail"]),
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.emitEditFocus(res);
      if (this.line.titre) {
        this.line.description = this.line.titre;
      }
      this.updateOrderFormCustomerDetail({
        orderFormCustomerDetail: this.line,
      });
    },
  },
};
</script>
