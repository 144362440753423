<template lang="pug">
div.h-100
  b-row.my-0.h-100
    b-col.py-0.mb-1.h-100(cols='12')
      quill-editor.h-100.quill-editor-scroll(class="editor quill-fixed" @blur="saveAction" v-model="orderFormCustomer.internalNote" :options="editorOption")
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      editorOption: {
        placeholder: 'Ecrivez une note interne liée à cette commande client...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['link'],
            ['clean'] 
          ]
        }
      },
    }
  },
  components: {
    quillEditor
  },
  computed: {
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer; 
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMRE", value);
      }
    },
  },
  methods:{
    ...mapActions(['updateOrderFormCustomer']),
    saveAction() {
      this.updateOrderFormCustomer({orderFormCustomer: this.orderFormCustomer, loading: false})
    },
  },
}
</script>