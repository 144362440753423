<template lang="pug">
.document-line(ref="orderFormCustomerDetails" v-show="isShowLine" @mouseover="edit = true, mouseOn = true" @mouseleave="edit=false, mouseOn=false")
    input(class="w-100" :ref="'fakeInputOrderFormCustomerDetail'+index" style="width: 0px !important; position: absolute; display:block")
    .product-line.builder(:class="[line.isOption ? 'isOption' :'',(line.type==14 ||line.type==15 ||line.type==16 ||line.type==17 ||line.type==18) ? 'isWorkElement' : '',isWorkLineWithElement() ? 'isWorkLineWithElement' : '', line.type == 2 ? 'align-items-center':'']")
      span.feather-drag-document-detail.handle.cursor-move.material-icons(:class="disabled ? 'invisible' : ''") drag_indicator
      div.type-line.no-printting {{documentDetailTypeTranslate(line.type)}}
      OrderFormCustomerDetailCommonLine.m-0(:disabled="disabled" @emitEditFocus="changeEditFocus" v-for="(key, i) in Object.keys(orderFormCustomerHeader)" :key="i"  :dataKey="key" v-if="orderFormCustomerHeader[key].display && (line.type == 4 || line.type == 5 || line.type == 6 || line.type == 7 || line.type == 8 || line.type == 9 || line.type==14 ||line.type==15 ||line.type==16 ||line.type==17 ||line.type==18)" :class="orderFormCustomerHeader[key].class" :style="orderFormCustomerHeader[key].display ? orderFormCustomerHeader[key].style : ''" :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index") 
      OrderFormCustomerDetailSectionLine.m-0(:disabled="disabled" @emitEditFocus="changeEditFocus" v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 0 || line.type == 11" :line="line" :class="orderFormCustomerHeader[key].class" :style="orderFormCustomerHeader[key].style" :orderFormCustomerHeader="orderFormCustomerHeader" :dataKey="key" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      OrderFormCustomerDetailSubTotalLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 13" :line="line" :class="orderFormCustomerHeader[key].class" :style="orderFormCustomerHeader[key].style" :orderFormCustomerHeader="orderFormCustomerHeader" :dataKey="key" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      OrderFormCustomerDetailTextLine.m-0(:disabled="disabled" @emitEditFocus="changeEditFocus" v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 1" :class="orderFormCustomerHeader[key].class" :style="orderFormCustomerHeader[key].style" :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      OrderFormCustomerDetailBreakPageLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 2"  :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index")
      OrderFormCustomerDetailBreakLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 12"  :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index")
      OrderFormCustomerDetailPriceLine.m-0(:disabled="disabled" @emitEditFocus="changeEditFocus" v-for="(key, i) in ['index', 'description', 'referencielTvaId', 'total']" :key="i"  :dataKey="key" v-if="orderFormCustomerHeader[key].display && (line.type == 3)" :class="orderFormCustomerHeader[key].class" :style="orderFormCustomerHeader[key].display ? orderFormCustomerHeader[key].style : ''" :line="line" :orderFormCustomerHeader="orderFormCustomerHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      vs-dropdown.more-actions(v-if="!disabled" vs-trigger-click)
        span.handle.cursor-pointer(class='material-icons') expand_more
        vs-dropdown-menu
          vs-dropdown-group(v-if="line.type == 6" vs-collapse='' vs-label='Ajouter un composant' vs-icon='add')
            vs-dropdown-item(@click="newLine({type: 14, unit: 1, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type: 17, unit: 2, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type: 15, unit: 1, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type: 16, unit: 1, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type: 18, unit: 1, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Autre
          vs-divider.p-0.m-0(v-if="line.type == 6")
          vs-dropdown-item(v-if="line.type == 13" @click="toggleOptionLine(index)")  {{line.isOption ? "Sous-total" : "Sous-total des options"}}
          vs-dropdown-item(:disabled="line.isDisabled && orderFormCustomer.institutionId != 25" v-else-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18" @click="toggleOptionLine(index)")  {{line.isOption ? "Ligne non optionnelle" : "Ligne optionnelle"}}
          vs-divider.p-0.m-0
          vs-dropdown-group(v-if='line.type==14 || line.type==15 || line.type==16 || line.type==17 || line.type==18' vs-collapse='' vs-label='Insérer au dessus' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:14, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:17, unit : 2, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:15, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type:16, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:18, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Autre
          vs-dropdown-group(v-else vs-collapse='' vs-label='Insérer au dessus' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:4, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:5, unit : 2, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:6, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Ouvrage
            vs-dropdown-item(@click="newLine({type:7, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type:8, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:9, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Autre
            vs-dropdown-item(@click="newLine({type:1, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Texte
            vs-dropdown-item(@click="newLine({type:2, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Saut de page
            vs-dropdown-item(@click="newLine({type:12, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Saut de ligne
          vs-dropdown-group(v-if='line.type==14 || line.type==15 || line.type==16 || line.type==17 || line.type==18' vs-collapse='' vs-label='Insérer en dessous' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:14, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:17, unit : 2, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:15, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type:16, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:18, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Autre
          vs-dropdown-group(v-else vs-collapse='' vs-label='Insérer en dessous' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:4, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:5, unit : 2, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:6, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Ouvrage
            vs-dropdown-item(@click="newLine({type:7, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type:8, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:9, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Autre
            vs-dropdown-item(@click="newLine({type:1, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Texte
            vs-dropdown-item(@click="newLine({type:2, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Saut de page
            vs-dropdown-item(@click="newLine({type:12, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Saut de ligne
          vs-divider.p-0.m-0
          vs-dropdown-group(v-if="line.type !== 6" vs-collapse='' vs-label='Dupliquer' vs-icon='add')
            vs-dropdown-item(@click="duplicateOrderFormCustomerLine({ payload: {line: line, index: index}, position: 'before' })")
              | Au dessus
            vs-dropdown-item(@click="duplicateOrderFormCustomerLine({ payload: {line: line, index: index}, position: 'after' })")
              | En dessous
            vs-dropdown-item(@click="duplicateOrderFormCustomerLine({ payload: {line: line, index: index}, position: 'beginning' })")
              | Au début
            vs-dropdown-item(@click="duplicateOrderFormCustomerLine({ payload: {line: line, index: index}, position: 'end' })")
              | À la fin
          vs-dropdown-group(v-if="line.type == 6" vs-collapse='' vs-label='Dupliquer' vs-icon='add')
            vs-dropdown-item(@click="duplicateOrderFormCustomerWorkLineLocal(line, index, 'before')")
              | Au dessus
            vs-dropdown-item(@click="duplicateOrderFormCustomerWorkLineLocal(line, index, 'after')")
              | En dessous
            vs-dropdown-item(@click="duplicateOrderFormCustomerWorkLineLocal(line, index, 'beginning')")
              | Au début
            vs-dropdown-item(@click="duplicateOrderFormCustomerWorkLineLocal(line, index, 'end')")
              | À la fin 
          vs-dropdown-item.text-danger(:disabled="line.isDisabled" @click="deleteLine(line)")
            | Supprimer
</template>

<script>
import OrderFormCustomerDetailCommonLine from "@/components/invoice/order-form-customer/builder/table/lines/OrderFormCustomerDetailCommonLine.vue";
import OrderFormCustomerDetailSectionLine from "@/components/invoice/order-form-customer/builder/table/lines/OrderFormCustomerDetailSectionLine.vue";
import OrderFormCustomerDetailTextLine from "@/components/invoice/order-form-customer/builder/table/lines/OrderFormCustomerDetailTextLine.vue";
import OrderFormCustomerDetailBreakPageLine from "@/components/invoice/order-form-customer/builder/table/lines/OrderFormCustomerDetailBreakPageLine.vue";
import OrderFormCustomerDetailBreakLine from "@/components/invoice/order-form-customer/builder/table/lines/OrderFormCustomerDetailBreakLine.vue";
import OrderFormCustomerDetailPriceLine from "@/components/invoice/order-form-customer/builder/table/lines/OrderFormCustomerDetailPriceLine.vue";
import OrderFormCustomerDetailSubTotalLine from "@/components/invoice/order-form-customer/builder/table/lines/OrderFormCustomerDetailSubTotalLine.vue";
import {
  calculateSubTotalOrderFormCustomerLine,
  calculateSubTotalOrderFormCustomerLineBySection,
} from "@/types/api-orisis/library/DocumentOperations";
import { mapGetters, mapActions } from "vuex";
import { lineChartOptions } from "@/@core/components/statistics-cards/chartOptions";
import { documentDetailTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { applyOrderFormCustomerDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";

export default {
  data() {
    return {
      edit: false,
      mouseOn: false,
      editFocus: false,
    };
  },
  computed: {
    ...mapGetters(["institutionSettingsActive"]),
    isShowLine() {
      if (
        this.line.parentId !== null &&
        this.parendIdHide == this.line.parentId
      ) {
        return false;
      } else {
        return true;
      }
    },
    orderFormCustomerHeader: {
      get() {
        return this.$store.getters.getOrderFormCustomerHeader;
      },
      set(val) {
        this.$store.commit("SET_INVOICE_HEADER", {
          ...orderFormCustomerHeader,
          val,
        });
      },
    },
    orderFormCustomerDetails: {
      get() {
        return this.$store.getters["getOrderFormCustomerDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_DETAILS", value);
      },
    },
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    columnText() {
      if (this.orderFormCustomer.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parendIdHide: {
      type: Number,
    },
    indexToFocus: {
      type: Number,
    },
    disabled: {
      default: false,
    },
  },
  watch: {
    indexToFocus(val) {
      if (val == this.index) {
        this.$nextTick(() => {
          this.$refs["fakeInputOrderFormCustomerDetail" + this.index]?.focus({
            preventScroll: true,
          });
        });
      }
    },
  },
  methods: {
    calculateSubTotalOrderFormCustomerLine,
    calculateSubTotalOrderFormCustomerLineBySection,
    documentDetailTypeTranslate,
    applyOrderFormCustomerDeduction,
    ...mapActions([
      "addOrderFormCustomerLine",
      "deleteOrderFormCustomerDetails",
      "duplicateOrderFormCustomerLine",
      "duplicateOrderFormCustomerWorkLine",
      "updateOrderFormCustomerDetail",
    ]),
    changeEditFocus(val) {
      this.editFocus = val;
    },
    isWorkLineWithElement() {
      if (this.line.type == 6) {
        let workLineElements = this.orderFormCustomerDetails.filter(
          (el) => el.parentId == this.line.id
        );
        if (workLineElements && workLineElements.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    duplicateOrderFormCustomerWorkLineLocal(line, index, position) {
      let workLine = line;
      let workLineElements = this.orderFormCustomerDetails.filter(
        (el) => el.parentId == line.id
      );
      this.duplicateOrderFormCustomerWorkLine({
        orderFormCustomerDetailId: line.id,
        workLineElements: workLineElements,
        index,
        position,
      });
    },
    newLine(line) {
      let _this = this;
      this.addOrderFormCustomerLine({
        orderFormCustomerDetail: {
          ...line,
          orderFormCustomerId: this.orderFormCustomer.id,
          referencielTvaId: this.institutionSettingsActive.referencielTvas.find(
            (elem) => elem.isDefault == true
          ).referencielTva.id,
        },
        indexArray: line.indexArray,
        review: line.review,
      });
      this.$nextTick(() => {
        _this.$refs["fakeInputOrderFormCustomerDetail" + this.index]?.focus({
          preventScroll: true,
        });
      });
    },
    async deleteLine(line) {
      let _this = this;
      let arrayIds = [];
      if (line.type == 6) {
        let workLineElements = this.orderFormCustomerDetails.filter(
          (el) => el.parentId == line.id
        );
        workLineElements.forEach((element) => {
          arrayIds.push(element.id);
        });
      }
      arrayIds.push(line.id);
      await this.deleteOrderFormCustomerDetails({
        orderFormCustomerDetailIds: arrayIds,
        reviewIndex: true,
        orderFormCustomerDetail: line,
      }).then(() => {
        this.$nextTick(() => {
          _this.$refs["fakeInputOrderFormCustomerDetail" + this.index]?.focus({
            preventScroll: true,
          });
        });
      });
      this.applyOrderFormCustomerDeduction();
    },
    async toggleOptionLine(index) {
      if (this.line.type == 13) {
        this.calculateSubTotalOrderFormCustomerLine(
          {
            ...this.line,
            indexArray: index,
            isOption: !this.line.isOption,
            description: "Sous-total des options",
          },
          !this.line.isOption,
          false
        );
        this.applyOrderFormCustomerDeduction();
      } else {
        await this.updateOrderFormCustomerDetail({
          orderFormCustomerDetail: {
            ...this.line,
            indexArray: index,
            isOption: !this.line.isOption,
          },
        });
        this.calculateSubTotalOrderFormCustomerLineBySection(this.line);
        this.applyOrderFormCustomerDeduction();
      }
    },
  },
  components: {
    OrderFormCustomerDetailCommonLine,
    OrderFormCustomerDetailSectionLine,
    OrderFormCustomerDetailTextLine,
    OrderFormCustomerDetailBreakPageLine,
    OrderFormCustomerDetailBreakLine,
    OrderFormCustomerDetailPriceLine,
    OrderFormCustomerDetailSubTotalLine,
  },
};
</script>
