<template lang="pug">
  div.no-printting
    vs-dropdown(vs-trigger-click='') 
      .flex.item-center.cursor-pointer
        span.text-primary + Ajouter une condition
      vs-dropdown-menu
        vs-dropdown-item(@click="setPaymentCondition({type: 'downPaymentRequest', isReview: false})")
          |Ajouter un acompte
        vs-dropdown-item(:disabled='holdbackIsDisabled' @click="setPaymentCondition({type: 'holdback', isReview: false})")
          |Retenue de garantie 
        vs-dropdown-item(@click="setPaymentCondition({type: 'review', isReview: false})")
          |Ajouter une déduction / majoration HT
        vs-dropdown-item(@click="setPaymentCondition({type: 'punctualDeduction', isReview: false})")
          |Ajouter une déduction / majoration TTC
        vs-dropdown-item(:disabled='prorataIsDisabled' @click="setPaymentCondition({type: 'prorata', isReview: false})")
          |Ajouter un compte de prorata
        vs-dropdown-item(@click="setPaymentCondition({type: 'review', isReview: true})")
          |Ajouter une révision de prix
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    deduction: {
      get() {
        return this.$store.getters["deduction"];
      },
      set(value) {
        return this.$store.commit("SET_DEDUCTION", value);
      },
    },
    holdbackIsDisabled() {
      return this.deduction
        ? this.deduction.holdbackNumber > 0 &&
            this.deduction.holdbackPercent > 0
        : false;
    },
    prorataIsDisabled() {
      return this.deduction
        ? this.deduction.prorataAccountPrice != null &&
            this.deduction.prorataAccountLabel != null &&
            this.deduction.prorataAccountPercent != null
        : false;
    },
  },
  methods: {
    setPaymentCondition(type) {
      this.$emit("setPaymentCondition", type);
    },
  },
};
</script>
<style>
.vs-dropdown--menu {
  margin-bottom: 0 !important;
}
</style>
