<template lang="pug">
div(style='display:contents')
  div(v-if="isLoadingOrderFormCustomer || isLoadingOrderFormCustomerPDF || isValidatingOrderFormCustomer" class="h-100")
    .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner 
        .mt-5 
          br
          br
          br
          |  {{ isValidatingOrderFormCustomer ? "Validation de la commande client en cours, veuillez patienter..." : "Génération du PDF..." }}
  div(style='display:contents' v-show="!isLoadingOrderFormCustomer && !isLoadingOrderFormCustomerPDF && !isValidatingOrderFormCustomer")
    iframe.pdfViewver(:src="orderFormCustomerPDF" style="width: 100%;height: 100%; border: solid 2px #f1f1f1; padding:0px") 
    div(style="display:contents" v-if="statusCheck.orderFormCustomerEditable.includes(orderFormCustomer.status) && !orderFormCustomer.path || orderFormCustomer.path=='' || orderFormCustomer.path == null || orderFormCustomer.path.includes('null.pdf')")
      orderFormCustomerPdfContent.pdfViewver(style="width: 100%;height: 100%; border: none; padding: 0px; display:none")
        table.structure
          thead
            tr
              td
                .page-header-space
          tbody
            tr
              td
                div#invoiceContainer.pdfViewver
                  OrderFormCustomerPreviewHeader()
                  OrderFormCustomerPreviewTable()
                  OrderFormCustomerPreviewFooter()
                  OrderFormCustomerPreviewTermsAndConditions(v-if="institutionSettingsActive.showTermsOfSales")
                  div.mt-2(v-if="orderFormCustomerOption.additionalLegalNotices" v-html="orderFormCustomerOption.additionalLegalNotices" style="margin-top: 2rem !important; font-style: italic !important")
                  .watermark(v-if="orderFormCustomer.status <= 1")
                    .text PROVISOIRE
                  .watermark(v-else-if="orderFormCustomer.status == 6")
                    .text ANNULÉ
                  .watermark(v-else-if="orderFormCustomerOption.showWaterMark && orderFormCustomer.status !== 2 && orderFormCustomer.status !== 3 && orderFormCustomer.status !== 6")
                    .text {{ statusToUpperCaseTranslate(orderFormCustomer.status) }}
                  .background-documents(v-if="orderFormCustomer.institutionId == 17")
          tfoot
            tr
              td
                .page-footer-space(:style="'height:'+pageFooterHeight()+'px !important'")
                  #pageFooter
</template>

<script>
import OrderFormCustomerPdfContent from "@/components/invoice/order-form-customer/OrderFormCustomerPdfContent";
import OrderFormCustomerPreviewHeader from "@/components/invoice/order-form-customer/preview/OrderFormCustomerPreviewHeader.vue";
import OrderFormCustomerPreviewTable from "@/components/invoice/order-form-customer/preview/OrderFormCustomerPreviewTable.vue";
import OrderFormCustomerPreviewFooter from "@/components/invoice/order-form-customer/preview/OrderFormCustomerPreviewFooter.vue";
import OrderFormCustomerPreviewTermsAndConditions from "@/components/invoice/order-form-customer/preview/OrderFormCustomerPreviewTermsAndConditions.vue";
import { mapGetters, mapActions } from "vuex";
import { statusToUpperCaseTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { statusCheck } from "@/types/api-orisis/enums/enums";

export default {
  data() {
    return {
      statusCheck,
    };
  },
  computed: {
    ...mapGetters([
      "orderFormCustomer",
      "orderFormCustomerPDF",
      "isLoadingOrderFormCustomerPDF",
      "isValidatingOrderFormCustomer",
      "institutionSettingsActive",
      "isLoadingOrderFormCustomer",
      "orderFormCustomerOption",
    ]),
    venteSettingBackgroundDocuments() {
      return this.moduleParametersList.find(
        (el) => el.key == "backgroundDocuments"
      )
        ? this.moduleParametersList.find(
            (el) => el.key == "backgroundDocuments"
          ).value
        : false;
    },
    heightFooter() {
      return "100";
    },
  },
  components: {
    OrderFormCustomerPdfContent,
    OrderFormCustomerPreviewHeader,
    OrderFormCustomerPreviewTable,
    OrderFormCustomerPreviewFooter,
    OrderFormCustomerPreviewTermsAndConditions,
  },
  methods: {
    statusToUpperCaseTranslate,
    htmlEntities(str) {
      return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
    pageFooterHeight() {
      var count = this.orderFormCustomer.footer
        ? ((this.orderFormCustomer.footer.match(/\<p/g) || []).length +
            (this.orderFormCustomer.footer.match(/\<br/g) || []).length +
            (this.orderFormCustomer.footer.match(/\<\/br/g) || []).length) *
          12
        : 0;
      if (count > 50) {
        return count;
      } else {
        return 50;
      }
    },
    decodeHtmlEntities(str) {
      var element = document.createElement("div");
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }
      return str;
    },
  },
};
</script>
