<template lang="pug"> 
  quill-editor(ref="quillEditor" v-if='(editFocus || mouseOn) && !disabled' @focus="focusFunction" @blur="blurFunction(false)" :disabled="line.isDisabled" :class="[editFocus && mouseOn ? 'edit' : 'no-edit', editFocus? 'focus' : '', line.isDisabled ? 'disabled' :'']" v-model="line['description']" :options="editorOption")
  div(v-else style="padding: 5px !important")
    span(class="v-html-no-padding" v-html="line['description']")
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      lineOrigine: {},
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
    },
    mouseOn: {
      type: Boolean,
    },
    disabled: {
      default: false,
    },
  },
  created() {
    this.lineOrigine = JSON.parse(JSON.stringify(this.line["description"]));
  },
  methods: {
    ...mapActions(["updateOrderFormCustomerDetail"]),
    blurFunction() {
      this.$emit("emitEditFocus", false);
      if (
        JSON.parse(JSON.stringify(this.line["description"])) !==
        this.lineOrigine
      ) {
        this.updateOrderFormCustomerDetail({
          orderFormCustomerDetail: this.line,
        });
      }
    },
    focusFunction() {
      this.$emit("emitEditFocus", true);
    },
  },
  components: {
    quillEditor,
  },
};
</script>
