<template lang="pug">
  .row
    .document-table.col-12
      OrderFormCustomerHeader()
      draggable(v-if="getOrderFormCustomerDetails.length" 
        :list="getOrderFormCustomerDetails" 
        handle=".handle" 
        :move="onMove"
        @start="setPostionIndex"
        @end="endDrag" 
      ) 
        orderFormCustomerDetails(:disabled="disabled" v-for="(line, index) in getOrderFormCustomerDetails" :indexToFocus="indexToFocus" :key="index" :line="line" :index="index" :class="[isLasWorkElement(line, index) ? 'isLasWorkElement' : '']" :parendIdHide="parendIdHide" )
      div(v-else).mt-2.p-1.text-center.text-muted
        | Cliquez sur un des boutons ci-dessous pour ajouter des lignes à votre document
</template>

<script>
import OrderFormCustomerHeader from '@/components/invoice/order-form-customer/builder/table/OrderFormCustomerHeader.vue'
import orderFormCustomerDetails from '@/components/invoice/order-form-customer/builder/table/OrderFormCustomerDetails.vue'
import { EDocumentTypeLine, EInputType } from '@/types/api-orisis/enums/enums'
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      parendIdHide: 0,
      position: 0,
      indexToFocus: 0,
    }
  },
  computed: {
    ...mapGetters(['getOrderFormCustomerDetails', 'isLoadingOrderFormCustomerDetails', 'orderFormCustomer']),
  },
  props: {
    disabled: {
      default: false
    }
  },
  methods: {
    ...mapActions(["reviewIndexOrderFormCustomer"]),
    isLasWorkElement(line, index) {
      if(this.getOrderFormCustomerDetails[index+1] && this.getOrderFormCustomerDetails[index+1].parentId!==line.parentId && (line.type==14 || line.type==15 || line.type==16 || line.type==17 || line.type==18)){
        return true;
      } else if (!this.getOrderFormCustomerDetails[index+1] && (line.type==14 || line.type==15 || line.type==16 || line.type==17 || line.type==18)){
        return true;
      } else {
        return false;
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      const beforeDragElementUp = relatedContext.list[relatedContext.index-1] ? relatedContext.list[relatedContext.index-1] : false;
      const afterDragElementDown = relatedContext.list[relatedContext.index+1] ? relatedContext.list[relatedContext.index+1] : false;
      const simpleElement = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 , 11, 12, 13];
      const workElement = [14, 15, 16, 17, 18];

      if (draggedElement.type == 6 && this.getOrderFormCustomerDetails.filter(el=>el.parentId == draggedElement.id).length > 0) {
        // Action qui permet de cacher les éléments enfants d'un ouvrage
        this.parendIdHide = draggedElement.id;
      }
      if (this.position > relatedContext.index) {
        // si je drag un simpleElement je peux me placer si le beforeDragElementTypeUp est un workElement et que relatedElement.type est un simpleElement
        if (simpleElement.includes(draggedElement.type) && simpleElement.includes(relatedElement.type) && workElement.includes(beforeDragElementUp.type)) {
          this.position = relatedContext.index;
          return true;
        // si je drag un simpleElement je peux me placer si le beforeDragElementTypeUp est un simpleElement et que relatedElement.type est un simpleElement
        } else if (simpleElement.includes(draggedElement.type) && simpleElement.includes(relatedElement.type) && simpleElement.includes(beforeDragElementUp.type)) {
          this.position = relatedContext.index;
          return true;
        // si je drag un simpleElement je peux me placer si le beforeDragElementTypeUp est égal à false et que relatedElement.type est un simpleElement
        } else if (simpleElement.includes(draggedElement.type) && simpleElement.includes(relatedElement.type) && !beforeDragElementUp) {
          this.position = relatedContext.index;
          return true;
        // si je drag un workElement je peux me placer si le beforeDragElementTypeUp est un workElement et qu'il ai le meme parentId que le drag et que relatedElement.type est un workElement et qu'il ai le meme parentId que le drag
        } else if (workElement.includes(draggedElement.type) && workElement.includes(relatedElement.type) && workElement.includes(beforeDragElementUp.type) && draggedElement.parentId == relatedElement.parentId && draggedElement.parentId == beforeDragElementUp.parentId) {
          this.position = relatedContext.index;
          return true;
        // si je drag un workElement je peux me placer si le beforeDragElementTypeUp est un simpleElement et qu'il ai l'id égal au parentId de l'element drag et que relatedElement.type est un workElement et qu'il ai le meme parentId que le drag
        } else if (workElement.includes(draggedElement.type) && workElement.includes(relatedElement.type) && simpleElement.includes(beforeDragElementUp.type) && draggedElement.parentId == beforeDragElementUp.id && draggedElement.parentId == relatedElement.parentId) {
          this.position = relatedContext.index;
          return true;
        } else {
          return false;
        }
      } else if (this.position < relatedContext.index) {
        // si je drag un simpleElement je peux me placer si le afterDragElementDown est un worlElement et que relatedElement.type est un simpleElement
        if (simpleElement.includes(draggedElement.type) && simpleElement.includes(afterDragElementDown.type) && workElement.includes(relatedElement.type)) {
          this.position = relatedContext.index;
          return true;
        // si je drag un simpleElement je peux me placer si le afterDragElementDown est un simpleElement et que relatedElement.type est un simpleElement
        } else if (simpleElement.includes(draggedElement.type) && simpleElement.includes(afterDragElementDown.type) && simpleElement.includes(relatedElement.type)) {
          this.position = relatedContext.index;
          return true;
        // si je drag un simpleElement je peux me placer si le afterDragElementDown est égal à false et que relatedElement.type est un simpleElement ou un workElement
        } else if (simpleElement.includes(draggedElement.type) && !afterDragElementDown && (simpleElement.includes(relatedElement.type) || workElement.includes(relatedElement.type))) {
          this.position = relatedContext.index;
          return true;
        // si je drag un workElement je peux me placer si le afterDragElementDown est un workElement et qu'il ai le meme parentId que le drag et que relatedElement.type est un workElement et qu'il ai le meme parentId que le drag
        } else if (workElement.includes(draggedElement.type) && workElement.includes(afterDragElementDown.type) && workElement.includes(relatedElement.type) && draggedElement.parentId == relatedElement.parentId && draggedElement.parentId == afterDragElementDown.parentId) {
          this.position = relatedContext.index;
          return true;
        // si je drag un workElement je peux me placer si le afterDragElementDown est un simpleElement  et que relatedElement.type est un workElement et qu'il ai le meme parentId que le drag
        } else if (workElement.includes(draggedElement.type) && simpleElement.includes(afterDragElementDown.type) && workElement.includes(relatedElement.type) && draggedElement.parentId == relatedElement.parentId) {
          this.position = relatedContext.index;
          return true;
        } else {
          return false;
        }
      } else {
        // je drag un simpleElement je peux me placer si relatedElement.type est un simpleElement et que le afterDragElementDown est un simpleElement
        if (simpleElement.includes(draggedElement.type) && simpleElement.includes(relatedElement.type) && simpleElement.includes(afterDragElementDown.type)) {
          this.position = relatedContext.index;
          return true;
        // je drag un workElement je peux me placer si relatedElement.type est un workElement et que le afterDragElementDown est un workElement et qu'il ai le meme parentId que le drag
        } else if (workElement.includes(draggedElement.type) && workElement.includes(relatedElement.type) && workElement.includes(afterDragElementDown.type) && draggedElement.parentId == relatedElement.parentId && draggedElement.parentId == afterDragElementDown.parentId) {
          this.position = relatedContext.index;
          return true;
        } else {
          return false;
        }
      }
    },
    setPostionIndex(e){
      this.position = e.oldIndex;
    },
    endDrag(e){
      this.parendIdHide = 0;
      this.$nextTick(() => {
        if (e.item._underlying_vm_.type == 6) {
          // Traitement spéciale pour le déplacement des ouvrage
          let newPositionChildElement = 0;
          let childElements = this.getOrderFormCustomerDetails.filter(el=>el.parentId == e.item._underlying_vm_.id);
          if (e.newIndex > e.oldIndex) {
            newPositionChildElement = e.newIndex;
          } else {
            newPositionChildElement = e.newIndex + 1;
          }
          if (childElements && childElements.length > 0) {
            // On boucle les element enfants
            for (let index = 0; index < childElements.length; index++) {
              const childElement = childElements[index];

              const positionChildElement = this.getOrderFormCustomerDetails.findIndex(el=>el.id==childElement.id);
              this.getOrderFormCustomerDetails.splice(positionChildElement, 1);
              
              this.getOrderFormCustomerDetails.splice(newPositionChildElement,0,childElement);
            }
          }
          this.reviewIndexOrderFormCustomer();
        }else{
          this.reviewIndexOrderFormCustomer();
        }
        this.$nextTick(() => {
          this.indexToFocus = e.newIndex;
        })
      })
    },
  },
  components: {
    OrderFormCustomerHeader,
    orderFormCustomerDetails,
    draggable
  }
}
</script>

<style>

.maskDocumentDetailsLoading {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

</style>