<template lang="pug">
.builder.pb-4
  .builder-area
    OrderTrackingCustomerHeader(:disabled="disabled")
    OrderTrackingCustomerTable(:disabled="disabled" )
    OrderTrackingCustomerFooter(:disabled="disabled")
</template>

<script>
import OrderTrackingCustomerHeader from "@/components/invoice/order-form-customer/builder/OrderTrackingCustomerHeader.vue";
import OrderTrackingCustomerTable from "@/components/invoice/order-form-customer/builder/OrderTrackingCustomerTable.vue";
import OrderTrackingCustomerFooter from "@/components/invoice/order-form-customer/builder/OrderTrackingCustomerFooter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    OrderTrackingCustomerHeader,
    OrderTrackingCustomerTable,
    OrderTrackingCustomerFooter,
  },
  computed: {
    ...mapGetters(["orderFormCustomer"]),
    orderTrackingCustomer: {
      get() {
        return this.$store.getters.orderTrackingCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    orderTrackingCustomerDetails: {
      get() {
        return this.$store.getters["getOrderTrackingCustomerDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_DETAILS", value);
      },
    },
    disabled() {
      return this.orderFormCustomer.status >= 3;
    },
    deduction: {
      get() {
        return this.$store.getters["deduction"];
      },
      set(value) {
        return this.$store.commit("SET_DEDUCTION", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateOrderTrackingCustomer"]),
    saveAction(bool) {
      this.updateOrderTrackingCustomer({
        orderTrackingCustomer: this.orderTrackingCustomer,
        loading: bool,
      });
    },
  },
};
</script>

<style scoped>
#invoiceContainer {
  height: calc(100% - 23px) !important;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.builder-area {
  /* margin-top: 20px; */
  padding: 20px;
  background-color: white !important;
  border: solid 2px #f1f1f1;
}
</style>
