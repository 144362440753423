<template lang="pug">
    .row 
      div.col-md-6.mt-2
        PaymentConditions(:disabled="disabled")
      div.col-md-5.offset-md-1.mt-2(v-if='!orderFormCustomer.isHideTotalPrice')
        AddGlobalDiscount(v-if="!disabled")
        TotalOrderFormCustomer(:disabled="disabled" @updateTvaDetails='updateTvaDetails' :canEditDiscount='true' ref="TotalOrderFormCustomer")
        TotalTva.mt-2
</template>
<script>
import PaymentConditions from "@/components/invoice/order-form-customer/builder/PaymentConditions";
import TotalOrderFormCustomer from "@/components/invoice/order-form-customer/builder/TotalOrderFormCustomer";
import TotalTva from "@/components/invoice/order-form-customer/builder/TotalTva";
import AddGlobalDiscount from "@/components/invoice/order-form-customer/builder/AddGlobalDiscount";
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tvaDetails: []
    }
  },
  props: {
    disabled: {
      default: false
    }
  },
  computed: {
    ...mapGetters(['orderFormCustomer'])
  },
  methods: {
    updateTvaDetails(val) {
      this.tvaDetails = val;
    }
  },
  components: {
    PaymentConditions,
    TotalOrderFormCustomer,
    TotalTva,
    AddGlobalDiscount
  },
};
</script>