<template lang="pug">
    div(v-if="!preview || (preview && deduction.holdbackNumber>0)")
        div(v-if="editHoldback")
            span Retenue de garantie :
        div.mb-1(v-if="isHoldback && !editHoldback") 
            span(@mouseover="showTools = true" @mouseleave="showTools = false")  Retenue de garantie de {{ formatPercent(deduction.holdbackPercent) }} soit {{ formatCurrency(deduction.holdbackNumber) }} TTC  due au {{ formattedDate }}
                feather-icon.cursor-pointer.feather-icon-holdback.ml-1.feather(v-if="!preview && showTools && !disabled" icon="Edit3Icon" size="18" @click='updateHoldback' v-b-tooltip.hover.top="'Modifier'") 
                feather-icon.cursor-pointer.feather(v-if="!preview && showTools && !disabled" icon="XIcon" size="18" @click='deleteHoldback' v-b-tooltip.hover.top="'Retirer la prime'")
        div.mb-1.mt-1.d-flex(v-if='editHoldback && !preview')
            .d-flex.align-items-center
                input.input-number-holdback.form-control.form-control-sm.mr-1(@blur="changeValueByPercent" placeholder='5' v-model='percent' type="number" min='0' max='5') 
                div(style='min-width: fit-content;') % soit 
                input.input-number-holdback.form-control.form-control-sm.ml-1.mr-1(@blur="changePercentByValue" v-model='number' type="number" min='0') 
                div(style='min-width: fit-content;')  € TTC due au                
                date-range-picker.datepicker-holdback.form-control-sm.ml-1.p-0(ref="picker" :showDropdowns='true' id="dates" style="width: 100%" :show-week-numbers="true" :timePicker="false" :single-date-picker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="datePicker" :auto-apply="true")
                b-button.form-control-sm.p-0.ml-1(v-if="!disabled" style="padding:0px 5px !important" variant="primary" type="small" @click="saveHoldback") 
                    | Enregistrer
                b-button.form-control-sm.p-0.ml-1(v-if="!disabled" style="padding:0px 5px !important" variant="outline-primary" @click="initDataHoldback") 
                    feather-icon(icon='XIcon' size="20")
</template>
<script>
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  formatCurrency,
  formatPercent,
} from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Aujourd'hui": [new Date(dayjs()), new Date(dayjs())],
  Demain: [new Date(dayjs().add(1, "day")), new Date(dayjs().add(1, "day"))],
  Hier: [
    new Date(dayjs().subtract(1, "day")),
    new Date(dayjs().subtract(1, "day")),
  ],
  "Dans 1 mois": [
    new Date(dayjs().add(1, "month")),
    new Date(dayjs().add(1, "month")),
  ],
};

export default {
  data() {
    return {
      showTools: false,
      config: config,
      defaultRange: defaultRange,
      minDate: new Date(),
      number: null,
      date: new Date(dayjs().add(1, "year")),
      percent: null,
    };
  },
  props: {
    preview: {
      default: false,
    },
    editHoldback: {
      type: Boolean,
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  components: {
    "v-select": vSelect,
    BButton,
    DateRangePicker,
  },
  computed: {
    ...mapGetters(["orderFormCustomer"]),
    deduction: {
      get() {
        return this.$store.getters["deduction"];
      },
      set(value) {
        return this.$store.commit("SET_DEDUCTION", value);
      },
    },
    datePicker: {
      get() {
        return {
          startDate:
            this.date == null
              ? new Date(dayjs().add(1, "year"))
              : dayjs(this.date),
          endDate:
            this.date == null
              ? new Date(dayjs().add(1, "year"))
              : dayjs(this.date),
        };
      },
      set(value) {
        this.date = new Date(value.startDate);
      },
    },
    formattedDate() {
      return dayjs(this.deduction.holdbackDate).format("DD/MM/YYYY");
    },
    isHoldback() {
      return this.deduction
        ? this.deduction.holdbackNumber != null ||
            this.deduction.holdbackPercent != null
        : true;
    },
  },
  methods: {
    ...mapActions(["createDeduction", "updateDeduction"]),
    formatCurrency,
    formatPercent,
    changePercentByValue(res) {
      if (res.target.value < 0) {
        this.number = 0;
        this.percent =
          Math.round(
            (this.number / this.orderFormCustomer.totalTtc) * 100 * 100
          ) / 100;
      } else {
        this.percent =
          Math.round(
            (this.number / this.orderFormCustomer.totalTtc) * 100 * 100
          ) / 100;
      }
    },
    changeValueByPercent(res) {
      if (res.target.value < 0) {
        this.percent = 0;
        this.number =
          Math.round(
            (this.percent / 100) * this.orderFormCustomer.totalTtc * 100
          ) / 100;
      } else if (res.target.value > 5) {
        this.percent = 5;
        this.number =
          Math.round(
            (this.percent / 100) * this.orderFormCustomer.totalTtc * 100
          ) / 100;
      } else {
        this.number =
          Math.round(
            (this.percent / 100) * this.orderFormCustomer.totalTtc * 100
          ) / 100;
      }
    },
    saveHoldback() {
      if (this.number && this.date) {
        this.deduction = {
          ...this.deduction,
          holdbackPercent: parseFloat(this.percent),
          holdbackNumber: parseFloat(this.number),
          holdbackDate: new Date(this.date).toISOString(),
          quoteId: null,
          invoiceId: null,
          creditId: null,
          orderFormCustomerId: this.orderFormCustomer.id,
        };
        if (this.deduction.id == 0) {
          this.createDeduction({ deduction: this.deduction });
        } else {
          this.updateDeduction({ deduction: this.deduction });
        }
        this.$emit("toggleHoldback");
      }
    },
    initDataHoldback() {
      this.number = null;
      this.percent = null;
      this.date = new Date(dayjs().add(1, "year"));
      this.$emit("toggleHoldback");
    },
    updateHoldback() {
      this.number = this.deduction.holdbackNumber;
      this.percent = this.deduction.holdbackPercent;
      this.date = this.deduction.holdbackDate;
      this.$emit("toggleHoldback", true);
    },
    deleteHoldback() {
      this.deduction.holdbackNumber = this.number = null;
      this.deduction.holdbackPercent = this.percent = null;
      this.deduction.holdbackDate = this.date = new Date();
      this.updateDeduction({ deduction: this.deduction });
    },
  },
};
</script>

<style scoped>
.input-number-holdback {
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  width: 50px;
  min-width: 50px;
  height: 30px;
  border-radius: 0.357rem;
}

.feather-icon-holdback {
  margin-right: 5px;
}

.datepicker-holdback {
  height: 30px !important;
  width: 205px !important;
}
</style>

<style>
.datepicker-holdback > .text-break {
  padding: 0rem 1rem !important;
}
.form-control-sm .form-control {
  height: 2.142rem;
  font-size: 0.857rem;
  line-height: 1;
}
</style>
