<template lang="pug">
  div(v-if="!preview" style='max-width:400px')
    div.d-flex(:class="edit ? 'justify-content-between' : ''")
      span {{text}}
        span(v-if="orderFormCustomer.limitDate != null" @mouseover="showTools = true" @mouseleave="showTools = false") {{ begginingWorkDate }} 
          span.cursor-pointer.bold(v-if="showTools && !disabled") 
            feather-icon(icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="'Modifier la date de validitée'") 
            a(v-if="orderFormCustomer[keyValue] == null" @click="edit = !edit") définir
      span.cursor-pointer.text-primary(v-if="edit"  @click="edit = false") Fermer
    div.mb-1.d-flex(v-if="edit")
      div.w-100
        v-select#validityQuote(@input="changeDate" :clearable="false" :loading='isLoadingDocumentValidityList' :deselectFromDropdown='true' :closeOnSelect='true' v-model='orderFormCustomer.validityDocument' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="\
          documentValidityList\
          " :reduce='elem => elem.value')
            template(v-slot:no-options='')
              template  Aucun r&eacute;sultat trouv&eacute;
    div.mb-1.d-flex(v-if="edit")
      div.w-100
        date-range-picker(ref="picker" :showDropdowns='true' id="dates" style="width: 100%" :show-week-numbers="true" :timePicker="false" :single-date-picker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="datePicker" :auto-apply="true")
  div(v-else)
    div.d-flex
      span {{ text }} {{ begginingWorkDate }} 
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Aujourd'hui": [new Date(dayjs()), new Date(dayjs())],
  Demain: [new Date(dayjs().add(1, "day")), new Date(dayjs().add(1, "day"))],
  Hier: [
    new Date(dayjs().subtract(1, "day")),
    new Date(dayjs().subtract(1, "day")),
  ],
  "Dans 1 mois": [
    new Date(dayjs().add(1, "month")),
    new Date(dayjs().add(1, "month")),
  ],
};

export default {
  components: {
    DateRangePicker,
    "v-select": vSelect,
  },
  props: {
    preview: {
      default: false,
    },
    text: {
      type: String,
      default: "Durée :",
    },
    keyValue: {
      type: String,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      config: config,
      defaultRange: defaultRange,
      edit: false,
      showTools: false,
    };
  },
  created() {
    if (!this.documentValidityList || this.documentValidityList.length == 0) {
      this.getDocumentValidityEnum();
    }
  },
  methods: {
    ...mapActions(["getDocumentValidityEnum"]),
    changeDate(value) {
      let date = new Date(this.orderFormCustomer.documentDate);
      if (value == 0) {
        this.orderFormCustomer.limitDate = date.toISOString();
      } else {
        this.orderFormCustomer.limitDate = dayjs(
          this.orderFormCustomer.documentDate
        )
          .add(value, "day")
          .toISOString();
      }
      this.edit = false;
      this.$emit("setValue");
    },
  },
  computed: {
    ...mapGetters(["documentValidityList", "isLoadingDocumentValidityList"]),
    begginingWorkDate() {
      return dayjs(this.orderFormCustomer.limitDate).format("DD/MM/YYYY");
    },
    datePicker: {
      get() {
        return {
          startDate: dayjs(this.orderFormCustomer.limitDate),
          endDate: dayjs(this.orderFormCustomer.limitDate),
        };
      },
      set(value) {
        this.orderFormCustomer.limitDate = new Date(value.startDate);
        this.orderFormCustomer.validityDocument = 0;
        this.$emit("setValue");
        this.edit = false;
      },
    },
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
  },
};
</script>
