<template lang="pug">
div(v-if="orderFormCustomerHeader[dataKey].inputType == 1") 
  TextCol(:disabled="disabled" @emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus")
div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 2") 
  NumberCol(:disabled="disabled" @emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus")
div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 10") 
  SelectCol(:disabled="disabled" @emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus") 
div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 23") 
  MarginCol(:disabled="disabled" @emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus")
div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 24") 
  SelectCatalog(:disabled="disabled" @emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index") 
</template>

<script>
import SelectCol from '@/components/invoice/order-form-customer/builder/table/cols/SelectCol.vue'
import TextCol from '@/components/invoice/order-form-customer/builder/table/cols/TextCol.vue'
import NumberCol from '@/components/invoice/order-form-customer/builder/table/cols/NumberCol.vue'
import MarginCol from '@/components/invoice/order-form-customer/builder/table/cols/MarginCol.vue'
import SelectCatalog from '@/components/invoice/order-form-customer/builder/table/cols/SelectCatalog.vue'

export default {
  data () {
    return {}
  },
  props: {
    mouseOn: {
      type: Boolean,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    orderFormCustomerHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    },
    editFocus: {
      required: true
    },
    index: {
      type: Number
    },
    disabled: {
      default: false
    }
  },
  methods: {
    changeEditFocus(res) {
      this.$emit("emitEditFocus", res);
    }
  },
  components: {
    SelectCol,
    TextCol,
    NumberCol,
    MarginCol,
    SelectCatalog
  }
}
</script>