<template lang="pug">
div
    div
        div.d-flex(v-if="!preview || (preview && (orderFormCustomerDownPaymentsRequestsList.length>0 || deduction.holdbackNumber>0 || deduction.punctualDeductions.length>0 || deduction.prorataAccountPrice>0 || orderFormCustomerPaymentMethods.length>0 || orderFormCustomer.bankAccountId!==null))")
            h4 Conditions de paiement
            AddPaymentCondition(v-if="!preview && !disabled" @setPaymentCondition='setPaymentCondition').ml-2
        OrderFormCustomerDownPaymentsRequest(:disabled="disabled" :preview="preview" :editOrderFormCustomerDownPaymentRequest='editOrderFormCustomerDownPaymentRequest' @toggleOrderFormCustomerDownPaymentsRequest='toggleOrderFormCustomerDownPaymentsRequest')
        Holdback(:disabled="disabled" :preview="preview" :editHoldback='editHoldback' @toggleHoldback='toggleHoldback')
        PunctualDeduction(:disabled="disabled" :preview="preview" :editPunctualDeduction='editPunctualDeduction' @togglePunctualDeduction='togglePunctualDeduction')
        ProrataAccount(:disabled="disabled" :preview='preview' :editProrataAccount="editProrataAccount" @toggleProrataAccount='toggleProrataAccount')
        Review(:disabled="disabled" :isReview="isReview" :preview="preview" :editReview='editReview' @toggleReview='toggleReview')
        PaymentMethodChoice(:disabled="disabled" :preview="preview")
        BankAccountChoice(:disabled="disabled" @setValue="setBankAccount" :preview="preview" v-if="!orderFormCustomerOption.hideBankAccount").mb-1
        div.mt-1(v-if='!orderFormCustomer.hideWasteCollection && orderFormCustomer.type != 1')
            WasteManagement(:disabled="disabled" :preview="preview")
    BottomNotes(:disabled="disabled" :preview="preview")
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PaymentMethodChoice from "@/components/invoice/order-form-customer/builder/PaymentMethodChoice";
import BankAccountChoice from "@/components/invoice/order-form-customer/builder/BankAccountChoice";
import AddPaymentCondition from "@/components/invoice/order-form-customer/builder/AddPaymentCondition";
import WasteManagement from "@/components/invoice/order-form-customer/builder/WasteManagement";
import OrderFormCustomerDownPaymentsRequest from "@/components/invoice/order-form-customer/builder/OrderFormCustomerDownPaymentsRequest";
import Holdback from "@/components/invoice/order-form-customer/builder/Holdback";
import PunctualDeduction from "@/components/invoice/order-form-customer/builder/PunctualDeduction";
import ProrataAccount from "@/components/invoice/order-form-customer/builder/ProrataAccount";
import BottomNotes from "@/components/invoice/order-form-customer/builder/BottomNotes.vue";
import Review from "@/components/invoice/order-form-customer/builder/Review";

export default {
  components: {
    PaymentMethodChoice,
    BankAccountChoice,
    OrderFormCustomerDownPaymentsRequest,
    AddPaymentCondition,
    WasteManagement,
    Holdback,
    PunctualDeduction,
    ProrataAccount,
    BottomNotes,
    Review,
  },
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      editHoldback: false,
      editOrderFormCustomerDownPaymentRequest: false,
      editPunctualDeduction: false,
      editReview: false,
      editProrataAccount: false,
      isReview: false,
    };
  },
  computed: {
    ...mapGetters([
      "orderFormCustomerDownPaymentsRequestsList",
      "deduction",
      "paymentMethodsList",
      "orderFormCustomerPaymentMethods",
      "orderFormCustomerOption",
    ]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateOrderFormCustomer"]),
    setBankAccount(bank) {
      if (bank.id == null || bank.id == 0 || !bank) {
        this.orderFormCustomer = {
          ...this.orderFormCustomer,
          bankAccountId: null,
          bankAccountLabel: null,
          bankAccountName: null,
          bankAccountIban: null,
          bankAccountBic: null,
        };
        this.saveAction();
      } else {
        this.orderFormCustomer = {
          ...this.orderFormCustomer,
          bankAccountId: bank.id,
          bankAccountLabel: bank.label,
          bankAccountName: bank.name,
          bankAccountIban: bank.iban,
          bankAccountBic: bank.bic,
        };
        this.saveAction();
      }
    },
    saveAction() {
      this.updateOrderFormCustomer({
        orderFormCustomer: this.orderFormCustomer,
        loading: false,
      });
    },
    setPaymentCondition({ type, isReview = false }) {
      this.isReview = isReview;
      switch (type) {
        case "downPaymentRequest":
          this.editOrderFormCustomerDownPaymentRequest = true;
          break;
        case "holdback":
          this.editHoldback = true;
          break;
        case "punctualDeduction":
          this.editPunctualDeduction = true;
          break;
        case "prorata":
          this.editProrataAccount = true;
          break;
        case "review":
          this.editReview = true;
          break;
        default:
          break;
      }
    },
    toggleHoldback(res = false) {
      this.editHoldback = res;
    },
    toggleOrderFormCustomerDownPaymentsRequest(res = false) {
      this.editOrderFormCustomerDownPaymentRequest = res;
    },
    togglePunctualDeduction(res = false) {
      this.editPunctualDeduction = res;
    },
    toggleProrataAccount(res = false) {
      this.editProrataAccount = res;
    },
    toggleReview(res = false) {
      this.editReview = res;
    },
  },
};
</script>
