<template lang="pug">
  div
    div(v-if="!preview" @mouseover="showTools = true" @mouseleave="showTools = false")
      div.d-flex(:class="edit ? 'justify-content-between' : ''")
        span.text-primary.font-weight-bold Votre contact :
        span.pr-1.cursor-pointer.text-primary(v-if="edit && !disabled" @click='saveUserInfo') Enregistrer
        span.pl-1.cursor-pointer.bold( v-if="showTools && !edit && !disabled") 
          feather-icon(icon="Edit3Icon" size="18"  @click="edit=true") 
      div(v-if="edit")
        div.mb-1
          b-form-group.pr-1(label-for='userId' style='flex:1')
            validation-provider(#default='{ errors }' name='userId' rules="required")
              v-select#userId(:loading='isLoadingCollaboratorsList' :clearable='false' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='orderFormCustomer.userId' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="\
                collaboratorsList.filter((collab) => collab.userId)\
                " :get-option-label="(collab) => collab.displayLabel" :reduce="(elem) => elem.userId" @input="setUserValue")
                    template(v-slot:no-options='')
                        template  Aucun r&eacute;sultat trouv&eacute;
        div.mb-1
          b-form-group.pr-1(label-for='userMail' style='flex:1')
            validation-provider(#default='{ errors }' name='userMail')
              b-form-input(placeholder="Email" v-model="orderFormCustomer.userMail")
        div.mb-1.d-flex
          div.w-50
            b-form-group.pr-1(label-for='userPhoneNumber' style='flex:1')
              validation-provider(#default='{ errors }' name='userPhoneNumber')
                b-form-input(placeholder="Téléphone principal" v-model="orderFormCustomer.userPhoneNumber")
          div.w-50
            b-form-group.pr-1(label-for='userSecondaryPhoneNumber' style='flex:1')
              validation-provider(#default='{ errors }' name='userSecondaryPhoneNumber')
                b-form-input(placeholder="Téléphone secondaire" v-model="orderFormCustomer.userSecondaryPhoneNumber")
      div(v-else)
        div
          span.font-weight-bold {{ orderFormCustomer.userFirstName }} {{ orderFormCustomer.userLastName }}
        div
          span {{ orderFormCustomer.userMail ? "E-mail : " + orderFormCustomer.userMail : "" }}
        div
          span {{ orderFormCustomer.userPhoneNumber ? "Tél : " + orderFormCustomer.userPhoneNumber : "" }}
        div
          span {{ orderFormCustomer.userSecondaryPhoneNumber ? "Tél secondaire: " + orderFormCustomer.userSecondaryPhoneNumber : "" }}
    div(v-else)
      div
        span.font-weight-bold {{ orderFormCustomer.userFirstName }} {{ orderFormCustomer.userLastName }}
      div
        span {{ orderFormCustomer.userMail ? "E-mail : " + orderFormCustomer.userMail : "" }}
      div
        span {{ orderFormCustomer.userPhoneNumber ? "Tél : " + orderFormCustomer.userPhoneNumber : "" }}
      div
        span {{ orderFormCustomer.userSecondaryPhoneNumber ? "Tél secondaire: " + orderFormCustomer.userSecondaryPhoneNumber : "" }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BFormInput, BFormGroup, BButton } from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import vSelect from "vue-select";
import { mask } from "vue-the-mask";

export default {
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      showTools: false,
    };
  },
  async created() {
    await this.getCollaborators({});
  },
  computed: {
    ...mapGetters(["isLoadingCollaboratorsList", "collaboratorsList"]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateOrderFormCustomer", "getCollaborators"]),
    setUserValue(value) {
      let collab = this.collaboratorsList.find((el) => el.userId == value);
      if (collab) {
        this.orderFormCustomer.userLastName = collab.lastName;
        this.orderFormCustomer.userFirstName = collab.firstName;
        this.orderFormCustomer.userPhoneNumber = collab.phoneNumber;
        this.orderFormCustomer.userMail = collab.email;
      }
    },
    saveUserInfo() {
      this.edit = false;
      this.updateOrderFormCustomer({
        orderFormCustomer: this.orderFormCustomer,
        loading: false,
      });
    },
  },
  directives: {
    mask,
  },
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style>
.content-user-info {
}
</style>
