<template lang="pug"> 
  div(v-if="orderFormCustomerHeader[dataKey].inputType == 1") 
    TextCol(:disabled="disabled" @emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :edit="edit" :editFocus="editFocus")
  div(v-else-if="orderFormCustomerHeader[dataKey].inputType == 25")  
    TitleCol(:disabled="disabled" @emitEditFocus="emitEditFocus" :line="line" :dataKey="dataKey" :orderFormCustomerHeader="orderFormCustomerHeader" :canEdit="true" :edit="edit" :editFocus="editFocus")
</template>
<script>
import TitleCol from '@/components/invoice/order-form-customer/builder/table/cols/TitleCol.vue'
import TextCol from '@/components/invoice/order-form-customer/builder/table/cols/TextCol.vue'

export default {
  data () {
    return {}
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    orderFormCustomerHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    },
    editFocus: {
      type: Boolean,
      required: true
    },
    disabled: {
      default: false
    }
   
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    }
  },
  components: {
    TitleCol,
    TextCol
  }
}
</script>
