<template lang="pug">
table.documentDetails   
    thead
        OrderTrackingCustomerPreviewHeader(:preview="preview")
    tbody 
        //OrderFormCustomerPreviewDetails(v-for="(line, index) in getOrderFormCustomerDetails" :key="index" :line="line" :index="index")
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import OrderTrackingCustomerPreviewHeader from '@/components/invoice/order-form-customer/preview/table/OrderTrackingCustomerPreviewHeader.vue'
import OrderFormCustomerPreviewDetails from '@/components/invoice/order-form-customer/preview/table/OrderFormCustomerPreviewDetails.vue'

export default {
    props: {
        preview: {
            type:String,
            default: ''
        }
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapGetters(['getOrderFormCustomerDetails']),
    },
    components: {
        OrderTrackingCustomerPreviewHeader,
        OrderFormCustomerPreviewDetails
    }
}
</script>
