<template lang="pug">
div(v-if="preview && orderFormCustomerPaymentMethods.length>0")
  span.d-flex(v-if="orderFormCustomerPaymentMethods.length>0" style='flex-wrap: wrap;')
    span(style="margin-right:5px") Méthode{{ orderFormCustomerPaymentMethods.length>1 ? 's' : '' }} de paiement acceptée{{ orderFormCustomerPaymentMethods.length>1 ? 's' : '' }} :
    span(style="margin-right:5px" v-for="(item, index) in orderFormCustomerPaymentMethods", :key="index") {{ paymentMethodTranslate(item) }}{{ orderFormCustomerPaymentMethods.length - 1 == index ? '.' : ',' }}
div(v-else-if="!preview")
  div.mb-1(v-if="paymentMethodsList.length>0")
    span.d-flex(style='flex-wrap: wrap;') Méthode{{ orderFormCustomerPaymentMethods.length>1 ? 's' : '' }} de paiement acceptée{{ paymentMethodsList.length>1 ? 's' : '' }} :
      .span(v-for="(item, index) in orderFormCustomerPaymentMethods", :key="index") &nbsp {{ paymentMethodTranslate(item) }}{{ orderFormCustomerPaymentMethods.length - 1 == index ? '.' : ',' }}
      span.no-printting.text-primary.ml-1.cursor-pointer(v-if="!disabled" @click="edit=!edit") {{ !edit ? "+ Ajouter" : "- Masquer"}}
    b-form-group.payment-methods-config(v-if="edit")
      b-form-checkbox(v-for='option in paymentMethodsList' @change="chooseOption" :key='option.id' v-model='orderFormCustomerPaymentMethods' :value='option.id')
        | {{ option.label }}

</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { BFormCheckboxGroup } from "bootstrap-vue";
import { paymentMethodTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      showTools: false,
      edit: false,
    };
  },
  computed: {
    ...mapGetters(["paymentMethodsList", "isLoadingPaymentMethod"]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    orderFormCustomerPaymentMethods: {
      get() {
        return this.$store.getters.orderFormCustomerPaymentMethods;
      },
      set(value) {
        this.$store.commit("SET_ORDER_FORM_CUSTOMER_PAYMENT_METHODS", value);
      },
    },
  },
  async created() {
    if (!this.paymentMethodsList || this.paymentMethodsList.length == 0) {
      await this.getPaymentMethodByWorkspaceId({});
    }
  },
  methods: {
    ...mapActions([
      "updatePaymentMethodOrderFormCustomer",
      "getPaymentMethodByWorkspaceId",
    ]),
    paymentMethodTranslate,
    chooseOption() {
      this.updatePaymentMethodOrderFormCustomer({
        paymentMethodOrderFormCustomer: this.orderFormCustomerPaymentMethods,
        orderFormCustomerId: this.orderFormCustomer.id,
      });
    },
  },
  components: {
    BFormCheckboxGroup,
  },
};
</script>

<style>
.payment-methods-config {
  padding: 5px;
}
</style>
