<template lang="pug">
div(style='display:contents')
  div(v-if="isLoadingOrderFormCustomer || isLoadingOrderFormCustomerPDF" class="h-100")
    .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner 
        .mt-5 
          br
          br
          br
          |  Génération du PDF en cours...
  div(style='display:contents' v-show="!isLoadingOrderFormCustomer && !isLoadingOrderFormCustomerPDF")
    iframe.pdfViewver(:src="orderTrackingCustomerPDF" style="width: 100%;height: 100%; border: solid 2px #f1f1f1; padding:0px") 
    div(style="display:contents")
      // v-if="orderFormCustomer.status < 3 && !orderFormCustomer.path || orderFormCustomer.path=='' || orderFormCustomer.path == null || orderFormCustomer.path.includes('null.pdf')")
      orderTrackingCustomerPdfContent.pdfViewver(style="width: 100%;height: 100%; border: none; padding: 0px; display:block")
        table.structure
          thead
            tr
              td
                .page-header-space
          tbody
            tr
              td
                div#invoiceContainer.pdfViewver
                    OrderFormCustomerPreviewHeader()
                    OrderTrackingCustomerPreviewTable(:preview="preview")
                    OrderFormCustomerPreviewFooter()
                    .watermark
                        .text SITUATION
          tfoot
            tr
              td
                .page-footer-space(:style="'height:'+pageFooterHeight()+'px !important'")
                  #pageFooter
</template>

<script>
import OrderTrackingCustomerPdfContent from "@/components/invoice/order-form-customer/OrderTrackingCustomerPdfContent";
import OrderFormCustomerPreviewHeader from "@/components/invoice/order-form-customer/preview/OrderFormCustomerPreviewHeader.vue";
import OrderTrackingCustomerPreviewTable from "@/components/invoice/order-form-customer/preview/OrderTrackingCustomerPreviewTable.vue";
import OrderFormCustomerPreviewFooter from "@/components/invoice/order-form-customer/preview/OrderFormCustomerPreviewFooter.vue";
import OrderFormCustomerPreviewTermsAndConditions from "@/components/invoice/order-form-customer/preview/OrderFormCustomerPreviewTermsAndConditions.vue";
import { mapGetters, mapActions } from "vuex";
import { statusToUpperCaseTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  props: {
    preview: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters([
      "orderFormCustomer",
      "orderTrackingCustomerPDF",
      "isLoadingOrderFormCustomerPDF",
      "isValidatingOrderFormCustomer",
      "isLoadingOrderFormCustomer",
    ]),
    heightFooter() {
      return "100";
    },
  },
  components: {
    OrderTrackingCustomerPdfContent,
    OrderFormCustomerPreviewHeader,
    OrderTrackingCustomerPreviewTable,
    OrderFormCustomerPreviewFooter,
    OrderFormCustomerPreviewTermsAndConditions,
  },
  methods: {
    statusToUpperCaseTranslate,
    htmlEntities(str) {
      return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
    pageFooterHeight() {
      var count = this.orderFormCustomer.footer
        ? ((this.orderFormCustomer.footer.match(/\<p/g) || []).length +
            (this.orderFormCustomer.footer.match(/\<br/g) || []).length +
            (this.orderFormCustomer.footer.match(/\<\/br/g) || []).length) *
          12
        : 0;
      if (count > 50) {
        return count;
      } else {
        return 50;
      }
    },
    decodeHtmlEntities(str) {
      var element = document.createElement("div");
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }
      return str;
    },
  },
};
</script>
