<template lang="pug">
.row
  .document-table.col-12
    .text-center.flex-center.w-100(v-if='!headerLoaded && !dataGrid')
        .loading-bg-inner
            .loader
                .outer
                .middle
                .inner
        .mt-5
            br 
            br
            br
            | Chargement de la liste des ligne de produit/services....
    sync-grid#overviewgrid(ref="overviewgrid" v-else-if='dataGrid.length && dataGrid.length>0' :uid="uid" :gridData="dataGrid" :key="keyGrid" :headerData="headerData" :allowTextWrap="true" :allowGrouping="allowGrouping" :allowKeyboard="allowKeyboard" :allowPaging="allowPaging" :editSettings="editSettings" @actionBegin="actionBegin"  @deleteButtonClicked="deleteButtonClicked"  @queryCellInfo='queryCellInfoEvent' :saveGridStateProps="false")
    div(v-else).mt-2.p-1.text-center.text-muted
      | La commande ne contient aucune ligne de produit/service.
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SyncGrid from "@/components/global/grid/Grid.vue";

export default {
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      uid: "orderTrackingCustomerTable",
      parendIdHide: 0,
      position: 0,
      indexToFocus: 0,
      headerLoaded: false,
      headerData: [],
      allowKeyboard: false,
      allowGrouping: false,
      frozenColumns: 1,
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: false,
        mode: "Dialog",
        newRowPosition: "Bottom",
        allowNextRowEdit: true,
        titleAdd: "Nouvelle ligne",
        titleEdit: "Modifier la ligne",
        titleField: "label",
      },
      allowPaging: false,
      keyGrid: 1,
    };
  },
  computed: {
    ...mapGetters([
      "getOrderTrackingCustomerDetails",
      "isLoadingOrderTrackCustomerDetails",
      "orderTrackCustomer",
      "institutionSettingsActive",
    ]),
    referencielTvasInstitution() {
      return this.institutionSettingsActive.referencielTvas.map(
        (referencielTva) => {
          return referencielTva.referencielTva;
        }
      );
    },
    dataGrid: {
      get() {
        return this.$store.getters.getOrderTrackingCustomerDetails;
      },
    },
  },
  async created() {
    this.setHeaderData();
    this.headerLoaded = true;
  },
  methods: {
    ...mapActions(["reviewIndexOrderFormCustomer"]),
    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
        },
        {
          type: "string",
          field: "index",
          headerText: "",
          width: 60,
          minWidth: 60,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: false,
          visible: true,
        },
        {
          type: "string",
          field: "description",
          headerText: "Description",
          minWidth: 300,
          width: 300,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: false,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
        },
        {
          type: "number",
          format: "formatNumber",
          field: "quantity",
          headerText: "Qté",
          minWidth: 90,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          validationRules: { required: true },
          customCss: "e-column-border-left-light",
        },
        {
          type: "number",
          field: "unitPriceHt",
          headerText: "Prix U. HT",
          minWidth: 120,
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: false,
          allowFiltering: true,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 2, step: 0.01 } },
          format: "formatCurrency",
          validationRules: { required: true },
          customCss: "e-column-border-left-light",
        },
        {
          type: "number",
          field: "referencielTvaId",
          headerText: "Tx TVA",
          minWidth: 90,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          // template: 'referencielTvaTemplate',
          edit: {
            source: this.referencielTvasInstitution,
            fields: { text: "label", value: "id" },
            type: "dropdowndependencies",
            name: "dropdownaffair",
            target: "dropdownmission",
          },
          customCss: "e-column-border-left-light",
        },
        {
          type: "number",
          field: "discount",
          headerText: "% remise",
          minWidth: 90,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "discountPercent",
          customCss: "e-column-border-left-light",
        },
        {
          type: "number",
          field: "total",
          headerText: "Total HT",
          minWidth: 120,
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "totalPriceHT",
          customCss: "e-column-border-left-light",
        },
        {
          type: "number",
          format: "formatNumber",
          field: "situationProgressQuantity",
          headerText: "Qté facturée",
          minWidth: 90,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "situationProgressQuantity",
          customCss: "e-column-border-left",
        },
        {
          type: "number",
          field: "situationProgressPercent",
          headerText: "% facturé",
          minWidth: 120,
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "situationProgressPercent",
          customCss: "e-column-border-left-light",
        },
        {
          type: "number",
          field: "situationProgressAmount",
          headerText: "Montant facturé",
          minWidth: 120,
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "situationProgressAmount",
          customCss: "e-column-border-left-light",
        },
        {
          type: "number",
          format: "formatNumber",
          field: "situationRemainingQuantity",
          headerText: "Qté restante",
          minWidth: 90,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "situationRemainingQuantity",
          customCss: "e-column-border-left",
        },
        {
          type: "number",
          format: "formatNumber",
          field: "situationRemainingPercent",
          headerText: "% restant",
          minWidth: 90,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "situationRemainingPercent",
          customCss: "e-column-border-left-light",
        },
        {
          type: "number",
          field: "situationRemainingAmount",
          headerText: "Montant restant",
          minWidth: 120,
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "situationRemainingAmount",
          customCss: "e-column-border-left-light",
        },
      ];
    },

    actionBegin(args) {},
    deleteButtonClicked(args) {},
    queryCellInfoEvent(args) {
      if (
        [0, 1, 2, 11, 12, 13].includes(args.data.type) &&
        args.column.field == "description"
      ) {
        args.colSpan = 11;
        args.column.customCss = "col-title";
      }
    },

    isLastWorkElement(line, index) {
      if (
        this.getOrderTrackingCustomerDetails[index + 1] &&
        this.getOrderTrackingCustomerDetails[index + 1].parentId !==
          line.parentId &&
        (line.type == 14 ||
          line.type == 15 ||
          line.type == 16 ||
          line.type == 17 ||
          line.type == 18)
      ) {
        return true;
      } else if (
        !this.getOrderTrackingCustomerDetails[index + 1] &&
        (line.type == 14 ||
          line.type == 15 ||
          line.type == 16 ||
          line.type == 17 ||
          line.type == 18)
      ) {
        return true;
      } else {
        return false;
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      const beforeDragElementUp = relatedContext.list[relatedContext.index - 1]
        ? relatedContext.list[relatedContext.index - 1]
        : false;
      const afterDragElementDown = relatedContext.list[relatedContext.index + 1]
        ? relatedContext.list[relatedContext.index + 1]
        : false;
      const simpleElement = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
      const workElement = [14, 15, 16, 17, 18];

      if (
        draggedElement.type == 6 &&
        this.getOrderTrackingCustomerDetails.filter(
          (el) => el.parentId == draggedElement.id
        ).length > 0
      ) {
        // Action qui permet de cacher les éléments enfants d'un ouvrage
        this.parendIdHide = draggedElement.id;
      }
      if (this.position > relatedContext.index) {
        // si je drag un simpleElement je peux me placer si le beforeDragElementTypeUp est un workElement et que relatedElement.type est un simpleElement
        if (
          simpleElement.includes(draggedElement.type) &&
          simpleElement.includes(relatedElement.type) &&
          workElement.includes(beforeDragElementUp.type)
        ) {
          this.position = relatedContext.index;
          return true;
          // si je drag un simpleElement je peux me placer si le beforeDragElementTypeUp est un simpleElement et que relatedElement.type est un simpleElement
        } else if (
          simpleElement.includes(draggedElement.type) &&
          simpleElement.includes(relatedElement.type) &&
          simpleElement.includes(beforeDragElementUp.type)
        ) {
          this.position = relatedContext.index;
          return true;
          // si je drag un simpleElement je peux me placer si le beforeDragElementTypeUp est égal à false et que relatedElement.type est un simpleElement
        } else if (
          simpleElement.includes(draggedElement.type) &&
          simpleElement.includes(relatedElement.type) &&
          !beforeDragElementUp
        ) {
          this.position = relatedContext.index;
          return true;
          // si je drag un workElement je peux me placer si le beforeDragElementTypeUp est un workElement et qu'il ai le meme parentId que le drag et que relatedElement.type est un workElement et qu'il ai le meme parentId que le drag
        } else if (
          workElement.includes(draggedElement.type) &&
          workElement.includes(relatedElement.type) &&
          workElement.includes(beforeDragElementUp.type) &&
          draggedElement.parentId == relatedElement.parentId &&
          draggedElement.parentId == beforeDragElementUp.parentId
        ) {
          this.position = relatedContext.index;
          return true;
          // si je drag un workElement je peux me placer si le beforeDragElementTypeUp est un simpleElement et qu'il ai l'id égal au parentId de l'element drag et que relatedElement.type est un workElement et qu'il ai le meme parentId que le drag
        } else if (
          workElement.includes(draggedElement.type) &&
          workElement.includes(relatedElement.type) &&
          simpleElement.includes(beforeDragElementUp.type) &&
          draggedElement.parentId == beforeDragElementUp.id &&
          draggedElement.parentId == relatedElement.parentId
        ) {
          this.position = relatedContext.index;
          return true;
        } else {
          return false;
        }
      } else if (this.position < relatedContext.index) {
        // si je drag un simpleElement je peux me placer si le afterDragElementDown est un worlElement et que relatedElement.type est un simpleElement
        if (
          simpleElement.includes(draggedElement.type) &&
          simpleElement.includes(afterDragElementDown.type) &&
          workElement.includes(relatedElement.type)
        ) {
          this.position = relatedContext.index;
          return true;
          // si je drag un simpleElement je peux me placer si le afterDragElementDown est un simpleElement et que relatedElement.type est un simpleElement
        } else if (
          simpleElement.includes(draggedElement.type) &&
          simpleElement.includes(afterDragElementDown.type) &&
          simpleElement.includes(relatedElement.type)
        ) {
          this.position = relatedContext.index;
          return true;
          // si je drag un simpleElement je peux me placer si le afterDragElementDown est égal à false et que relatedElement.type est un simpleElement ou un workElement
        } else if (
          simpleElement.includes(draggedElement.type) &&
          !afterDragElementDown &&
          (simpleElement.includes(relatedElement.type) ||
            workElement.includes(relatedElement.type))
        ) {
          this.position = relatedContext.index;
          return true;
          // si je drag un workElement je peux me placer si le afterDragElementDown est un workElement et qu'il ai le meme parentId que le drag et que relatedElement.type est un workElement et qu'il ai le meme parentId que le drag
        } else if (
          workElement.includes(draggedElement.type) &&
          workElement.includes(afterDragElementDown.type) &&
          workElement.includes(relatedElement.type) &&
          draggedElement.parentId == relatedElement.parentId &&
          draggedElement.parentId == afterDragElementDown.parentId
        ) {
          this.position = relatedContext.index;
          return true;
          // si je drag un workElement je peux me placer si le afterDragElementDown est un simpleElement  et que relatedElement.type est un workElement et qu'il ai le meme parentId que le drag
        } else if (
          workElement.includes(draggedElement.type) &&
          simpleElement.includes(afterDragElementDown.type) &&
          workElement.includes(relatedElement.type) &&
          draggedElement.parentId == relatedElement.parentId
        ) {
          this.position = relatedContext.index;
          return true;
        } else {
          return false;
        }
      } else {
        // je drag un simpleElement je peux me placer si relatedElement.type est un simpleElement et que le afterDragElementDown est un simpleElement
        if (
          simpleElement.includes(draggedElement.type) &&
          simpleElement.includes(relatedElement.type) &&
          simpleElement.includes(afterDragElementDown.type)
        ) {
          this.position = relatedContext.index;
          return true;
          // je drag un workElement je peux me placer si relatedElement.type est un workElement et que le afterDragElementDown est un workElement et qu'il ai le meme parentId que le drag
        } else if (
          workElement.includes(draggedElement.type) &&
          workElement.includes(relatedElement.type) &&
          workElement.includes(afterDragElementDown.type) &&
          draggedElement.parentId == relatedElement.parentId &&
          draggedElement.parentId == afterDragElementDown.parentId
        ) {
          this.position = relatedContext.index;
          return true;
        } else {
          return false;
        }
      }
    },
    setPostionIndex(e) {
      this.position = e.oldIndex;
    },
    endDrag(e) {
      this.parendIdHide = 0;
      this.$nextTick(() => {
        if (e.item._underlying_vm_.type == 6) {
          // Traitement spéciale pour le déplacement des ouvrage
          let newPositionChildElement = 0;
          let childElements = this.getOrderTrackingCustomerDetails.filter(
            (el) => el.parentId == e.item._underlying_vm_.id
          );
          if (e.newIndex > e.oldIndex) {
            newPositionChildElement = e.newIndex;
          } else {
            newPositionChildElement = e.newIndex + 1;
          }
          if (childElements && childElements.length > 0) {
            // On boucle les element enfants
            for (let index = 0; index < childElements.length; index++) {
              const childElement = childElements[index];

              const positionChildElement =
                this.getOrderTrackingCustomerDetails.findIndex(
                  (el) => el.id == childElement.id
                );
              this.getOrderTrackingCustomerDetails.splice(
                positionChildElement,
                1
              );

              this.getOrderTrackingCustomerDetails.splice(
                newPositionChildElement,
                0,
                childElement
              );
            }
          }
          this.reviewIndexOrderFormCustomer();
        } else {
          this.reviewIndexOrderFormCustomer();
        }
        this.$nextTick(() => {
          this.indexToFocus = e.newIndex;
        });
      });
    },
  },
  components: {
    SyncGrid,
  },
};
</script>

<style>
.maskDocumentDetailsLoading {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.document-table .e-headercell {
  background-color: #0c3571 !important;
}
.document-table .e-headertext {
  color: #ffffff !important;
}
.document-table .e-rowcell p,
.document-table .e-rowcell ol {
  margin-bottom: 0px;
}

.e-column-border-left {
  border-left: 1px solid #0c3571 !important;
}
.e-column-border-left-light {
  border-left: 1px solid #dee2e6 !important;
}
.e-column-border-right {
  border-right: 1px solid #0c3571 !important;
}

.e-lib.e-grid .e-gridheader .e-sortfilter .e-headercelldiv,
.e-lib.e-grid .e-gridheader .e-stackedheadercelldiv {
  padding: 0 5px 0 5px;
}

.document-table
  .e-lib.e-grid
  .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd),
.document-table
  .e-lib.e-grid
  .e-detailrowcollapse:not(.e-editedbatchcell):not(.e-updatedtd),
.document-table
  .e-lib.e-grid
  .e-detailrowexpand:not(.e-editedbatchcell):not(.e-updatedtd),
.document-table
  .e-lib.e-grid
  .e-gridcontent
  .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.document-table
  .e-lib.e-grid
  .e-gridheader
  .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.document-table
  .e-lib.e-grid
  .e-emptyrow:not(.e-editedbatchcell):not(.e-updatedtd) {
  vertical-align: baseline;
  padding-top: 7px;
}
.col-title,
.col-title-total .content-text,
.col-title textarea,
.col-title input,
.col-title select {
  font-size: 1.3rem;
  font-weight: 500;
}
</style>
